import React, { Component } from "react";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";

import CustomDataTable from "../../shared/customDataTable/customDataTable";
import DateConverter from "../../shared/dateConverter/dateConverter";
import { getEcuSyncLogApi } from "../../utill/api/syncApi";
import "./EcuMaster.scss"
import EcuMasterSyncModal from "./EcuMasterSyncModal";
import ToastAlert from "../../shared/notificationAlert/toastAlert";
/**
 *
 */
class EcuMaster extends Component {
  columns = [
    {
      name: "#",
      sortable: true,
      maxWidth: "50px",
      cell: row => row.index
    },
    {
      name: "ECU Group",
      selector: "ecuGroup",
      sortable: true,
      minWidth: "150px",
      // cell: row => <span>{row.addedCount}</span>
    },
    {
      name: "ECU Name",
      selector: "ecuName",
      sortable: true,
      minWidth: "150px",
      // cell: row => <span>{row.updatedCount}</span>
    },
    {
      name: "PCODE",
      selector: "faultCode",
      sortable: true,
      minWidth: "150px",
      // cell: row => <span>{row.updatedCount}</span>
    },
    {
      name: "Adjustment Parameter",
      selector: "adjustmentParameters",
      sortable: true,
      minWidth: "150px",
      // cell: row => <span>{row.updatedCount}</span>
    },
    {
      name: "Freezeframe",
      selector: "freezeFrame",
      sortable: true,
      minWidth: "150px",
      // cell: row => <span>{row.updatedCount}</span>
    },
    {
      name: "Linear Master",
      selector: "linearMaster",
      sortable: true,
      minWidth: "150px",
      // cell: row => <span>{row.updatedCount}</span>
    },
    {
      name: "Table Master",
      selector: "tableMaster",
      sortable: true,
      minWidth: "150px",
      // cell: row => <span>{row.updatedCount}</span>
    },
    {
      name: "Identical Master",
      selector: "identicalMaster",
      sortable: true,
      minWidth: "150px",
      // cell: row => <span>{row.updatedCount}</span>
    },
    {
      name: "Synced By",
      selector: "addedBy",
      sortable: true,
      minWidth: "150px",
      // cell: row => <span>{row.updatedCount}</span>
    },
    {
      name: "Synced At",
      selector: "syncAt",
      sortable: true,
      minWidth: "200px",
      cell: row => <DateConverter date={row.syncAt} />
    },
    {
      name: "Message",
      selector: "message",
      sortable: true,
      minWidth: "300px",
      // cell: row => <span>{row.updatedCount}</span>
    }
  ];

  currentPage = 0;
  constructor(props) {
    super(props);
    this.state = {
      ecuMasterData: [],
      isLoading: true,
      error: null,
      showEcuUploadModal: false
    };
    this.getEcuMasterLog = this.getEcuMasterLog.bind(this);
  }

  componentDidMount() {
    //this.getEcuMasterLog();
  }

  /**
   * To get files
   */
  getEcuMasterLog(page) {
    getEcuSyncLogApi(page)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.setIndexNumber(res, page);
              this.setState({ showEcuUploadModal: false });
            } else this.setState({ isLoading: false });
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false, error: e });
      });
  }

  /**
   * To set index for each row
   */
  setIndexNumber = (data, page) => {
    if (page == 0) {
      this.setState({ ecuMasterData: [] });
    }
    data.map((row, index) => {
      return row.index = this.state.ecuMasterData.length + index + 1;
    });
    if (page == 0) {
      this.setState({
        ecuMasterData: data,
        isLoading: false
      })
    } else {
      this.setState(prevState => {
        return {
          ecuMasterData: [...prevState.ecuMasterData, ...data],
          isLoading: false
        }
      });
    }
  };

  toggleConfirmModal = () => {
    this.setState(oldState => ({
      showEcuUploadModal: !oldState.showEcuUploadModal
    }));
  };

  loadNextPage = (page, currentPage) => {
    this.currentPage = currentPage;
    this.getEcuMasterLog(page);
  };

  showModalSuccess = e => {
    this.setState({ error: e });
  };

  errorHandled = () => {
    this.setState({ error: null });
  };

  render() {
    const {
      error,
      showEcuUploadModal
    } = this.state;
    return (
      <>
        {/* Toast Alert */}
        {Boolean(error) ? (
          <ToastAlert
            show={Boolean(error)}
            onClose={this.errorHandled}
            error={error}
          />
        ) : null}
        {/* Page Content starts */}
        <Container fluid>
          <Row noGutters className="page-title">
            <Col className="col-6">
              <h1>ECU Master</h1>
            </Col>
            <Col className="col-6 text-right">
              <Button
                className="sync-button"
                variant="danger" size="md"
                onClick={this.toggleConfirmModal}
              >
                <i className={"fa fa-refresh " + this.state.syncing} aria-hidden="true"></i>
                <span className="name pl-2">Sync XML</span>
              </Button>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="data-table">
              <CustomDataTable
                tableColumns={this.columns}
                data={this.state.ecuMasterData}
                loading={this.state.isLoading}
                loadNextPage={this.loadNextPage}
                currentPage={this.currentPage}
              />
            </Col>
          </Row>
        </Container>
        {/* Confirm Action Modal */}
        <EcuMasterSyncModal
          show={showEcuUploadModal}
          onHide={this.toggleConfirmModal}
          getEcuMasterLog={this.getEcuMasterLog}
          showModalSuccess={this.showModalSuccess}
        />
      </>
    );
  }
}

export default EcuMaster;
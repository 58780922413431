import React from "react";
import { ProgressBar } from "react-bootstrap";

const StatusBar = (props) => {
  const { schedule } = props;
  let status = {};
  status.inProgress = (schedule.inProgress / schedule.totalDevice) * 100;
  status.scheduled = (schedule.scheduled / schedule.totalDevice) * 100;
  status.completed = (schedule.completed / schedule.totalDevice) * 100;
  status.error = (schedule.error / schedule.totalDevice) * 100;
  status.cancelled = (schedule.cancelled / schedule.totalDevice) * 100;

  if (status.inProgress && !Number.isInteger(status.inProgress)) {
    status.inProgress = parseFloat(status.inProgress.toFixed(1));
  }
  if (status.scheduled && !Number.isInteger(status.scheduled)) {
    status.scheduled = parseFloat(status.scheduled.toFixed(1));
  }
  if (status.completed && !Number.isInteger(status.completed)) {
    status.completed = parseFloat(status.completed.toFixed(1));
  }
  if (status.error && !Number.isInteger(status.error)) {
    status.error = parseFloat(status.error.toFixed(1));
  }
  if (status.cancelled && !Number.isInteger(status.cancelled)) {
    status.cancelled = parseFloat(status.cancelled.toFixed(1));
  }

  if (
    schedule.cancelled +
    schedule.completed +
    schedule.inProgress +
    schedule.error +
    schedule.scheduled ===
    0
  ) {
    status.inProgress = 0;
    status.scheduled = 0;
    status.completed = 0;
    status.error = 0;
    status.cancelled = 0;
    status.noDevice = 100;
  } else {
    status.noDevice = 0;
  }

  // to check the status

  // This will be set the colour for the progress bar
  let completedStatusVariant = "success";
  // will be used to show tge tool tips  fo the rejected and completed
  let completedStatusToolTipText = "Completed";

  if (schedule.status == "REJECTED") {

    completedStatusVariant = "secondary";
    completedStatusToolTipText = "Rejected";
  }

  return (
    <ProgressBar style={{ width: "81%" }}>
      <ProgressBar
        variant=""
        className="tooltip-toggle inprogress-bar"
        tooltip={`${status.inProgress}%  In Progress / ${schedule.inProgress} Device`}
        tabIndex="0"
        now={status.inProgress}
      />
      <ProgressBar
        variant="cencelled"
        className="tooltip-toggle"
        tooltip={`${status.scheduled}% Scheduled / ${schedule.scheduled} Device`}
        tabIndex="0"
        now={status.scheduled}
      />
      <ProgressBar
        variant={`${completedStatusVariant}`}
        className="tooltip-toggle"
        tooltip={`${status.completed}% ${completedStatusToolTipText} / ${schedule.completed} Device`}
        tabIndex="0"
        now={status.completed}
      />
      <ProgressBar
        variant="danger"
        className="tooltip-toggle"
        tooltip={`${status.error}% Error / ${schedule.error} Device`}
        tabIndex="0"
        now={status.error}
      />
      <ProgressBar
        variant=""
        className="tooltip-toggle cancelled-bar"
        tooltip={`${status.cancelled}% Cancelled / ${schedule.cancelled} Device`}
        tabIndex="0"
        now={status.cancelled}
      />
      <ProgressBar
        variant="secondary"
        className="tooltip-toggle"
        tooltip={`0 Devices`}
        tabIndex="0"
        now={status.noDevice}
      />
    </ProgressBar>
  );
};

export default StatusBar;

import React, { Component } from 'react';
import { Button, Card, ListGroup, Modal, Spinner, Table } from 'react-bootstrap';

class WarningModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningType: props.warningType,
      invalidVins: props.invalidVins,
      vinsTaken: props.vinsTakenData
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      warningType: nextProps.warningType,
      invalidVins: nextProps.invalidVins,
      vinsTaken: nextProps.vinsTakenData
    });
  }

  render() {
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          backdrop={'static'}
          keyboard={false}
          size={this.state.warningType === 'already_scheduled' ? 'lg' : 'md'}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdropClassName="second-level-modal"
          >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <i className="fa fa-exclamation-triangle text-warning mr-1"></i>
              {this.state.warningType === 'validation_fail' ? 'Warning! Validation Failures' : null}
              {this.state.warningType === 'already_scheduled' ? 'Warning! Schedule already created' : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row no-gutters align-items-center">
              <div className="col-12">
                {this.state.warningType === 'validation_fail' ? 
                  <p>Below <b>{this.state.invalidVins.length}</b> VINs were validation failed during CSV processing and will be excluded from the Schedule.</p>
                : null}
                {this.state.warningType === 'already_scheduled' ? 
                  <p>Below <b>{this.state.vinsTaken.length}</b> VINs were added to other schedule(s) while you were previewing this upload.</p>
                : null}
              </div>
            </div>
            {this.state.warningType === 'validation_fail' ?
              <Card style={{ maxHeight: '200px', overflow: 'auto' }}>
                <ListGroup variant="flush">
                  {this.state.invalidVins.map((item, i) => {
                    return <ListGroup.Item key={i}>{item}</ListGroup.Item>
                  })}
                </ListGroup>
              </Card>
            : null}
            {this.state.warningType === 'already_scheduled' ? 
              <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                <Table bordered size="sm">
                  <thead>
                    <tr>
                      <th style={{fontWeight: 500}}>#</th>
                      <th style={{fontWeight: 500}}>VIN</th>
                      <th style={{fontWeight: 500}}>Schedule Name</th>
                      <th style={{fontWeight: 500}}>Created By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.vinsTaken.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i+1}</td>
                          <td>{item.vin}</td>
                          <td>{item.scheduleName}</td>
                          <td>{item.creatorName}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            : null}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onHide} disabled={this.props.disable}>
              Close
            </Button>
            <Button variant="primary" onClick={this.props.onConfirm} disabled={this.props.disable}>
              {this.props.disable ? (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  role="status"
                />
              ) : null}
              Remove and Proceed
            </Button>
        </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default WarningModal;
import React, { Component } from 'react';
import { remoteDiagnosticsApi } from "../../utill/api/schedulesApi";
import CustomDataTable from "../../shared/customDataTable/customDataTable";
import { Container, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import ViewSideMenu from "../DiagnosticsView/ViewSideMenu";
import ToastAlert from "../../shared/notificationAlert/toastAlert";

class UpdateParametersView extends Component {
  columns = [
    {
      name: "#",
      selector: "index",
      sortable: false,
      minWidth: "60px",
      maxWidth: "50px",
      left: true
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      minWidth: "150px"
    },
    {
      name: "ECU",
      selector: "ecuName",
      sortable: false,
      minWidth: "150px"
    },
    {
      name: "Data",
      selector: "data",
      sortable: false,
      minWidth: "100px"
    },
    {
      name: "Data Identifier",
      selector: "dataIdentifier",
      sortable: false,
      minWidth: "150px"
    },
    {
      name: "Actuator Parameter",
      selector: "parameter",
      sortable: false,
      minWidth: "150px"
    },
    {
      name: "Unit",
      selector: "unit",
      sortable: false,
      minWidth: "150px"
    },
    // {
    //   name: "Value",
    //   selector: "value",
    //   sortable: false,
    //   minWidth: "300px",
    //   cell: row => (
    //     <>
    //       <DropdownButton id="dropdown-basic-button" title="Dropdown button">
    //         <Dropdown.Item href="#/action-1">Value 1</Dropdown.Item>
    //         <Dropdown.Item href="#/action-2">Value 2</Dropdown.Item>
    //         <Dropdown.Item href="#/action-3">Value 3</Dropdown.Item>
    //       </DropdownButton>
    //     </>
    //   )
    // }
  ];

  currentPage = 0;

  constructor(props) {
    super(props);
    this.state = {
      vehicleId: props.match.params.vehicleId,
      scheduleId: props.match.params.scheduleId,
      schedule: JSON.parse(localStorage.getItem("selectedSchedule")),
      vehicle: JSON.parse(localStorage.getItem("selectedVehicle")),
      isLoading: true,
      rawData: {},
      data: [],
      vin: 'VIN',
      error: null
    }
  }

  getDiagnostics = page => {
    remoteDiagnosticsApi(this.state.vehicleId, page)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            this.setState({
              rawData: res[0],
              isLoading: false
            });
            if (res.length && res[0].data && res[0].data.length) {
              this.setState({ vin: res[0].vin, ecuName: res[0].ecuName });
              this.setIndexNumber(res[0].data, page);
            } else {
              this.setState({ isLoading: false });
            }
          });
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              isLoading: false
            });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          isLoading: false
        });
      });
  };

  setIndexNumber(data, page) {
    if (page == 0) {
      this.setState({ data: [] });
    }
    // eslint-disable-next-line array-callback-return
    data.map((row, index) => {
      (row.index = this.state.data.length + index + 1);
      (row.ecuName = this.state.rawData.ecuName);
      (row.status = this.state.rawData.status);
    });
    if (page == 0) {
      this.setState({
        data: data,
        isLoading: false
      })
    } else {
      this.setState(prevState => {
        return {
          data: [...prevState.data, ...data],
          isLoading: false
        }
      });
    }
  }

  loadNextPage = (page, currentPage) => {
    this.currentPage = currentPage;
    this.getDiagnostics(page);
  };

  errorHandled = () => {
    this.setState({ error: null });
  };

  goBack = () => {
    this.props.history.push(`/schedules/${this.state.scheduleId}/updateParameters`)
  };

  render() {
    const { error } = this.state;
    return (
      <>
        {/* Toast Alert */}
        {Boolean(error) ? (
          <ToastAlert
            show={Boolean(error)}
            onClose={this.errorHandled}
            error={error}
          />
        ) : null}
        <Container fluid className="px-0">
          <Row noGutters className="action-row-top align-items-center">
            <Col md="4">
              <div className="back" onClick={this.goBack}>
                <i className="fa fa-chevron-left"></i>&nbsp;
                Back to Schedule for {this.state.vehicle.vin}
              </div>
            </Col>
            <Col md="8" className="px-0">
              <h6 className="longString mt-1">
                Schedule: &nbsp;
                <span>{this.state.schedule.name}</span>
              </h6>
            </Col>
          </Row>
          <Row md="12" className="pt-2">
            <Col md="12">
              <Row noGutters>
                <Col md="12" className="page-title">
                  <h1>Update Parameters</h1>
                </Col>
              </Row>
              <Row noGutters>
                <Col md="12">
                  <CustomDataTable
                    tableColumns={this.columns}
                    data={this.state.data}
                    loading={this.state.isLoading}
                    loadNextPage={this.loadNextPage}
                    currentPage={this.currentPage}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default UpdateParametersView;
import React, { Component } from "react";
import { Navbar, Nav, NavItem, NavDropdown, Button } from "react-bootstrap";
import { NavLink, Link } from 'react-router-dom';
import './Header.scss';
import { Redirect } from 'react-router-dom';
import logo from '../../assets/img/darby_header_logo.png';
import { permissionModules } from "../../utill/config";
import { logoutApi } from "../../utill/api/loginApi";
import ChangePasswordModal from "./ChangePasswordModal";
import ToastAlert from "../../shared/notificationAlert/toastAlert";

class Header extends Component {
  currentUser = JSON.parse(localStorage.getItem("currentUser"));

  constructor() {
    super();
    this.state = {
      error: null,
      loggedIn: true,
      showModal: false,
      syncDefaultUrl: ""
    };
  }

  componentDidMount() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser && currentUser.userType &&
      currentUser.userGroupId === 1 &&
      permissionModules[currentUser.userType].sync.includes("pdVehicleSync")
    ) {
      this.setState({ syncDefaultUrl: "/sync/pdvehicle" });
    } else if (currentUser && currentUser.userType &&
      currentUser.userGroupId === 1 &&
      permissionModules[currentUser.userType].sync.includes("ecuMasterSync")
    ) {
      this.setState({ syncDefaultUrl: "/sync/ecumaster" });
    } else if (currentUser && currentUser.userType &&
      currentUser.userGroupId === 2 &&
      permissionModules[currentUser.userType].sync.includes("iAlert")
    ) {
      this.setState({ syncDefaultUrl: "/sync/ialert" });
    } else {
      this.setState({ syncDefaultUrl: "/sync/hexsync" });
    }
  }

  logOut = (e) => {
    // const encodedToken = localStorage.getItem("encodedToken");
    // logoutApi(encodedToken)
    localStorage.clear();
    this.setState({ loggedIn: false })
  }

  toggleModal = () => {
    this.setState(oldState => ({
      showModal: !oldState.showModal
    }));
  }

  errorHandled = () => {
    this.setState({ error: null });
  };

  showModalSuccess = e => {
    this.setState({ error: e });
  };

  render() {
    if (!this.state.loggedIn) {
      return <Redirect to='/login' />
    }
    return (
      <>
        {/* Toast Alert */}
        {Boolean(this.state.error) ? (
          <ToastAlert
            show={Boolean(this.state.error)}
            onClose={this.errorHandled}
            error={this.state.error}
          />
        ) : null}
        <Navbar
          collapseOnSelect
          expand="sm"
          bg="light"
          variant="light"
          fixed="top"
        >
          <Navbar.Brand>
            <img
              src={logo}
              className="d-inline-block align-top"
              // alt="Ashok Leyland"
              alt="darby"
            />
          </Navbar.Brand>
          <Navbar.Brand>
            <span className="main-head">ECU</span>&nbsp;
            <span className="sub-head">OTA</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <NavItem>
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/dashboard"
                >
                  DASHBOARD
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/schedules"
                >
                  SCHEDULES
                </NavLink>
              </NavItem>
              {this.currentUser &&
                this.currentUser.userType
                && permissionModules[this.currentUser.userType].file.includes("view") ? (
                  <NavItem>
                    <NavLink className="nav-link" to="/files">
                      FILES
                  </NavLink>
                  </NavItem>
                ) : null}
              {this.currentUser &&
                this.currentUser.userType &&
                permissionModules[this.currentUser.userType].admin.includes("view") ? (
                  <NavItem>
                    <NavLink className="nav-link" to="/admin/users">
                      ADMIN
                  </NavLink>
                  </NavItem>
                ) : null}
              {this.currentUser &&
                this.currentUser.userType &&
                (this.currentUser.userGroupId === 1 || this.currentUser.userGroupId === 2) &&
                permissionModules[this.currentUser.userType].sync.includes("view") ? (
                  <NavItem>
                    <NavLink className="nav-link" to={this.state.syncDefaultUrl}>
                      SYNC
                  </NavLink>
                  </NavItem>
                ) : null}
              <NavDropdown
                title={this.currentUser && this.currentUser.name ? this.currentUser.name : 'User'}
                alignRight
                id="dropdown-menu-align-right">
                <NavDropdown.Item as={Button} onClick={this.toggleModal}>
                  <i className="fa fa-key" aria-hidden="true"></i>
                  &nbsp;Change Password
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/logout" onClick={this.logOut}>
                  <i className="fa fa-power-off" aria-hidden="true"></i>
                  &nbsp;Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <ChangePasswordModal
          show={this.state.showModal}
          onHide={this.toggleModal}
        />
      </>
    );
  }
}

export default Header;

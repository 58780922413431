import React, { Component } from "react";
import { Modal, Button, Form, Col, Spinner } from "react-bootstrap";
import { createFileApi, uploadHexFieApi } from "../../utill/api/filesApi";
import { ecuGroupApi, ecuNameApi } from "../../utill/api/ecuApi";

import "../../assets/styles/_modal.scss";
import InlineAlert from "../../shared/notificationAlert/inlineAlert";
import { ToggleButtonGroup } from "react-bootstrap";
import { ToggleButton } from "react-bootstrap";

class AddFileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      disable: false,
      selectedType: "flashEcu",
      ecuGroupId: "",
      ecuNameId: "",
      fileType: "",
      file: "",
      ecuNameData: [],
      files: [],
      ecuGroup: [],
      ecuGroupValue: "",
      ecuNameValue: "",
      error: null,
      invalidFileName: false,
      checkboxValue: 'normal',
      hexFileType: 'B',
      invalidBaseFile: false,
      invalidCalibFile: false,
      invalidHexFile: false
    };
    this.getEcuGroup = this.getEcuGroup.bind(this);
    this.getEcuName = this.getEcuName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getEcuGroup();
  }

  changeScheduleType = (e) => {
    const { hexFileType } = this.state;
    this.setState({ selectedType: e.target.value });
    if (hexFileType !== 'B') this.setState({ hexFileType: 'B' });
  }

  getEcuGroup() {
    ecuGroupApi()
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            this.setState({
              ecuGroup: res,
              ecuGroupValue: res[0].ecuGroup
            }, () => this.getEcuName(null, res[0].ecuGroup));
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ erroe: e });
      });
  }

  getEcuName(e, id) {
    let ecuGroupId = id;
    if (e) {
      ecuGroupId = e.target.value;
      this.setState({
        ecuGroupValue: ecuGroupId
      });
    }
    ecuNameApi(ecuGroupId)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            this.setState({
              ecuNameData: res,
              ecuNameValue: res[0].ecuName
            });
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  };

  validateFileName = (elem) => {
    if (elem.target.files.length && /\s/.test(elem.target.files[0].name)) {
      this.setState({ invalidFileName: true });
      return true;
    } else {
      this.setState({ invalidFileName: false });
      return false;
    }
  };

  validateBaseFileName = (elem) => {
    if (elem.target.files.length && /\s/.test(elem.target.files[0].name)) {
      this.setState({ invalidBaseFile: true });
      return true;
    } else {
      this.setState({ invalidBaseFile: false });
      return false;
    }
  }

  validateCalibFileName = (elem) => {
    if (elem.target.files.length && /\s/.test(elem.target.files[0].name)) {
      this.setState({ invalidCalibFile: true });
      return true;
    } else {
      this.setState({ invalidCalibFile: false });
      return false;
    }
  }

  // validateHexFileName = (elem) => {
  //   if (elem.target.files.length && /\s/.test(elem.target.files[0].name)) {
  //     this.setState({ invalidHexFile: true });
  //     return true;
  //   } else {
  //     this.setState({ invalidHexFile: false });
  //     return false;
  //   }
  // }

  handleRadioSelection = (value) => {
    this.setState({ checkboxValue: value });
  };

  changeHexFileType = (e) => {
    this.setState({
      hexFileType: e.target.value,
      invalidBaseFile: false,
      invalidCalibFile: false
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const {
      checkboxValue,
      selectedType,
      invalidFileName,
      invalidBaseFile,
      invalidCalibFile,
      // invalidHexFile,
      hexFileType
    } = this.state;

    if (form.checkValidity()) {
      const {
        normalFileType,
        ecuGroupId,
        ecuNameId,
        manufacturer,
        file,
        baseFile,
        calibFile,
        // hexFile
      } = event.target;
      const formData = new FormData();
      
      let ecuGroup = '';
      this.state.ecuGroup.map(item => {
        if (ecuGroupId.value == item.ecuGroup) {
          ecuGroup = item.ecuGroup;
        }
      });
      const data = {
        ecuGroup: ecuGroup,
        ecuName: ecuNameId.value,
        scheduleType: selectedType
      };
      
      if (checkboxValue === 'normal' && !invalidFileName) {
        this.setState({ disable: true });
        data.fileType = normalFileType.value;
        formData.append("file", file.files[0]);
        this.normalFileUpload(formData, data, selectedType);
      } else if (checkboxValue === 'hex') {
        data.fileType = hexFileType;
        data.ecuManufacturerName = manufacturer.value;
        if (!invalidBaseFile && !invalidCalibFile) {
          this.setState({ disable: true });
          if (hexFileType === 'B' || hexFileType === 'B+C') {
            formData.append("baseFile", baseFile.files[0]);
          } else if (hexFileType === 'C') {
            formData.append("calFile", calibFile.files[0]);
          } else if (hexFileType === 'B&C') {
            formData.append("baseFile", baseFile.files[0]);
            formData.append("calFile", calibFile.files[0]);
          }
        }
        this.hexFileUpload(formData, data, selectedType)
        // else if (!invalidBaseFile) {
        //   data.fileType = 'B';
        //   formData.append("baseFile", hexFile.files[0]);
        // }
      }
    }
    this.setState({ validated: true });
  }

  normalFileUpload = (formData, data, selectedType) => {
    createFileApi(formData, data, selectedType)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            res.successCode = "addFile";
            this.props.getFiles(0);
            this.props.onHide();
            this.props.showModalSuccess(res);
            this.setState({ disable: false });
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false })
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false })
        console.log(e);
      });
  }

  hexFileUpload = (formData, data, selectedType) => {
    uploadHexFieApi(formData, data, selectedType)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            res.successCode = "addFile";
            this.props.getFiles(0);
            this.props.onHide();
            this.props.showModalSuccess(res);
            this.setState({ disable: false });
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false })
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false })
        console.log(e);
      });
  }

  render() {
    const {
      error,
      validated,
      checkboxValue,
      selectedType,
      ecuGroupValue,
      ecuGroup,
      ecuNameData,
      invalidFileName,
      invalidBaseFile,
      invalidCalibFile,
      invalidHexFile,
      hexFileType,
      disable
    } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">New File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Boolean(error) ? (
            <InlineAlert error={error} />
          ) : null}
          <Form
            noValidate
            validated={validated}
            onSubmit={this.handleSubmit}
          >
            {/* Radio Selection */}
            <div className="d-flex flex-column mb-4 width100">
              <ToggleButtonGroup
                type="radio"
                name="options"
                defaultValue={checkboxValue}
                onChange={this.handleRadioSelection}
              >
                <ToggleButton variant="outline-secondary" value={"normal"}>Encrypted File</ToggleButton>
                <ToggleButton variant="outline-secondary" value={"hex"}>Hex File</ToggleButton>
              </ToggleButtonGroup>
            </div>

            {/* Common Schedule Type selection */}
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="exampleForm.ControlSelect1">
                <Form.Label>Schedule Type</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="scheduleType"
                  value={selectedType}
                  onChange={this.changeScheduleType}
                  >
                  <option value="flashEcu">ECU Flashing</option>
                  {/* <option value="readDtc">Read Error Codes</option>
                  <option value="clearDtc">Read and Erase Error Codes</option>
                  <option value="readFreezeFrame">Read Error Codes with Snapshot</option>
                  <option value="readParameters">Read Parameter</option>
                  <option value="updateParameters">Update Parameter</option> */}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select Schedule type.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            {/* Common Ecu Group Selection */}
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                <Form.Label>ECU Group</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="ecuGroupId"
                  value={ecuGroupValue}
                  onChange={this.getEcuName}
                >
                  <option value="" disabled defaultValue>
                    Select ECU Group
                  </option>
                  {ecuGroup.length ? (
                    <>
                      {ecuGroup.map((data, i) => {
                        return (
                          <option
                            key={`ecuGroup_${i}`}
                            defaultValue={i === 0}
                            value={data.ecuGroupId}
                          >
                            {data.ecuGroup}
                          </option>
                        );
                      })}
                    </>
                  ) : null}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please choose ECU group.
                </Form.Control.Feedback>
              </Form.Group>

              {/* Common Ecu Name Selection */}
              <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                <Form.Label>ECU Name</Form.Label>
                <Form.Control as="select" required name="ecuNameId">
                  <option value="" disabled defaultValue>
                    Select ECU Name
                  </option>
                  {ecuNameData.length ? (
                    <>
                      {ecuNameData.map((data, i) => {
                        return (
                          <option
                            defaultValue={i === 0}
                            key={`ecuName_${i}`}
                            value={data.ecuName}
                          >
                            {data.ecuName}
                          </option>
                        );
                      })}
                    </>
                  ) : null}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please choose ECU name.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            
            {/* Conditional file type and manufacturer selection */}
            <Form.Row>
            {/* Encrypted file type selection */}
            {checkboxValue === 'normal' ?
            <>
              {/* File type selection (all types in flashEcu and only B in others) */}
              <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                <Form.Label>File Type</Form.Label>
                <Form.Control
                  as="select" required
                  name="normalFileType"
                  >
                  <option value="" disabled defaultValue>Select File</option>
                  <option value="B">B</option>
                  {selectedType === "flashEcu" ?
                  <>
                    <option value="C">C</option>
                    <option value="B+C">B + C</option>
                  </> : null }
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please choose File Type.
                </Form.Control.Feedback>
              </Form.Group>
            </>
            :
            // Hex fileType and manufacturer selection
            <>
              {/* File type selection (all types in flashEcu and only B in others) */}
              <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                <Form.Label>File Type</Form.Label>
                <Form.Control
                  as="select" required
                  name="hexFileType"
                  value={hexFileType}
                  onChange={this.changeHexFileType}
                >
                  <option value="" disabled defaultValue>
                    Select File
                  </option>
                  <option value={"B"}>B</option>
                  {selectedType === "flashEcu" ?
                  <>
                    <option value={"C"}>C</option>
                    <option value={"B+C"}>B + C</option>
                    <option value={"B&C"}>B & C</option>
                  </> : null }
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please choose File Type.
                </Form.Control.Feedback>
              </Form.Group>
              
              {/* Manufacturer selection in all hex schedule types */}
              <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                <Form.Label>Manufacturer</Form.Label>
                <Form.Control as="select" required name="manufacturer">
                  <option value="" disabled defaultValue>
                    Select Manufacturer
                  </option>
                  <option value=""></option>
                  <option value="cummins">Cummins</option>
                  <option value="bosch">Bosch</option>
                  <option value="BCU">BCU</option>
                </Form.Control>
              </Form.Group>
            </>
            }
            </Form.Row>

            {/* File Type selection for enc and hex files based on schedule type */}
            {/* {selectedType === "flashEcu" ?
              <>
                <Form.Row>
                  {checkboxValue === 'normal' ?
                    <>
                      <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                        <Form.Label>File Type</Form.Label>
                        <Form.Control
                          as="select" required
                          name="normalFileType"
                          >
                          <option value="" disabled defaultValue>Select File</option>
                          <option value="B">B</option>
                          <option value="C">C</option>
                          <option value="B+C">B + C</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please choose File Type.
                      </Form.Control.Feedback>
                      </Form.Group>
                    </>
                    :
                    <>
                      <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                        <Form.Label>File Type</Form.Label>
                        <Form.Control
                          as="select" required
                          name="hexFileType"
                          value={hexFileType}
                          onChange={this.changeHexFileType}
                        >
                          <option value="" disabled defaultValue>
                            Select File
                          </option>
                          <option value={"B"}>B</option>
                          <option value={"C"}>C</option>
                          <option value={"B+C"}>B + C</option>
                          <option value={"B&C"}>B & C</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please choose File Type.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                        <Form.Label>Manufacturer</Form.Label>
                        <Form.Control as="select" required name="manufacturer">
                          <option value="" disabled defaultValue>
                            Select Manufacturer
                          </option>
                          <option value=""></option>
                          <option value="cummins">Cummins</option>
                          <option value="bosch">Bosch</option>
                          <option value="BCU">BCU</option>
                        </Form.Control>
                      </Form.Group>
                    </>
                  }
                </Form.Row>
              </>
            : null} */}

            {/* File Upload Input based on file type selection */}
            {checkboxValue === "normal" ?
              <>
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                    <Form.Control
                      required
                      type="file"
                      name="file"
                      onChange={this.validateFileName}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please choose a File.
                    </Form.Control.Feedback>
                    {invalidFileName ? (
                      <>
                        <small className="text-danger">
                          File Name contains space. Please rename the file and try again.
                        </small>
                      </>
                    ) : null}
                  </Form.Group>
                </Form.Row>
              </>
              :
              <>
                {hexFileType === 'B' || hexFileType === 'B&C' || hexFileType === 'B+C' ?
                  <>
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                        <Form.Label>Base File</Form.Label>
                        <Form.Control
                          required
                          type="file"
                          name="baseFile"
                          onChange={this.validateBaseFileName}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please choose a File.
                        </Form.Control.Feedback>
                        {invalidBaseFile ? (
                          <>
                            <small className="text-danger">
                              File Name contains space. Please rename the file and try again.
                            </small>
                          </>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                  </>
                : null}

                {hexFileType === 'C' || hexFileType === 'B&C' ?
                  <>
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                        <Form.Label>Calibration File</Form.Label>
                        <Form.Control
                          required
                          type="file"
                          name="calibFile"
                          onChange={this.validateCalibFileName}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please choose a File.
                        </Form.Control.Feedback>
                        {invalidCalibFile ? (
                          <>
                            <small className="text-danger">
                              File Name contains space. Please rename the file and try again.
                            </small>
                          </>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                  </>
                : null}
              </>
            }
            {/* {selectedType === "flashEcu" ?
              <>
              </>
              :
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                  <Form.Control
                    required
                    type="file"
                    name="hexFile"
                    onChange={this.validateHexFileName}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please choose a File.
                  </Form.Control.Feedback>
                  {invalidHexFile ? (
                    <>
                      <small className="text-danger">
                        File Name contains space. Please rename the file and try again.
                      </small>
                    </>
                  ) : null}
                </Form.Group>
              </Form.Row>
            } */}

            <Button
              className="float-right"
              type="submit"
              variant="success"
              disabled={disable}
              >
              {disable ? (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  role="status"
                />
              ) : null}
              Upload
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddFileModal;

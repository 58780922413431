import apiHelper from "./apiHelper";
/**
 * 
 */
export const usersApi = (page) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/users?page=${page}&limit=${100}`,
    method: "GET"
  })
};
/**
 * 
 */
export const userTypeApi = () => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/users/userType`,
    method: "GET"
  })
};
/**
 * 
 * @param {*} payload 
 */
export const createUserApi = (payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/users`,
    method: "POST",
    body: payload
  })
};

export const userSearchApi = (key, value) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/users/search?key=${key}&value=${value}`,
    method: "GET"
  })
};

export const deleteUserApi = (userId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/users/${userId}`,
    method: "DELETE"
  })
};

export const editUserApi = (userId, payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/users/${userId}`,
    method: "PUT",
    body: payload
  })
};

export const resetPasswordApi = (userId, payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/users/${userId}/password/change`,
    method: "PUT",
    body: payload
  })
};
import React, { Component } from 'react';
import { ButtonToolbar, Col, Form, InputGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import InlineAlert from '../../../../shared/notificationAlert/inlineAlert';
import {
  ecuDetailsApi,
  fileDetailsApi,
  softwareTypeDetailsApi,
  supplierDetailsApi
} from '../../../../utill/api/ecuApi';
import Select from 'react-select';

class EcuFlashBulk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleType: this.props.scheduleType,
      error: null,
      ecuDetails: [],
      selectedEcu: '',
      selectedEcuId: null,
      supplierDetails: [],
      selectedSupplier: null,
      selectedSupplierId: null,
      selectedIAlertVpartId: null,
      softwareTypeDetails: [],
      selectedType: null,
      bFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
      cFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
      description: null,
      scheduleExpiryDays: 14,
      customFileName: null,
      radioButtonsEnable: {
        B: false,
        C: false,
        BPC: false,
        BAC: false
      }
    }
  }

  componentDidMount() {
    this.getEcuDetails();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isDataStored } = nextProps;
    if (isDataStored) this.setState({ customFileName: null });
  }

  getEcuDetails() {
    ecuDetailsApi(null)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(ecu => { ecu.label = `${ecu.ecuGroup}`; });
              // Update parent with the selected changes
              this.props.handleChange('ecuGroup', res[0].ecuGroup);
              this.props.handleChange('ecuGroupId', res[0].id);
              // set current state values
              this.setState({
                ecuDetails: res,
                selectedEcu: res[0].label,
                selectedEcuId: res[0].id
              }, () => this.getSupplierDetails());
            } else {
              // empty parent values
              this.props.handleChange('ecuGroup', null);
              this.props.handleChange('ecuGroupId', null);
              this.props.handleChange('supplier', null);
              this.props.handleChange('supplierId', null);
              this.props.handleChange('selectedIAlertVpartId', null);
              this.props.handleChange('softwareType', null);
              this.props.handleChange('bFileDetails', { data: [], selected: null, selectedName: '', selectedId: '' });
              this.props.handleChange('cFileDetails', { data: [], selected: null, selectedName: '', selectedId: '' });
              // empty current state values
              this.setState({
                ecuDetails: [],
                selectedEcuId: '',
                selectedEcu: '',
                supplierDetails: [],
                selectedSupplierId: '',
                selectedIAlertVpartId: '',
                selectedSupplier: '',
                softwareTypeDetails: [],
                selectedType: '',
                bFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                cFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                radioButtonsEnable: {
                  B: false,
                  C: false,
                  BPC: false,
                  BAC: false
                }
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getSupplierDetails() {
    supplierDetailsApi(this.state.selectedEcu, null)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(sup => { sup.label = `${sup.supplier}` });
              // Update parent with the selected changes
              this.props.handleChange('supplier', res[0].supplier);
              this.props.handleChange('supplierId', res[0].id);
              this.props.handleChange('selectedIAlertVpartId', res[0].iAlertVPartNumberId);
              // set current state values
              this.setState({
                supplierDetails: res,
                selectedSupplier: res[0].label,
                selectedSupplierId: res[0].id,
                selectedIAlertVpartId: res[0].iAlertVPartNumberId
              }, () => this.getSoftwareTypeDetails());
            } else {
              // empty parent values
              this.props.handleChange('supplier', null);
              this.props.handleChange('supplierId', null);
              this.props.handleChange('selectedIAlertVpartId', null);
              this.props.handleChange('softwareType', null);
              this.props.handleChange('bFileDetails', { data: [], selected: null, selectedName: '', selectedId: '' });
              this.props.handleChange('cFileDetails', { data: [], selected: null, selectedName: '', selectedId: '' });
              // empty current state values
              this.setState({
                supplierDetails: [],
                selectedSupplierId: '',
                selectedSupplier: '',
                selectedIAlertVpartId: '',
                softwareTypeDetails: [],
                selectedType: '',
                bFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                cFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                radioButtonsEnable: {
                  B: false,
                  C: false,
                  BPC: false,
                  BAC: false
                }
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getSoftwareTypeDetails() {
    const { selectedEcu, selectedSupplier } = this.state;
    softwareTypeDetailsApi(selectedEcu, selectedSupplier, null)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              // Explicitly adding B&C if both B and C are present. Works when res values are not repeated.
              if (res.includes('B') && res.includes('C')) res.push('B&C');
              // Update parent with the selected changes
              this.props.handleChange('softwareType', res[0]);
              // set current state values
              this.setState({
                softwareTypeDetails: res,
                selectedType: res[0]
              }, () => this.getFiles());
            } else {
              // empty parent values
              this.props.handleChange('softwareType', null);
              this.props.handleChange('bFileDetails', { data: [], selected: null, selectedName: '', selectedId: '' });
              this.props.handleChange('cFileDetails', { data: [], selected: null, selectedName: '', selectedId: '' });
              // empty current state values
              this.setState({
                softwareTypeDetails: [],
                selectedType: '',
                bFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                cFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                radioButtonsEnable: {
                  B: false,
                  C: false,
                  BPC: false,
                  BAC: false
                }
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getFiles() {
    const { selectedType, bFileDetails, cFileDetails } = this.state;
    this.checkEnability();
    if (bFileDetails.data.length || cFileDetails.data.length) {
      this.setState({
        bFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
        cFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
      });
    }
    if (selectedType === 'B&C') {
      this.getFileDetails('B');
      this.getFileDetails('C');
    } else {
      this.getFileDetails(selectedType);
    }
  }

  getFileDetails(type) {
    const { selectedEcu, selectedSupplier, selectedType } = this.state;
    fileDetailsApi(selectedEcu, selectedSupplier, type, null)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(item => {
                const d = new Date(item.effectivityDate);
                item.effDateString = `${d.toLocaleString('default', { month: 'short' })} ${d.getDate()} ${d.getFullYear()}`;
                item.label = `${item.fileName}  (Effective: ${item.effDateString})`;
                item.value = item.fileName;
              });
              const obj = { data: res, selected: res[0], selectedName: res[0].fileName, selectedId: res[0].id }
              if (type === 'B' || type === 'B+C') {
                // Update parent with the selected changes
                this.props.handleChange('bFileDetails', obj);
                // set current state values
                this.setState({ bFileDetails: obj });
              } else if (type === 'C') {
                // Update parent with the selected changes
                this.props.handleChange('cFileDetails', obj);
                // set current state values
                this.setState({ cFileDetails: obj });
              }
            } else {
              // empty parent values
              this.props.handleChange('bFileDetails', { data: [], selected: null, selectedName: '', selectedId: '' });
              this.props.handleChange('cFileDetails', { data: [], selected: null, selectedName: '', selectedId: '' });
              // empty current state values
              this.setState({
                bFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                cFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' }
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  checkEnability() {
    let { softwareTypeDetails, radioButtonsEnable } = this.state;
    if ((softwareTypeDetails.includes('B') && softwareTypeDetails.includes('C')) || softwareTypeDetails.includes('B&C')) {
      radioButtonsEnable = {
        B: true, C: true, BPC: false, BAC: true
      };
    } else if (softwareTypeDetails.includes('B+C')) {
      radioButtonsEnable = {
        B: false, C: false, BPC: true, BAC: false
      };
    } else if (softwareTypeDetails.includes('B')) {
      radioButtonsEnable = {
        B: true, C: false, BPC: false, BAC: false
      };
    } else if (softwareTypeDetails.includes('C')) {
      radioButtonsEnable = {
        B: false, C: true, BPC: false, BAC: false
      };
    } else {
      radioButtonsEnable = {
        B: false, C: false, BPC: false, BAC: false
      };
    }
    this.setState({ radioButtonsEnable });
  }

  onEcuGroupChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const { ecuDetails } = this.state;
    // Update parent with the selected changes
    this.props.handleChange('ecuGroup', ecuDetails[selectedIndex].ecuGroup);
    this.props.handleChange('ecuGroupId', ecuDetails[selectedIndex].id);
    // set current state values
    this.setState({
      selectedEcu: ecuDetails[selectedIndex].label,
      selectedEcuId: ecuDetails[selectedIndex].id
    }, () => this.getSupplierDetails());
  }

  onSupplierChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const { supplierDetails } = this.state;
    // Update parent with the selected changes
    this.props.handleChange('supplier', supplierDetails[selectedIndex].supplier);
    this.props.handleChange('supplierId', supplierDetails[selectedIndex].id);
    this.props.handleChange('selectedIAlertVpartId', supplierDetails[selectedIndex].iAlertVPartNumberId);
    // set current state values
    this.setState({
      selectedSupplier: supplierDetails[selectedIndex].label,
      selectedSupplierId: supplierDetails[selectedIndex].id,
      selectedIAlertVpartId: supplierDetails[selectedIndex].iAlertVPartNumberId,
    }, () => this.getSoftwareTypeDetails());
  }

  onSwtypeChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const { softwareTypeDetails } = this.state;
    // Update parent with the selected changes
    this.props.handleChange('softwareType', softwareTypeDetails[selectedIndex]);
    // set current state values
    this.setState({
      selectedType: softwareTypeDetails[selectedIndex],
    }, () => this.getFiles());
  }

  handleRadioClick = (type) => {
    const { softwareTypeDetails } = this.state;
    if (softwareTypeDetails.length && softwareTypeDetails.includes(type)) {
    // Update parent with the selected changes
    this.props.handleChange('softwareType', type);
      this.setState({
        selectedType: type,
      }, () => this.getFiles());
    }
  }

  onFileChange = (e, type) => {
    // const selectedIndex = e.target.selectedIndex;
    // const selectedValue = e.target.selectedOptions[0].value;
    // const selectedId = e.target.selectedOptions[0].id;
    let { bFileDetails, cFileDetails } = this.state;
    if (type === 'B') {
      bFileDetails.selected = e;
      bFileDetails.selectedName = e.fileName;
      bFileDetails.selectedId = e.id;
      // Update parent with the selected changes
      this.props.handleChange('bFileDetails', bFileDetails);
      // set current state values
      this.setState({ bFileDetails });
    } else if (type === 'C') {
      cFileDetails.selected = e;
      cFileDetails.selectedName = e.fileName;
      cFileDetails.selectedId = e.id;
      // Update parent with the selected changes
      this.props.handleChange('cFileDetails', cFileDetails);
      // set current state values
      this.setState({ cFileDetails });
    }
  };

  onCustomFileSelect = (e) => {
    let name = '';
    if (!e.target.files[0]) name = null;
    else name = e.target.files[0].name;
    this.setState({ customFileName: name });
  };

  onDescriptionKeyPress = (e) => {
    this.setState({ description: e.target.value });
  }

  onExpiryKeyPress = (e) => {
    this.setState({ scheduleExpiryDays: e.target.value });
  }

  render() {
    const { currentStep, isDataStored } = this.props;
    if (currentStep !== 1 || isDataStored) { // Prop: The current step
      return null
    }
    // The markup for the Step 1 UI
    const {
      ecuDetails,
      selectedEcu,
      supplierDetails,
      selectedSupplier,
      selectedType,
      softwareTypeDetails,
      bFileDetails,
      cFileDetails,
      description,
      scheduleExpiryDays,
      customFileName,
      radioButtonsEnable
    } = this.state;
    const { validated } = this.props;
    return (
      <>
        {/* Error Display */}
        {Boolean(this.state.error) ? (
          <InlineAlert error={this.state.error} />
        ) : null}
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="ecuGroup">
              <Form.Label>ECU Group</Form.Label>
              <Form.Control
                as="select"
                required
                name="ecuGroup"
                value={selectedEcu}
                onChange={this.onEcuGroupChange}
                >
                {ecuDetails.map((item, i) => {
                  return (
                    <option key={i} id={item.id} value={item.label}>{item.label}</option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a Group.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="supplier">
              <Form.Label>Supplier</Form.Label>
              <Form.Control
                as="select"
                required
                name="supplier"
                value={selectedSupplier}
                onChange={this.onSupplierChange}
                >
                {supplierDetails.map((item, i) => {
                  return (
                    <option key={i} id={item.id} value={item.label}>{item.label}</option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a Supplier.
                  </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="swType">
              <Form.Label>Software Type</Form.Label>
              <Form.Control
                as="select"
                required
                name="swType"
                value={selectedType}
                onChange={this.onSwtypeChange}
                >
                {softwareTypeDetails.map((item, i) => {
                  return (
                    <option key={i} value={item}>{item}</option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select Software Type.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="12" className="radioButton" controlId="swTypeRadio">
              <ButtonToolbar>
                <ToggleButtonGroup type="radio" name="fileType" value={selectedType}>
                  <ToggleButton
                    value={'B+C'}
                    disabled={!radioButtonsEnable.BPC}
                    checked={selectedType === 'B+C' ? true : false}
                    onClick={() => this.handleRadioClick('B+C')}
                    >
                    B+C
                  </ToggleButton>
                  <ToggleButton
                    value={'B'}
                    disabled={!radioButtonsEnable.B}
                    checked={selectedType === 'B' ? true : false}
                    onClick={() => this.handleRadioClick('B')}
                    >
                    B
                  </ToggleButton>
                  <ToggleButton
                    value={'C'}
                    disabled={!radioButtonsEnable.C}
                    checked={selectedType === 'C' ? true : false}
                    onClick={() => this.handleRadioClick('C')}
                    >
                    C
                  </ToggleButton>
                  <ToggleButton
                    value={'B&C'}
                    disabled={!radioButtonsEnable.BAC}
                    checked={selectedType === 'B&C' ? true : false}
                    onClick={() => this.handleRadioClick('B&C')}
                    >
                    B&C
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="baseDataset">
              <Form.Label>Base Dataset Part No.</Form.Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={bFileDetails.selected}
                isDisabled={selectedType === 'C'}
                isLoading={false}
                isClearable={false}
                isSearchable={true}
                name="color"
                options={bFileDetails.data}
                onChange={(e) => this.onFileChange(e, 'B')}
              />
              {validated && bFileDetails.selected == null ? (
                <>
                  <small className="text-danger">
                    Please select a File Name.
                  </small>
                </>
              ) : null}
            </Form.Group>
            {/* <Form.Group as={Col} md="6" controlId="baseDataset">
              <Form.Label>Base Dataset Part No.</Form.Label>
              <Form.Control
                as="select"
                required
                name="baseDataset"
                value={bFileDetails.selectedName}
                disabled={selectedType === 'C'}
                onChange={(e) => this.onFileChange(e, 'B')}
                >
                {bFileDetails.data.map((item, i) => {
                  return (
                    <option key={i} id={item.id} value={item.fileName}>
                      {item.fileName}&emsp;(Effective: {item.effDateString})
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a File Name.
              </Form.Control.Feedback>
            </Form.Group> */}

            <Form.Group as={Col} md="6" controlId="calibDataset">
              <Form.Label>Calibration Dataset Part No.</Form.Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={cFileDetails.selected}
                isDisabled={selectedType === 'B' || selectedType === 'B+C'}
                isLoading={false}
                isClearable={false}
                isSearchable={true}
                name="color"
                options={cFileDetails.data}
                onChange={(e) => this.onFileChange(e, 'C')}
              />
              {validated && cFileDetails.selected == null ? (
                <>
                  <small className="text-danger">
                    Please select a File Name.
                  </small>
                </>
              ) : null}
            </Form.Group>
            {/* <Form.Group as={Col} md="6" controlId="calibDataset">
              <Form.Label>Calibration Dataset Part No.</Form.Label>
              <Form.Control
                as="select"
                required
                name="calibDataset"
                value={cFileDetails.selectedName}
                disabled={selectedType === 'B' || selectedType === 'B+C'}
                onChange={(e) => this.onFileChange(e, 'C')}
                >
                {cFileDetails.data.map((item, i) => {
                  if (item.softwareType === 'C') {
                    return (
                      <option key={i} id={item.id} value={item.fileName}>
                        {item.fileName}&emsp;(Effective: {item.effDateString})
                      </option>
                    );
                  }
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select File Name.
              </Form.Control.Feedback>
            </Form.Group> */}

            <Form.Group as={Col} md="12" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                required
                name="description"
                type="text"
                placeholder="Description"
                maxLength={45}
                defaultValue={description}
                onKeyUp={(e) => this.onDescriptionKeyPress(e)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter schedule description.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="file">
              <Form.Label>Select File</Form.Label>
              <Form.File id="custom-file" custom>
                <Form.File.Input
                  name="file" 
                  required custom
                  onChange={(e) => this.onCustomFileSelect(e)}
                />
                <Form.File.Label data-browse="Browse">
                  <i className="fa fa-upload"></i> {customFileName || 'Choose File'}
                </Form.File.Label>
                <Form.Control.Feedback type="invalid">
                  Please select a file.
                </Form.Control.Feedback>
                <a href='/sample/schedule_vin_upload_sample.csv' download>Click to download sample file</a>
              </Form.File>
            </Form.Group>

            <Form.Group as={Col} md="8" controlId="scheduleExpiry">
              <Form.Label>Schedule Expiry</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  required
                  type="text"
                  min="1"
                  name="scheduleExpiryDays"
                  max={365}
                  placeholder="Days"
                  minLength={1}
                  maxLength={3}
                  defaultValue={scheduleExpiryDays}
                  pattern="^(?:[1-9]\d?|[12]\d{2}|3[0-5]\d|36[0-5])$"
                  onKeyUp={(e) => this.onExpiryKeyPress(e)}
                />
                <InputGroup.Append className="expiry-text">
                  <InputGroup.Text id="basic-addon2">
                    days from date of approval
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  Please select days within range from 1 to 365 only.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>

          {/* <Button type="submit" disabled={this.state.disable} style={{ float: 'right' }}>
            {this.state.disable ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                role="status"
              />
            ) : null}
            Create
          </Button>
        </Form> */}
      </>
    )
  }
}

export default EcuFlashBulk;
import React from "react";
import {Alert} from "react-bootstrap"

const InlineAlert = props => {


    const [showAlert, setShowAlert] = React.useState(true);

    if (showAlert) {
      return (
        <Alert variant="danger" onClose={() => setShowAlert(false)}>
          {/*<Alert.Heading>Oh snap! You got an error!</Alert.Heading>*/}
          <p className="mb-0">
            {props.error.message}
          </p>
        </Alert>
      );
    }
  return <span></span>;
  }

export default InlineAlert;
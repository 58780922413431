import React, { Component } from "react";
import {
  scheduleDetailApi,
  scheduleVehiclesApi,
  triggerScheduleApi,
  vehicleActivityApi,
  prevVehicleActivityApi,
  forceCancelApi,
  errorLogsApi,
  readDtcVehiclesApi,
  clearDtcVehiclesApi,
  readParamVehiclesApi,
  freezeFrameVehiclesApi,
  updateParamVehiclesApi,
  retryTriggerApi,
  iAlertVehiclesApi,
  downloadReportApi,
  scheduleVehicleSearchApi
} from "../../utill/api/schedulesApi";
import CustomDataTable from "../../shared/customDataTable/customDataTable";
import { Container, Row, Col, Button, ListGroup, ProgressBar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import DateConverter from "../../shared/dateConverter/dateConverter";
import "./ScheduleDetail.scss";
import ScheduleTrigger from "./ScheduleTrigger";
import Search from "../../shared/search/search";
import ConfirmDeleteModal from "../../shared/modals/confirmDeleteModal";
import ToastAlert from "../../shared/notificationAlert/toastAlert";
import { scheduleStatus, scheduleType, vehicleStatus } from "../../utill/config";
import StatusBar from "../../shared/statusBar/StatusBar";
import ActivityModal from "./activityModal";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import RetryTrigger from "./RetryTrigger";
import CancelStatusModal from "./CancelStatusModal";
import ServerSearch from "../../shared/server-search/server-serach";

class ScheduleDetail extends Component {
  vehicleColumns = [
    {
      name: "#",
      sortable: true,
      minWidth: "60px",
      maxWidth: "50px",
      cell: row => row.index
    },
    {
      name: "VIN",
      selector: "vin",
      sortable: true,
      minWidth: "200px",
      // cell: row => <span>{row.vin}</span>
      cell: row => (
        <div style={{ display: 'block' }}>
          <Link onClick={() => this.setVehicleForActivity(row)}>{row.vin}</Link><br />
          <span className="text-secondary">{row.vPartNumber}</span>
        </div>
      )
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      minWidth: "250px",
      cell: row => (
        <span>
          {vehicleStatus && vehicleStatus[row.status]
            ? vehicleStatus[row.status]
            : row.status}
          {row.status === 'FILE_DWLD_COMPLETED' ? <span> ({row.fileDownloadTimeInSeconds}s)</span> : null}
          {row.status === 'FLASH_ECU_COMPLETED' || row.status === 'FLASH_COMPLETED' ? <span> ({row.flashDownloadTimeInSeconds}s)</span> : null}
          {row.status === 'FLASH_REQ' ? <>
            <div>
              <ProgressBar now={row.progress ? row.progress : 0} label={`${row.progress ? row.progress : 0}%`} />
            </div>
          </> : null}
          {row.status === 'FLASH_FAILED' ?
            <>
              <div><code>Stage - {row.stage}</code></div>
              <div><code>Reason - {row.reason}</code></div>
            </>
          : null}
          {row.status !== 'FLASH_FAILED' && row.reason ?
            <>
              <div><code>{row.reason}</code></div>
            </>
          : null}
        </span>
      )
    },
    {
      name: "Software Version",
      selector: "softwareVersion",
      sortable: true,
      minWidth: "350px",
      cell: row => (
        <span>
          <code className="beforeVersion">
            {row.swVersionBeforeFlash ? row.swVersionBeforeFlash : '-----'}
          </code>
          <span> {'>'} </span>
          <code className="afterVersion">
            {row.swVersionAfterFlash ? row.swVersionAfterFlash : '-----'}
          </code>
        </span>
      )
    },
    // {
    //   name: "Updated At",
    //   selector: "updatedTimeStamp",
    //   sortable: true,
    //   minWidth: "250px",
    //   cell: row => <DateConverter date={row.updatedTimeStamp} />
    // },
    // {
    //   name: "Triggered By",
    //   selector: "triggeredByName",
    //   sortable: true,
    //   minWidth: "200px",
    //   cell: row => <div>{row.triggeredByName}</div>
    // },
    {
      name: "Location",
      selector: "address",
      sortable: true,
      minWidth: "300px",
      cell: row => (
        <div>
          {row.address ?
            <>
              <span class="longString">
                <a href={`https://www.google.com/maps/@${row.latitude},${row.longitude},16z`} target="_blank">
                  {row.address}
                </a>
              </span>
            </>
            :
            <>
              <a href={`https://www.google.com/maps/@${row.latitude},${row.longitude},16z`} target="_blank">
                Google Map
              </a>
            </>
          }
        </div>
      )
    },
    // {
    //   name: "Gsm Strength",
    //   selector: "gsmStrength",
    //   sortable: true,
    //   minWidth: "150px",
    //   cell: row => (
    //     <>
    //       {row.gsmStrength <= -110 ? <span title="Disconnected." className="badge badge-danger">&nbsp; No Signal ({row.gsmStrength} dBm) &nbsp;</span> : null}
    //       {row.gsmStrength > -110 && row.gsmStrength < -100 ? <span title="Performance drops drastically." className="badge badge-warning">&nbsp; Poor ({row.gsmStrength} dBm) &nbsp;</span> : null}
    //       {row.gsmStrength >= -100 && row.gsmStrength < -85 ? <span title="Fair but useful, fast and reliable data speeds may be attained, but marginal data drop-outs is possible." className="badge badge-primary">&nbsp; Fair ({row.gsmStrength} dBm) &nbsp;</span> : null}
    //       {row.gsmStrength >= -85 && row.gsmStrength < -70 ? <span title="Strong Signal with good data speeds." className="badge badge-info">&nbsp; Good ({row.gsmStrength} dBm) &nbsp;</span> : null}
    //       {row.gsmStrength >= -70 ? <span title="Strong Signal with maximum data speeds." className="badge badge-success">&nbsp; Excellent ({row.gsmStrength} dBm) &nbsp;</span> : null}
    //     </>
    //   )
    // },
    {
      name: "Communication Status",
      selector: "eventTime",
      sortable: true,
      minWidth: "300px",
      cell: row => <>
        <div>
          <p className="mb-1">
            GSM: &nbsp;
            {row.gsmStrength <= -110 ? <i class="fa fa-signal text-danger" aria-hidden="true"></i> : null}
            {row.gsmStrength > -110 && row.gsmStrength < -100 ? <i class="fa fa-signal text-warning" aria-hidden="true"></i> : null}
            {row.gsmStrength >= -100 && row.gsmStrength < -85 ? <i class="fa fa-signal text-primary" aria-hidden="true"></i> : null}
            {row.gsmStrength >= -85 && row.gsmStrength < -70 ? <i class="fa fa-signal text-info" aria-hidden="true"></i> : null}
            {row.gsmStrength >= -70 ? <i class="fa fa-signal text-success" aria-hidden="true"></i> : null}
          </p>
          <p className="mb-1">Last Comm: {this.checkLastCommStatus(row.eventTime)}</p>
        </div>
      </>
    },
    // {
    //   name: "Ignition Status",
    //   selector: "ignitionStatus",
    //   sortable: true,
    //   minWidth: "150px",
    //   cell: row => <div>{row.ignitionStatus}</div>
    // },
    // {
    //   name: "Engine Status",
    //   selector: "engineStatus",
    //   sortable: true,
    //   minWidth: "150px",
    //   cell: row => <div>{row.engineStatus}</div>
    // },
    {
      name: "Vehicle Status",
      selector: "vehicleStatus",
      sortable: true,
      minWidth: "200px",
      cell: row => <>
        <div>
          <p className="mb-1">Ignition: <b>{row.ignitionStatus}</b></p>
          <p className="mb-1">Engine: <b>{row.engineStatus}</b></p>
          <p className="mb-1">Vehicle: <b>{row.vehicleStatus}</b></p>
        </div>
      </>
    },
    {
      name: "Action",
      selector: "action",
      sortable: true,
      minWidth: "200px",
      cell: row => (
        <>
          <ScheduleTrigger
            row={row}
            scheduleDetail={this.state.scheduleDetail}
            confirmDelete={this.confirmDelete}
          />
          <RetryTrigger
            row={row}
            schedule={this.state.scheduleDetail}
            confirmDelete={this.confirmDelete}
          />
        </>
      )
    }
  ];

  logsColumns = [
    {
      name: "#",
      sortable: true,
      minWidth: "60px",
      maxWidth: "50px",
      cell: row => row.index
    },
    {
      name: "VIN",
      sortable: true,
      maxWidth: "200px",
      cell: row => row.vin
    },
    {
      name: "Log",
      sortable: true,
      minWidth: "700px",
      cell: row => row.log
    },
    {
      name: "Time",
      sortable: true,
      maxWidth: "300px",
      cell: row => row.time
    }
  ];

  clearDtcColumns = [
    {
      name: "#",
      sortable: true,
      minWidth: "60px",
      maxWidth: "50px",
      cell: row => row.index
    },
    {
      name: "VIN",
      sortable: true,
      minWidth: "200px",
      cell: row => (
        <div style={{ display: 'block' }}>
          <Link onClick={() => this.setVehicleForActivity(row)}>{row.vin}</Link><br />
          <span className="text-secondary">{row.vPartNumber}</span>
        </div>
      )
    },
    {
      name: "Ecu Name",
      sortable: true,
      minWidth: "200px",
      selector: "ecuName"
    },
    {
      name: "Status",
      sortable: true,
      minWidth: "300px",
      cell: row => (
        <span>
          {vehicleStatus && vehicleStatus[row.status]
            ? vehicleStatus[row.status]
            : row.status}
          {row.status && row.reason ?
            <>
              <div><code>{row.reason}</code></div>
            </>
          : null}
        </span>
      )
    },
    {
      name: "Time",
      sortable: true,
      minWidth: "200px",
      cell: row => <DateConverter date={row.eventTime} />
    },
    {
      name: "Received At",
      sortable: true,
      minWidth: "200px",
      cell: row => <DateConverter date={row.createdAt} />
    },
    {
      name: "Pre DTC Count",
      sortable: true,
      minWidth: "150px",
      selector: "preDTCCount"
    },
    {
      name: "Post DTC Count",
      sortable: true,
      minWidth: "150px",
      selector: "postDTCCount"
    },
    {
      name: "Action",
      selector: "action",
      sortable: true,
      minWidth: "200px",
      cell: row => (
        <>
          <ScheduleTrigger
            row={row}
            scheduleDetail={this.state.scheduleDetail}
            confirmDelete={this.confirmDelete}
          />
        </>
      )
    }
  ];

  readDtcColumns = [
    {
      name: "#",
      sortable: true,
      minWidth: "60px",
      maxWidth: "50px",
      cell: row => row.index
    },
    {
      name: "VIN",
      sortable: true,
      minWidth: "200px",
      cell: row => (
        <div style={{ display: 'block' }}>
          <Link onClick={() => this.setVehicleForActivity(row)}>{row.vin}</Link><br />
          <span className="text-secondary">{row.vPartNumber}</span>
        </div>
      )
    },
    {
      name: "Ecu Name",
      sortable: true,
      minWidth: "200px",
      selector: "ecuName"
    },
    {
      name: "Time",
      sortable: true,
      minWidth: "200px",
      cell: row => <DateConverter date={row.eventTime} />
    },
    {
      name: "Status",
      sortable: true,
      minWidth: "200px",
      cell: row => (
        <span>
          {vehicleStatus && vehicleStatus[row.status]
            ? vehicleStatus[row.status]
            : row.status}
          {row.status && row.reason ?
            <>
              <div><code>{row.reason}</code></div>
            </>
          : null}
        </span>
      )
    },
    {
      name: "Fault Counts",
      sortable: true,
      minWidth: "200px",
      cell: row => row.faultCount
    },
    {
      name: "Received At",
      sortable: true,
      minWidth: "200px",
      cell: row => <DateConverter date={row.createdAt} />
    },
    {
      name: "Action",
      selector: "action",
      sortable: true,
      minWidth: "300px",
      cell: row => (
        <>
          {row.status === "SUCCESS" ?
            <Button
              className="btn action-btn mr-2"
              size="sm"
              onClick={() => this.getDiagnostics(row)}
            >
              <i className="fa fa-eye action-btn-icon">&nbsp;</i>
              View PCODE
            </Button>
            : null}
          <ScheduleTrigger
            row={row}
            scheduleDetail={this.state.scheduleDetail}
            confirmDelete={this.confirmDelete}
          />
        </>
      )
    }
  ];

  readParamColumns = [
    {
      name: "#",
      sortable: true,
      minWidth: "60px",
      maxWidth: "50px",
      cell: row => row.index
    },
    {
      name: "VIN",
      sortable: true,
      minWidth: "200px",
      cell: row => (
        <div style={{ display: 'block' }}>
          <Link onClick={() => this.setVehicleForActivity(row)}>{row.vin}</Link><br />
          <span className="text-secondary">{row.vPartNumber}</span>
        </div>
      )
    },
    {
      name: "Time",
      sortable: true,
      minWidth: "200px",
      cell: row => <DateConverter date={row.eventTime} />
    },
    {
      name: "Status",
      sortable: true,
      minWidth: "200px",
      cell: row => (
        <span>
          {vehicleStatus && vehicleStatus[row.status]
            ? vehicleStatus[row.status]
            : row.status}
          {row.status && row.reason ?
            <>
              <div><code>{row.reason}</code></div>
            </>
          : null}
        </span>
      )
    },
    {
      name: "Identifier Counts",
      sortable: true,
      minWidth: "200px",
      cell: row => row.identifierCount
    },
    {
      name: "Received At",
      sortable: true,
      minWidth: "200px",
      cell: row => <DateConverter date={row.createdAt} />
    },
    {
      name: "Action",
      selector: "action",
      sortable: true,
      minWidth: "150px",
      cell: row => (
        <>
          {/* {row.status === "SUCCESS" ?
            <Button
              className="btn action-btn mr-2"
              size="sm"
              onClick={() => this.getDiagnostics(row)}
            >
              <i className="fa fa-eye action-btn-icon">&nbsp;</i>
              View Parameters
            </Button>
          : null} */}
          <ScheduleTrigger
            row={row}
            scheduleDetail={this.state.scheduleDetail}
            confirmDelete={this.confirmDelete}
          />
        </>
      )
    }
  ];

  freezeFrameColumns = [
    {
      name: "#",
      sortable: true,
      minWidth: "60px",
      maxWidth: "50px",
      cell: row => row.index
    },
    {
      name: "VIN",
      sortable: true,
      minWidth: "200px",
      cell: row => (
        <div style={{ display: 'block' }}>
          <Link onClick={() => this.setVehicleForActivity(row)}>{row.vin}</Link><br />
          <span className="text-secondary">{row.vPartNumber}</span>
        </div>
      )
    },
    {
      name: "Ecu Name",
      sortable: true,
      minWidth: "200px",
      selector: "ecuName"
    },
    {
      name: "Fault Code",
      sortable: true,
      minWidth: "200px",
      cell: row => row.faultCode
    },
    {
      name: "Failure Type",
      sortable: true,
      minWidth: "200px",
      cell: row => row.failureType
    },
    {
      name: "Status",
      sortable: true,
      minWidth: "200px",
      cell: row => (
        <span>
          {vehicleStatus && vehicleStatus[row.status]
            ? vehicleStatus[row.status]
            : row.status}
          {row.status && row.reason ?
            <>
              <div><code>{row.reason}</code></div>
            </>
            : null}
        </span>
      )
    },
    {
      name: "Record Number",
      sortable: true,
      minWidth: "200px",
      cell: row => row.ffRecordNumber
    },
    {
      name: "Identifier Counts",
      sortable: true,
      minWidth: "200px",
      cell: row => row.identifierCount
    },
    {
      name: "Time",
      sortable: true,
      minWidth: "200px",
      cell: row => <DateConverter date={row.eventTime} />
    },
    {
      name: "Received At",
      sortable: true,
      minWidth: "300px",
      cell: row => <DateConverter date={row.createdAt} />
    },
    {
      name: "Action",
      selector: "action",
      sortable: true,
      minWidth: "250px",
      cell: row => (
        <>
          {row.status === "SUCCESS" ?
            <Button
              className="btn action-btn mr-2"
              size="sm"
              onClick={() => this.getDiagnostics(row)}
            >
              <i className="fa fa-eye action-btn-icon">&nbsp;</i>
              View Data
            </Button>
          : null}
          <ScheduleTrigger
            row={row}
            scheduleDetail={this.state.scheduleDetail}
            confirmDelete={this.confirmDelete}
          />
        </>
      )
    }
  ];

  updateParamColumns = [
    {
      name: "#",
      sortable: true,
      minWidth: "60px",
      maxWidth: "50px",
      cell: row => row.index
    },
    {
      name: "VIN",
      selector: "vin",
      sortable: true,
      minWidth: "200px",
      // cell: row => <span>{row.vin}</span>
      cell: row => (
        <div style={{ display: 'block' }}>
          <Link onClick={() => this.setVehicleForActivity(row)}>{row.vin}</Link><br />
          <span className="text-secondary">{row.vPartNumber}</span>
        </div>
      )
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      minWidth: "250px",
      cell: row => (
        <span>
          {vehicleStatus && vehicleStatus[row.status]
            ? vehicleStatus[row.status]
            : row.status}
          {row.status === 'FILE_DWLD_COMPLETED' ? <span> ({row.fileDownloadTimeInSeconds}s)</span> : null}
          {row.status === 'FLASH_ECU_COMPLETED' || row.status === 'FLASH_COMPLETED' ? <span> ({row.flashDownloadTimeInSeconds}s)</span> : null}
          {row.status === 'FLASH_REQ' ? <>
            <div>
              <ProgressBar now={row.progress ? row.progress : 0} label={`${row.progress ? row.progress : 0}%`} />
            </div>
          </> : null}
          {row.status === 'FLASH_FAILED' ?
            <>
              <div><code>Stage - {row.stage}</code></div>
              <div><code>Reason - {row.reason}</code></div>
            </>
            : null}
          {row.status !== 'FLASH_FAILED' && row.reason ?
            <>
              <div><code>{row.reason}</code></div>
            </>
            : null}
        </span>
      )
    },
    {
      name: "Parameter Name",
      selector: "parameterName",
      sortable: true,
      minWidth: "250px",
      cell: row => <div>{row.parameterName}</div>
    },
    {
      name: "Parameter Value",
      selector: "parameterValue",
      sortable: true,
      minWidth: "250px",
      cell: row => <div>{row.parameterValue}</div>
    },
    {
      name: "Updated At",
      selector: "updatedTimeStamp",
      sortable: true,
      minWidth: "250px",
      cell: row => <DateConverter date={row.updatedTimeStamp} />
    },
    {
      name: "Triggered By",
      selector: "triggeredByName",
      sortable: true,
      minWidth: "200px",
      cell: row => <div>{row.triggeredByName}</div>
    },
    {
      name: "Action",
      selector: "action",
      sortable: true,
      minWidth: "200px",
      cell: row => (
        <>
          <ScheduleTrigger
            row={row}
            scheduleDetail={this.state.scheduleDetail}
            confirmDelete={this.confirmDelete}
          />
          {/* <Button
            className="btn action-btn mr-2"
            size="sm"
            onClick={() => this.getDiagnostics(row)}
          >
            <i className="fa fa-eye action-btn-icon">&nbsp;</i>
            View Data
          </Button> */}
        </>
      )
    }
  ];

  vehicleCurrentPage = 0;
  logsCurrentPage = 0;
  currentUser = JSON.parse(localStorage.getItem("currentUser"));

  constructor(props) {
    super(props);
    this.state = {
      user: this.props.match.params.scheduleId,
      type: this.props.match.params.type,
      scheduleDetail: {},
      scheduleVehicles: [],
      allVehicles: [],
      vehicleLoading: true,
      columns: [],
      logs: [],
      logsLoading: true,
      showLogsTable: true,
      showDeleteModal: false,
      disableConfirmButton: false,
      diagnosticsModal: false,
      activityModal: false,
      activeActivityKey: 'thisSchedule',
      deleteData: {},
      diagonisedVehicle: {},
      activityData: [],
      activityLoading: true,
      error: null,
      refreshing: "",
      timerKey: 1,
      isManual: false,
      currentUser: JSON.parse(localStorage.getItem("currentUser")),
      showCancelStatusModal: false,
      searchKey: 'vin',
      searchValue: ''
    };
    // this.getScheduleDetail();
    /**
     * This is not called here because the useEffect Hook in the customDataTable
     * component calls it automatically in the initial rendering.
     * This methodology is followed across all class components using customDataTable.
     */
    // this.getScheduleVehicles();
    // this.getScheduleLogs();
    this.getScheduleVehicles = this.getScheduleVehicles.bind(this);
    this.getVehiclesReadDtc = this.getVehiclesReadDtc.bind(this);
    this.getVehiclesClearDtc = this.getVehiclesClearDtc.bind(this);
    this.getVehiclesReadParam = this.getVehiclesReadParam.bind(this);
    this.getVehiclesFreezeFrame = this.getVehiclesFreezeFrame.bind(this);
    this.getandsetDynamicHeaders = this.getandsetDynamicHeaders.bind(this);
  }

  componentDidMount() {
    if (this.currentUser == null || this.currentUser === undefined) {
      localStorage.clear();
      this.props.history.push("/login");
    }
  }

  checkLastCommStatus = (time) => {
    const style = {fontSize: '12px'}
    const date = Date.parse(new Date());
    if (date - time > 300000) return <span className="badge badge-secondary" style={style}><DateConverter date={time} /></span>
    return <span className="badge badge-success" style={style}><DateConverter date={time} /></span>
  };

  getScheduleDetail = () => {
    scheduleDetailApi(this.state.user)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              // vehicleLoading: false,
              disableConfirmButton: false
            });
          });
        } else {
          res.json().then(res => {
            res["totalDevice"] =
              res.error +
              res.inProgress +
              res.scheduled +
              res.completed +
              res.cancelled;
            this.setState({
              scheduleDetail: res,
              fileName: res.fileName,
            }, () => this.loadVehicles(0));
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          vehicleLoading: false,
          disableConfirmButton: false
        });
      });
  };

  loadVehicles = (page) => {
    const { type, currentUser, isManual } = this.state;
    if (type === 'readDtc') {
      this.getVehiclesReadDtc(page);
      this.setState({ columns: this.readDtcColumns });
    } else if (type === 'clearDtc') {
      this.getVehiclesClearDtc(page);
      this.setState({ columns: this.clearDtcColumns });
    } else if (type === 'readParameters') {
      this.getVehiclesReadParam(page);
      // Headers are set after getting dynamic headers from API
      // this.setState({ columns: this.readParamColumns });
    } else if (type === 'readFreezeFrame') {
      this.getVehiclesFreezeFrame(page);
      this.setState({ columns: this.freezeFrameColumns });
    } else if (type === 'updateParameters') {
      this.getVehiclesUpdateParam(page);
      this.setState({ columns: this.updateParamColumns });
    } else if (type === 'flashEcu' && currentUser.userGroupId == 2 && isManual) {
      this.loadiAlertVehicles(page);
      this.setState({ columns: this.vehicleColumns });
    } else {
      this.getScheduleVehicles(page);
      this.setState({ columns: this.vehicleColumns });
    }
  };

  loadiAlertVehicles = (page) => {
    iAlertVehiclesApi(page, this.state.user)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              vehicleLoading: false,
              showDeleteModal: false,
              disableConfirmButton: false,
              refreshing: ""
            });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.setVehicleIndexNumber(res, page);
              if (this.state.showDeleteModal) {
                this.setState({
                  showDeleteModal: false,
                  disableConfirmButton: false,
                  refreshing: ""
                });
              }
            } else this.setState({ vehicleLoading: false, refreshing: "" });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          vehicleLoading: false,
          showDeleteModal: false,
          disableConfirmButton: false,
          refreshing: ""
        });
      });
  }

  getScheduleVehicles(page) {
    scheduleVehiclesApi(page, this.state.user)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              vehicleLoading: false,
              showDeleteModal: false,
              disableConfirmButton: false,
              refreshing: ""
            });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.setVehicleIndexNumber(res, page);
              if (this.state.showDeleteModal) {
                this.setState({
                  showDeleteModal: false,
                  disableConfirmButton: false,
                  refreshing: ""
                });
              }
            } else this.setState({ vehicleLoading: false, refreshing: "" });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          vehicleLoading: false,
          showDeleteModal: false,
          disableConfirmButton: false,
          refreshing: ""
        });
      });
  }

  getVehiclesReadDtc(page) {
    readDtcVehiclesApi(page, this.state.user)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              vehicleLoading: false,
              showDeleteModal: false,
              disableConfirmButton: false,
              refreshing: ""
            });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.setVehicleIndexNumber(res, page);
            } else this.setState({ vehicleLoading: false, refreshing: "" });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          vehicleLoading: false,
          showDeleteModal: false,
          disableConfirmButton: false,
          refreshing: ""
        });
      });
  }

  getVehiclesClearDtc(page) {
    clearDtcVehiclesApi(page, this.state.user)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              vehicleLoading: false,
              showDeleteModal: false,
              disableConfirmButton: false,
              refreshing: ""
            });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.setVehicleIndexNumber(res, page);
            } else this.setState({ vehicleLoading: false, refreshing: "" });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          vehicleLoading: false,
          showDeleteModal: false,
          disableConfirmButton: false,
          refreshing: ""
        });
      });
  }

  getVehiclesReadParam(page) {
    readParamVehiclesApi(page, this.state.user)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              vehicleLoading: false,
              showDeleteModal: false,
              disableConfirmButton: false,
              refreshing: ""
            });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.getandsetDynamicHeaders(res);
              this.setVehicleIndexNumber(res, page);
            } else this.setState({ vehicleLoading: false, refreshing: "" });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          vehicleLoading: false,
          showDeleteModal: false,
          disableConfirmButton: false,
          refreshing: ""
        });
      });
  }

  getandsetDynamicHeaders(res) {
    let headerKeys = [];
    if (res[0].supportedParameters) headerKeys = res[0].supportedParameters.split(',');
    const columnPosition = this.readParamColumns.length - 1;
    if (headerKeys.length) headerKeys.map(header => {
      const obj = {
        name: header,
        sortable: false,
        minWidth: "300px",
        cell: row => row.parameterMap && row.parameterMap[header] ? row.parameterMap[header] : '-----'
      }
      // this.readParamColumns.push(obj);
      this.readParamColumns.splice(columnPosition, 0, obj);
    });
    this.setState({ columns: this.readParamColumns });
  }

  getVehiclesFreezeFrame(page) {
    freezeFrameVehiclesApi(page, this.state.user)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              vehicleLoading: false,
              showDeleteModal: false,
              disableConfirmButton: false,
              refreshing: ""
            });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.setVehicleIndexNumber(res, page);
            } else this.setState({ vehicleLoading: false, refreshing: "" });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          vehicleLoading: false,
          showDeleteModal: false,
          disableConfirmButton: false,
          refreshing: ""
        });
      });
  };

  getVehiclesUpdateParam(page) {
    updateParamVehiclesApi(page, this.state.user)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              vehicleLoading: false,
              showDeleteModal: false,
              disableConfirmButton: false,
              refreshing: ""
            });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.setVehicleIndexNumber(res, page);
            } else this.setState({ vehicleLoading: false, refreshing: "" });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          vehicleLoading: false,
          showDeleteModal: false,
          disableConfirmButton: false,
          refreshing: ""
        });
      });
  }

  getScheduleLogs = page => {
    errorLogsApi(this.state.scheduleDetail.scheduleId)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            // console.log(res);
            this.setState({
              error: res,
              logsLoading: false,
              refreshing: ""
            });
          });
        } else {
          res.json().then(res => {
            this.setState({ refreshing: "" });
            if (res.length != 0) {
              this.setLogsIndexNumber(res, page);
            } else this.setState({ logsLoading: false, showLogsTable: false });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ logsLoading: false });
      });
  };

  setVehicleIndexNumber = (data, page) => {
    if (page == 0) {
      this.setState({ allVehicles: [] });
    }
    data.map((row, index) => {
      return (row.index = this.state.allVehicles.length + index + 1);
    });
    if (page == 0) {
      this.setState({
        scheduleVehicles: data,
        allVehicles: data,
        vehicleLoading: false,
        refreshing: ""
      });
    } else {
      this.setState(prevState => ({
        scheduleVehicles: [...prevState.scheduleVehicles, ...data],
        allVehicles: [...prevState.allVehicles, ...data],
        vehicleLoading: false,
        refreshing: ""
      }));
    }
  };

  setLogsIndexNumber = (data, page) => {
    if (page == 0) {
      this.setState({ logs: [] });
    }
    data.map((row, index) => {
      return (row.index = this.state.logs.length + index + 1);
    });
    if (page == 0) {
      this.setState({
        logs: data,
        showLogsTable: true,
        logsLoading: false
      });
    } else {
      this.setState(prevState => ({
        logs: [...prevState.logs, ...data],
        logsLoading: false
      }));
    }
  };

  getDiagnostics = vehicle => {
    const { user, scheduleDetail } = this.state;
    localStorage.setItem("selectedSchedule", JSON.stringify(scheduleDetail));
    localStorage.setItem("selectedVehicle", JSON.stringify(vehicle));
    let id = 0;
    if (scheduleDetail.type === 'updateParameters' || scheduleDetail.type === 'readDtc') {
      id = vehicle.id;
    } else {
      id = vehicle.scheduleVehicleId;
    }
    this.props.history.push(
      `/diagnostics/${scheduleDetail.type}/${user}/${id}`
    );
  };

  setVehicleForActivity = vehicle => {
    this.setState({
      diagonisedVehicle: {},
      activityData: [],
      activityLoading: true,
      activeActivityKey: 'thisSchedule'
    });
    this.toggleActivityModal();
    this.getActivityForCurrentSchedule(vehicle);
  };

  getActivityForCurrentSchedule = vehicle => {
    const { type } = this.state;
    let id = 0;
    if (type === 'flashEcu' || type === 'updateParameters') {
      id = vehicle.id;
    } else {
      id = vehicle.scheduleVehicleId;
    }
    vehicleActivityApi(id)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            // console.log(res);
            this.setState({
              activityData: res,
              diagonisedVehicle: vehicle,
              activityLoading: false
            });
          });
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              activityLoading: false
            });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          activityLoading: false
        });
      });
  };

  getActivityForPrevSchedule = vehicle => {
    prevVehicleActivityApi(vehicle.vin, 0)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            // console.log(res);
            this.setState({
              activityData: res,
              diagonisedVehicle: vehicle,
              activityLoading: false
            });
          });
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              activityLoading: false
            });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          activityLoading: false
        });
      });
  };

  updateStatus = () => {
    this.setState({ disableConfirmButton: true });
    const { deleteData } = this.state;
    if (deleteData.scope === "forceCancel") {
      this.forceCancel(deleteData);
    } else if (deleteData.scope === "retryTrigger") {
      this.retryTrigger(deleteData);
    } else {
      this.triggerSchedule(deleteData);
    }
  };

  triggerSchedule = data => {
    const payload = {
      serialNumber: data.row.serialNumber,
      scope: data.scope,
      fileName: this.state.scheduleDetail.fileName
    };
    triggerScheduleApi(payload)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            res.successCode = data.scope;
            this.setState({
              error: res,
              // deleteData: {}
            });
          });
          this.getScheduleDetail();
          this.getScheduleVehicles("0");
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              showDeleteModal: false,
              disableConfirmButton: false
            });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          showDeleteModal: false,
          disableConfirmButton: false
        });
      });
  };

  forceCancel = data => {
    forceCancelApi(data.row.serialNumber)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            res.successCode = data.scope;
            this.setState({
              error: res,
              showDeleteModal: false,
              disableConfirmButton: false
            });
          });
          if (this.state.scheduleDetail.type === 'flashEcu' && data.row.status.includes('FLASH')) {
            this.setState({
              showCancelStatusModal: true,
            });
          } else {
            this.getScheduleDetail();
            // this.getScheduleVehicles("0");
          }
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              showDeleteModal: false,
              disableConfirmButton: false
            });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          showDeleteModal: false,
          disableConfirmButton: false
        });
      });
  };

  retryTrigger = data => {
    let id = '';
    if (this.state.type === 'flashEcu') {
      id = data.row.id;
    } else {
      id = data.row.scheduleVehicleId;
    }
    retryTriggerApi(id)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            res.successCode = data.scope;
            this.setState({
              error: res,
              // deleteData: {}
            });
          });
          this.getScheduleDetail();
          this.getScheduleVehicles("0");
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              showDeleteModal: false,
              disableConfirmButton: false
            });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          showDeleteModal: false,
          disableConfirmButton: false
        });
      });
  };

  downloadReport = () => {
    const { scheduleDetail } = this.state;
    let type = '';
    if (scheduleDetail.type === 'flashEcu') type = 'ecu-flash';
    if (scheduleDetail.type === 'readDtc') type = 'error-code';
    if (scheduleDetail.type === 'clearDtc') type = 'read-erase';
    if (scheduleDetail.type === 'readFreezeFrame') type = 'read-snapshot';
    if (scheduleDetail.type === 'readParameters') type = 'read-parameter';
    downloadReportApi(type, scheduleDetail.scheduleId)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = `${this.state.scheduleDetail.name}.csv`;
            a.click();
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false, error: e });
      });
  }

  // downloadEcuFlashReport = () => {
  //   downloadEcuFlashReportApi(this.state.scheduleDetail.scheduleId)
  //     .then(res => {
  //       if (res.status !== 200) {
  //         res.json().then(res => {
  //           this.setState({ error: res, isLoading: false });
  //         });
  //       } else {
  //         this.saveFile(res);
  //       }
  //     })
  //     .catch(e => {
  //       console.log(e);
  //       this.setState({ isLoading: false, error: e });
  //     });
  // }

  // downloadReadDtcReport = () => {
  //   downloadReadDtcReportApi(this.state.scheduleDetail.scheduleId)
  //     .then(res => {
  //       if (res.status !== 200) {
  //         res.json().then(res => {
  //           this.setState({ error: res, isLoading: false });
  //         });
  //       } else {
  //         this.saveFile(res);
  //       }
  //     })
  //     .catch(e => {
  //       console.log(e);
  //       this.setState({ isLoading: false, error: e });
  //     });
  // }

  // downloadReadParametersReport = () => {
  //   downloadReadDtcReportApi(this.state.scheduleDetail.scheduleId)
  //     .then(res => {
  //       if (res.status !== 200) {
  //         res.json().then(res => {
  //           this.setState({ error: res, isLoading: false });
  //         });
  //       } else {
  //         this.saveFile(res);
  //       }
  //     })
  //     .catch(e => {
  //       console.log(e);
  //       this.setState({ isLoading: false, error: e });
  //     });
  // }

  // saveFile = (res) => {
  //   res.blob().then(blob => {
  //     let url = window.URL.createObjectURL(blob);
  //     let a = document.createElement('a');
  //     a.href = url;
  //     a.download = `${this.state.scheduleDetail.name}.csv`;
  //     a.click();
  //   });
  // }

  navigate = () => {
    this.props.history.push("/schedules");
  };

  // handleSearch = searchedValue => {
  //   this.setState({ scheduleVehicles: searchedValue });
  // };

  handleSearch = async (key, value) => {
    localStorage.setItem("scheduleDetailSearchKey", key);
    localStorage.setItem("scheduleDetailSearchValue", value);
    this.setState({ searchKey: key, searchValue: value });
    if (value.length && value.length >= 5) {
      this.setState({ vehicleLoading: true });
      const apiPromice = await scheduleVehicleSearchApi(this.state.scheduleDetail.scheduleId, value, 0);
      const res = await apiPromice.json();
      if (res.error && res.status !== 200) {
        this.setState({ error: res });
      } else {
        this.setVehicleIndexNumber(res, 0);
      }
    } else if (!value.length) {
      this.loadVehicles(0);
    }
  };

  confirmDelete = data => {
    if (!this.state.showDeleteModal) {
      this.setState({ showDeleteModal: true, deleteData: data });
    } else if (this.state.showDeleteModal) {
      this.setState({ showDeleteModal: false });
    }
  };

  errorHandled = () => {
    this.setState({ error: null });
  };

  loadVehicleNextPage = (page, currentPage) => {
    this.vehicleCurrentPage = currentPage;
    if (page === 0) {
      this.getScheduleDetail();
    } else {
      this.loadVehicles(page);
    }
  }

  loadLogsNextPage = (page, currentPage) => {
    this.logsCurrentPage = currentPage;
    this.getScheduleLogs(page);
  };

  toggleActivityModal = () => {
    this.setState(oldState => ({
      activityModal: !oldState.activityModal
    }));
  };

  onTimerComplete = (isManual) => {
    const { searchKey, searchValue } = this.state;
    this.setState(prevState => ({
      refreshing: "refresh-icon",
      vehicleLoading: true,
      timerKey: prevState.timerKey + 1,
      isManual: isManual
    }), () => {
      if (searchValue && searchValue.length >= 5) this.handleSearch(searchKey, searchValue);
      else this.getScheduleDetail();
    });
    if (this.state.activityModal) {
      // this.getActivityForCurrentSchedule(this.state.diagonisedVehicle);
      if (this.state.activeActivityKey === 'thisSchedule') this.getActivityForCurrentSchedule(this.state.diagonisedVehicle)
      else if (this.state.activeActivityKey === 'previousSchedule') this.getActivityForPrevSchedule(this.state.diagonisedVehicle)
    }
    return [true, 1500];
  };

  renderTime = value => {
    return <small>{value}</small>;
  }

  closeCancelStatusModal = () => {
    this.setState({
      showCancelStatusModal: false
    }, () => this.onTimerComplete(true));
  }

  handleActivityKeyChange = (key) => {
    if (key === 'thisSchedule') this.getActivityForCurrentSchedule(this.state.diagonisedVehicle)
    else if (key === 'previousSchedule') this.getActivityForPrevSchedule(this.state.diagonisedVehicle)
    this.setState({
      activeActivityKey: key,
      activityLoading: true,
      activityData: []
    });
  };

  render() {
    const {
      scheduleDetail,
      error,
      allVehicles,
      vehicleLoading,
      diagonisedVehicle,
      showDeleteModal,
      showCancelStatusModal,
      columns,
      timerKey
    } = this.state;
    return (
      <>
        {/* Toast Alert */}
        {Boolean(error) ? (
          <ToastAlert
            show={Boolean(error)}
            onClose={this.errorHandled}
            error={error}
          />
        ) : null}
        {/* Page Content starts */}
        <Container fluid className="pt-3">
          <Row>
            <Col md="1">
              <div onClick={this.navigate} className="back">
                <i className="fa fa-chevron-left">&nbsp;</i>Back
              </div>
            </Col>
            <Col md={8} className="p-0">
              <h5 className="longString">
                Schedule: &nbsp;
                <span>{scheduleDetail.name}</span>
                {/* <small className="ml-2">
                  ({allVehicles.length}{" "}
                  {allVehicles.length === 1 ? "Vehicle" : "Vehicles"})
                </small> */}
              </h5>
            </Col>
            <Col md="3" className="text-right">
              <div className="timer-wrapper" style={{ display: 'inline-block' }}>
              {scheduleDetail && scheduleDetail.status === 'IN_PROGRESS' ?
                <CountdownCircleTimer
                  isPlaying={(showDeleteModal || showCancelStatusModal || vehicleLoading) ? false : true}
                  size={30}
                  key={timerKey}
                  strokeWidth={5}
                  durationSeconds={30}
                  onComplete={() => this.onTimerComplete(false)}
                  renderTime={this.renderTime}
                  colors={[
                    ['#004777', .33],
                    ['#F7B801', .33],
                    ['#A30000']
                  ]}
                />
              : null}
              </div>
              <div className="text-right pl-2" style={{ display: 'inline-block' }}>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip">Refresh</Tooltip>}
                >
                  <Button
                    variant="outline-success"
                    className={scheduleDetail.type !== 'updateParameters' ? null : 'width100'}
                    onClick={() => this.onTimerComplete(true)}
                  >
                    <i className={"manual-ref fa fa-refresh " + this.state.refreshing}></i>
                  </Button>
                </OverlayTrigger>
                {scheduleDetail.type !== 'updateParameters' ?
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Download Report</Tooltip>}
                  >
                    <Button className="ml-2" variant="outline-success" onClick={this.downloadReport}>
                      <i className='fa fa-download'></i>
                    </Button>
                  </OverlayTrigger>
                : null}
              </div>
            </Col>
          </Row>
          <Row></Row>
          <Row noGutters className="mt-2 detail-card">
            <Col md={5} className="content-box pr-2">
              <ul className="schedule-vehicle-count">
                <li>
                  <div className="count-value">{scheduleDetail.totalDevice || 0}</div>
                  <div className="text" style={{fontSize: "13px"}}>Vehicles</div>
                </li>
                <li>
                  <div className="count-value text-primary">{scheduleDetail.scheduled || 0}</div>
                  <div className="text" style={{fontSize: "13px"}}>Scheduled</div>
                </li>
                <li>
                  <div className="count-value text-warning">{scheduleDetail.inProgress || 0}</div>
                  <div className="text" style={{fontSize: "13px"}}>In Progress</div>
                </li>
                <li>
                  <div className="count-value text-success">{scheduleDetail.completed || 0}</div>
                  <div className="text" style={{fontSize: "13px"}}>Success</div>
                </li>
                <li>
                  <div className="count-value text-danger">{scheduleDetail.error || 0}</div>
                  <div className="text" style={{fontSize: "13px"}}>Failed</div>
                </li>
                <li>
                  <div className="count-value text-secondary">{scheduleDetail.cancelled || 0}</div>
                  <div className="text" style={{fontSize: "13px"}}>Cancelled</div>
                </li>
              </ul>
              <ServerSearch
                scheduleDetail
                search={this.handleSearch}
              />
            </Col>
            <Col md={3} className="content-box pr-3">
              <ListGroup>
                <ListGroup.Item>
                  <span className="sub-heading">Schedule Type: </span>
                  {scheduleType[scheduleDetail.type]}
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-heading">Status: </span>
                  {scheduleStatus[scheduleDetail.status]}
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-heading">Created On: </span>
                  <DateConverter date={scheduleDetail.createdTimeStamp} />
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-heading">Created By: </span>
                  {scheduleDetail.initiatedBy}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4} className="content-box">
              <ListGroup>
                <ListGroup.Item>
                  <span className="sub-heading">Approved/Rejected By: </span>
                  {scheduleDetail.updaterName}
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-heading">File Type: </span>
                  {scheduleDetail.fileType}
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-heading">File Name: </span>
                  {scheduleDetail.userSelectedFileName}
                </ListGroup.Item>
                <ListGroup.Item>
                  <span className="sub-heading">ECU Group / ECU Name: </span>
                  {scheduleDetail.ecuGroup} / {scheduleDetail.ecuName}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          {/* <Row>
            <Col md={5} className="search-vin">
              <Search data={allVehicles} search={this.handleSearch} />
            </Col>
            <Col md={7} className="search-vin scheduleDetailProgress pr-0" style={{ display: 'flex' }}>
              <StatusBar schedule={scheduleDetail} />
              <div className="text-right pl-2" style={{ width: '16%', display: 'inline-block' }}>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip">Refresh</Tooltip>}
                >
                  <Button
                    variant="primary"
                    className={scheduleDetail.type !== 'updateParameters' ? null : 'width100'}
                    onClick={() => this.onTimerComplete(true)}
                  >
                    <i className={"manual-ref fa fa-refresh " + this.state.refreshing}></i>
                  </Button>
                </OverlayTrigger>
                {scheduleDetail.type !== 'updateParameters' ?
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Download Report</Tooltip>}
                  >
                    <Button className="ml-2" variant="primary" onClick={this.downloadReport}>
                      <i className='fa fa-download'></i>
                    </Button>
                  </OverlayTrigger>
                : null}
              </div>
            </Col>
          </Row> */}
          <Row></Row>
          {/* Vehicle Table Row */}
          <Row noGutters className="mt-2">
            <Col className="data-table">
              {/* {scheduleDetail.name ? ( */}
              <CustomDataTable
                tableColumns={columns}
                data={this.state.scheduleVehicles}
                loading={this.state.vehicleLoading}
                loadNextPage={this.loadVehicleNextPage}
                currentPage={this.vehicleCurrentPage}
              />
              {/* ) : null} */}
            </Col>
          </Row>
          {/* Logs Table Row */}
          <Row noGutters>
            <Col className="data-table orange-table">
              {scheduleDetail.name && this.state.showLogsTable ? (
                <CustomDataTable
                  tableColumns={this.logsColumns}
                  data={this.state.logs}
                  loading={this.state.logsLoading}
                  loadNextPage={this.loadLogsNextPage}
                  currentPage={this.logsCurrentPage}
                />
              ) : null}
            </Col>
          </Row>
        </Container>
        <ConfirmDeleteModal
          show={this.state.showDeleteModal}
          disable={this.state.disableConfirmButton}
          onHide={this.confirmDelete}
          update={this.updateStatus}
          action={this.state.deleteData.scope}
        />
        <CancelStatusModal
          show={this.state.showCancelStatusModal}
          onHide={this.closeCancelStatusModal}
          vehicle={this.state.deleteData}
        />
        <ActivityModal
          show={this.state.activityModal}
          onHide={this.toggleActivityModal}
          vehicle={diagonisedVehicle}
          data={this.state.activityData}
          isLoading={this.state.activityLoading}
          activeKey={this.state.activeActivityKey}
          changeActiveKey={this.handleActivityKeyChange}
        />
      </>
    );
  }
}

export default ScheduleDetail;

import React, { Component } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import InlineAlert from '../../../../shared/notificationAlert/inlineAlert';
import { validateFileApi, validateVinsApi } from '../../../../utill/api/schedulesApi';
import Step1 from './step1';
import Step2 from './step2';
import WarningModal from './warningModal';

class BulkMasterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleType: props.type,
      error: null,
      currentStep: 1,
      validated: false,
      disable: false,
      ecuGroup: null,
      ecuName: null,
      ecuGroupId: null,
      supplier: null,
      supplierId: null,
      selectedIAlertVpartId: null,
      softwareType: null,
      bFileDetails: { data: [], selectedName: '', selectedId: '' },
      cFileDetails: { data: [], selectedName: '', selectedId: '' },
      parameters: [],
      updateParameter: {},
      isParameterSelected: null,
      parameterValue: null,
      description: '',
      scheduleExpiryDays: '',
      // for csv valid vins
      vinCounts: {},
      validateVinData: [],
      validVins: [],
      invalidVins: [],
      showWarningModal: false,
      warningType: '',
      occupiedVinData: [],
      occupiedVins: [],
      payload: {},
      isDataStored: false
    }

    this.handleChange = this.handleChange.bind(this)
    this._next = this._next.bind(this)
    this._prev = this._prev.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { alreadyScheduledVinData, alreadyScheduledVins } = nextProps;
    if (alreadyScheduledVinData.length && alreadyScheduledVins.length) {
      this.setState({
        occupiedVinData: alreadyScheduledVinData,
        occupiedVins: alreadyScheduledVins,
        showWarningModal: true,
        warningType: 'already_scheduled',
        disable: false
      });
    }
  }

  // Use the submitted data to set the state
  handleChange(key, value) {
    this.setState({ [key]: value })
  }

  toggleWarningModal = (warningType) => {
    this.setState(oldState => ({
      showWarningModal: !oldState.showWarningModal,
      warningType
    }));
  }

  // _next and _previous functions will be called on button click
  _next() {
    let currentStep = this.state.currentStep
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 2 ? 3 : currentStep + 1
    this.setState({ currentStep, isDataStored: true })
  }

  _prev() {
    let currentStep = this.state.currentStep
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    this.setState({ currentStep, isDataStored: false })
  }

  // The "next" and "previous" button functions
  get previousButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <Button
          className="btn btn-secondary"
          disabled={this.state.disable}
          onClick={this._prev}>
          Back
        </Button>
      )
    }
    // ...else return nothing
    return null;
  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep < 2) {
      return (
        <Button
          className="btn btn-primary float-right"
          disabled={this.state.disable}
          type="submit">
          {this.state.disable ? (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              role="status"
            />
          ) : null}
          {currentStep === 2 ? 'Create' : null}
          {currentStep === 1 ? 'Next' : null}
        </Button>
      )
    }
    // If its the last step
    if (currentStep == 2) {
      return (
        <Button
          className="btn btn-primary pull-right"
          disabled={this.state.disable || !this.state.validVins.length}
          onClick={this.checkForWarnings}>
          Create
        </Button>
      )
    }
    // ...else render nothing
    return null;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const {
      currentStep,
      scheduleType,
      ecuGroup,
      supplier,
      isParameterSelected,
      softwareType,
      bFileDetails,
      cFileDetails
    } = this.state;
    let additionalFormValid = true;
    // checking for parameters selected in case of read and update param
    if (scheduleType === 'readParameters' || scheduleType === 'updateParameters') {
      if (isParameterSelected === null || !isParameterSelected) {
        additionalFormValid = false;
        this.setState({ isParameterSelected: false });
      } else additionalFormValid = true;
    }
    if (form.checkValidity() && additionalFormValid) {
      const {
        description,
        scheduleExpiryDays,
        file
      } = event.target;
      this.setState({
        description: description.value,
        scheduleExpiryDays: scheduleExpiryDays.value,
        disable: true
      }, () => this.setSelectedData());
      if (currentStep === 1) {
        const formData = new FormData();
        formData.append("file", file.files[0]);
        this.validateFile(formData, ecuGroup, supplier,softwareType,bFileDetails,cFileDetails,scheduleType);
      }
    }
    this.setState({ validated: true });
  };

  setSelectedData = () => {
    const {
      scheduleType,
      description,
      scheduleExpiryDays,
      ecuGroup,
      supplier,
      softwareType,
      bFileDetails,
      cFileDetails,
      parameters,
      updateParameter,
      parameterValue
    } = this.state;
    let payload = {
      ecuGroup: ecuGroup,
      supplier: supplier,
      description: description,
      scheduleExpiryDays: scheduleExpiryDays
    };
    if (scheduleType === 'flashEcu') {
      payload['softwareType'] = softwareType;
      payload['bFileName'] = bFileDetails.selectedName;
      payload['cFileName'] = cFileDetails.selectedName;
    }
    else if (scheduleType === 'readParameters') {
      const params = parameters.map(item => item.label);
      payload['parameters'] = params;
    }
    else if (scheduleType === 'updateParameters') {
      payload['parameterName'] = updateParameter.label;
      payload['parameterValue'] = parameterValue;
    }
    this.setState({ payload });
  }

  validateFile = (payload, ecuGroup, supplier,softwareType,bFileDetails,cFileDetails,scheduleType) => {
  
    var fileNames='';
    if(softwareType =="B&C"){
      fileNames += bFileDetails.selectedName+',' + cFileDetails.selectedName;
    }else  if(softwareType =="B+C"){
      fileNames += bFileDetails.selectedName;
    } if(softwareType =="B"){
      fileNames += bFileDetails.selectedName
    } if(softwareType =="C"){
      fileNames += cFileDetails.selectedName;
    }
  
    
    validateFileApi(payload, ecuGroup, supplier,softwareType,fileNames,scheduleType)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            if (this.state.currentStep === 1) {
              this.setState({
                vinCounts: res,
                validateVinData: res.resultList,
                disable: false,
                error: null
              });
              this.checkValidVins();
              this._next();
            }
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({
          error: e,
          disable: false
        });
      });
  }

  checkValidVins = () => {
    const { validateVinData } = this.state;
    let validVins = [];
    let invalidVins = [];
    validateVinData.map(item => {
      if (item.status === 'OK') validVins.push(item.vin);
      else if (item.status === 'NOK') invalidVins.push(item.vin);
    });
    this.setState({ validVins, invalidVins });
  };

  checkForWarnings = () => {
    const { validVins, invalidVins } = this.state;
    if (invalidVins.length) this.toggleWarningModal('validation_fail');
    else this.createSchedule()
  };

  onConfirmProceed = () => {
    if (this.state.warningType === 'already_scheduled') {
      this.setState({ disable: true }, () => this.getNewValidVins());
    } else {
      this.setState({ disable: true }, () => this.createSchedule());
    }
  };

  getNewValidVins = () => {
    const { validVins, occupiedVins } = this.state;
    const newValidVins = validVins.filter(item => {
      if (!occupiedVins.includes(item)) return item;
    })
    this.setState({
      validVins: newValidVins
    }, () => this.createSchedule());
  }

  createSchedule = () => {
    const { scheduleType } = this.state;
    let payload = {};
    // this.setState({ disable: false });
    if (scheduleType === 'flashEcu') payload = this.getFlashPayload();
    else if (['readDtc', 'clearDtc'].includes(scheduleType)) payload = this.getDtcPayload();
    else if (['readParameters', 'readFreezeFrame'].includes(scheduleType)) payload = this.getReadParamPayload();
    else if (scheduleType === 'updateParameters') payload = this.getUpdateParamPayload();
    this.props.createSchedule(payload);
  };

  getFlashPayload = () => {
    const { softwareType, bFileDetails, cFileDetails} = this.state;
    const files = [];
    // send file name
    if (softwareType === 'B&C') {
      const b = {
        fileName: bFileDetails.selectedName,
        fileType:"B"
      }
      const c = {
        fileName: cFileDetails.selectedName,
        fileType:"C"
      }
      files.push(b, c);
    } else if (softwareType === 'B' ) {
      const b = {
        fileName: bFileDetails.selectedName,
        fileType:"B"
      }
      files.push(b);
    } else if (softwareType === 'B+C') {
      const b = {
        fileName: bFileDetails.selectedName,
        fileType:"B+C"
      }
      files.push(b);
    } else if (softwareType === 'C') {
      const c = {
        fileName: cFileDetails.selectedName,
        fileType:"C"
      }
      files.push(c);
    }

     // structure the payload
     return {
      description: this.state.description,
      scheduleExpiryDays: this.state.scheduleExpiryDays,
      type: this.state.scheduleType,
      vehicles: this.state.validVins,
      vPartNumber: this.state.vPartNumber,
      ecuGroup: this.state.ecuGroup,
      supplier: this.state.supplier,
      files: files
    };
  }

  getDtcPayload = () => {
    return {
      description: this.state.description,
      scheduleExpiryDays: this.state.scheduleExpiryDays,
      type: this.state.scheduleType,
      vehicles: this.state.validVins,
      ecuGroup: this.state.ecuGroup,
      supplier: this.state.supplier,
      iAlertVPartNumberId: this.state.selectedIAlertVpartId
    }
  };

  getReadParamPayload = () => {
    let params = [];
    this.state.parameters.map((item, index) => {
      let parametersObj = {}
      parametersObj['lidHex'] = item.lid;
      parametersObj['referenceId'] = item.referenceId;
      if (this.state.scheduleType === 'readParameters') parametersObj['description'] = item.label;
      params.push(parametersObj);
    });
    return {
      description: this.state.description,
      scheduleExpiryDays: this.state.scheduleExpiryDays,
      vehicles: this.state.validVins,
      parameters: params,
      ecuGroup: this.state.ecuGroup,
      supplier: this.state.supplier,
      iAlertVPartNumberId: this.state.selectedIAlertVpartId
    }
  };

  getUpdateParamPayload = () => {
    return {
      description: this.state.description,
      scheduleExpiryDays: this.state.scheduleExpiryDays,
      vehicles: this.state.validVins,
      parameterName: this.state.updateParameter.label,
      parameterValue: this.state.parameterValue,
      referenceId: this.state.updateParameter.referenceId,
      lidHex: [this.state.updateParameter.lid],
      ecuGroup: this.state.ecuGroup,
      supplierId: this.state.supplier,
      iAlertVPartNumberId: this.state.selectedIAlertVpartId
    }
  };

  getSelectedValues = () => {}

  render() {
    return (
      <>
        {/* Error Display */}
        {Boolean(this.state.error) ? (
          <InlineAlert error={this.state.error} />
        ) : null}
        <Form
          noValidate
          encType="multipart/form-data"
          validated={this.state.validated}
          onSubmit={this.handleSubmit}
          >
          <Step1
            scheduleType={this.state.scheduleType}
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            isParameterSelected={this.state.isParameterSelected}
            isDataStored={this.state.isDataStored}
            validated={this.state.validated}
          />
          <Step2
            scheduleType={this.state.scheduleType}
            currentStep={this.state.currentStep}
            vinCounts={this.state.vinCounts}
            vinDetails={this.state.validateVinData}
            validVins={this.state.validVins}
            invalidVins={this.state.invalidVins}
            selectedData={this.state.payload}
          />
          {this.previousButton}
          {this.nextButton}
          <Button
            className="btn btn-secondary pull-right mr-2"
            disabled={this.state.disable}
            onClick={this.props.onHide}>
            Cancel
          </Button>
        </Form>
        <WarningModal
          show={this.state.showWarningModal}
          onHide={this.toggleWarningModal}
          warningType={this.state.warningType}
          invalidVins={this.state.invalidVins}
          vinsTakenData={this.state.occupiedVinData}
          disable={this.state.disable}
          onConfirm={this.onConfirmProceed}
        />
      </>
    );
  }
}

export default BulkMasterForm;
import React, { Component } from "react";
import { freezeFrameDiagnosticsApi, freezeFrameDiagParamApi } from "../../utill/api/schedulesApi";
import CustomDataTable from "../../shared/customDataTable/customDataTable";
import { Container, Row, Col } from "react-bootstrap";
// import ViewSideMenu from "../DiagnosticsView/ViewSideMenu";
import ToastAlert from "../../shared/notificationAlert/toastAlert";

class ReadFreezeFrameView extends Component {
  freezeColumns = [
    {
      name: "#",
      selector: "index",
      sortable: false,
      minWidth: "60px",
      maxWidth: "50px",
      left: true
    },
    {
      name: "Fault Code",
      selector: "faultCode",
      sortable: false,
      maxWidth: "120px"
    },
    {
      name: "SPN",
      selector: "spn",
      sortable: false,
      maxWidth: "100px"
    },
    {
      name: "FMI",
      selector: "fmi",
      sortable: false,
      maxWidth: "100px"
    },
    {
      name: "Description",
      selector: "description",
      sortable: false,
      minWidth: "200px",
      cell: row => row.description
    }
  ];

  conditionalRowStyles = [
    {
      when: row => row.index,
      style: {
        backgroundColor: 'rgba(63, 195, 128, 0.9)',
        color: 'white',
        // '&:hover': {
        //   cursor: 'pointer',
        // },
      },
    }
  ]

  snapshotColumns = [
    {
      name: "#",
      selector: "index",
      sortable: false,
      minWidth: "60px",
      maxWidth: "50px",
      left: true
    },
    {
      name: "Data Identifier",
      selector: "dataIdentifier",
      sortable: false,
      maxWidth: "120px"
    },
    {
      name: "Parameter",
      selector: "parameterName",
      sortable: false,
      minWidth: "400px",
      cell: row => <span>{row.parameterName}</span>
    },
    {
      name: "Value",
      sortable: false,
      minWidth: "100px",
      cell: row => <div>{row.value} {row.unit}</div>
    }
  ];

  currentPage = 0;
  ffCurrentPage = 0;

  constructor(props) {
    super(props);
    this.state = {
      vehicleId: props.match.params.vehicleId,
      scheduleId: props.match.params.scheduleId,
      schedule: JSON.parse(localStorage.getItem("selectedSchedule")),
      vehicle: JSON.parse(localStorage.getItem("selectedVehicle")),
      isLoading: true,
      // rawData: {},
      data: [],
      vin: 'VIN',
      ecuName: '',
      freezeFrameData: [],
      freezeLoading: true,
      parentId: '',
      error: null
    };
  }

  componentWillUnmount() {
    // localStorage.removeItem("selectedSchedule");
    // localStorage.removeItem("selectedVehicle");
  }

  getDiagnostics = page => {
    freezeFrameDiagnosticsApi(this.state.vehicleId, this.state.vehicle.ecuName, page)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            if (res.length) {
              // this.setState({ vin: res[0].vin, ecuName: res[0].ecuName });
              this.setIndexNumber(res, page);
            } else {
              this.setState({ isLoading: false, freezeLoading: false });
            }
          });
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              isLoading: false
            });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          isLoading: false
        });
      });
  };

  getSnapshotData = (parentId, page) => {
    this.setState({ parentId, freezeLoading: true });
    freezeFrameDiagParamApi(parentId, page)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            if (res.length) {
              this.setFreezeIndexNumber(res, page);
            } else {
              if (page == 0) this.setState({ freezeFrameData: [] })
              this.setState({ freezeLoading: false });
            }
          });
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              freezeLoading: false
            });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          freezeLoading: false
        });
      });
  };

  setIndexNumber(data, page) {
    if (page == 0) {
      this.setState({ data: [] });
    }
    // eslint-disable-next-line array-callback-return
    data.map((row, index) => {
      row.index = this.state.data.length + index + 1;
    });
    if (page == 0) {
      this.setState({
        data: data,
        isLoading: false
      });
      // this.rowClicked(data[0]);
      this.getSnapshotData(data[0].id, 0);
    } else {
      this.setState(prevState => {
        return {
          data: [...prevState.data, ...data],
          isLoading: false
        };
      });
    }
  }

  setFreezeIndexNumber(data, page) {
    if (page == 0) {
      this.setState({ freezeFrameData: [] });
    }
    // eslint-disable-next-line array-callback-return
    data.map((row, index) => {
      row.index = this.state.freezeFrameData.length + index + 1;
      const first = row.dataIdentifier.slice(0,2);
      const second = row.dataIdentifier.slice(2,4);
      row.dataIdentifier = `0x ${first} ${second}`
    });
    if (page == 0) {
      this.setState({
        freezeFrameData: data,
        freezeLoading: false
      });
    } else {
      this.setState(prevState => {
        return {
          freezeFrameData: [...prevState.freezeFrameData, ...data],
          freezeLoading: false
        };
      });
    }
  }

  loadNextPage = (page, currentPage) => {
    this.currentPage = currentPage;
    this.getDiagnostics(page);
  };

  loadFreezeNextPage = (page, currentPage) => {
    this.ffCurrentPage = currentPage;
    if (this.currentPage !== 0) {
      this.getSnapshotData(this.state.parentId, page);
    }
  }

  errorHandled = () => {
    this.setState({ error: null });
  };

  goBack = () => {
    this.props.history.push(`/schedules/${this.state.scheduleId}/readFreezeFrame`)
  };

  rowClicked = (row) => {
    this.getSnapshotData(row.id, 0);
  };

  render() {
    const { error } = this.state;
    return (
      <>
        {/* Toast Alert */}
        {Boolean(error) ? (
          <ToastAlert
            show={Boolean(error)}
            onClose={this.errorHandled}
            error={error}
          />
        ) : null}
        <Container fluid className="px-0">
          <Row noGutters className="action-row-top align-items-center">
            <Col md="4">
              <div className="back" onClick={this.goBack}>
                <i className="fa fa-chevron-left"></i>&nbsp;
                Back to Schedule for {this.state.vehicle.vin}
              </div>
            </Col>
            <Col md="8" className="px-0">
              <h6 className="longString mt-1">
                Schedule: &nbsp;
                <span>{this.state.schedule.name}</span>
              </h6>
            </Col>
          </Row>
          <Row md="12" className="pt-2">
            {/* <Col md="2">
              <ViewSideMenu ecuName={this.state.ecuName} />
            </Col> */}
            <Col md="6">
              <Row noGutters>
                <Col md="12" className="page-title">
                  <h1>Read Error Codes</h1>
                </Col>
              </Row>
              <Row noGutters>
                <Col md="12">
                  <CustomDataTable
                    tableColumns={this.freezeColumns}
                    data={this.state.data}
                    loading={this.state.isLoading}
                    loadNextPage={this.loadNextPage}
                    currentPage={this.currentPage}
                    rowClicked={this.rowClicked}
                    activeRow={this.conditionalRowStyles}
                    pointerOnHover
                    rowSelection
                  />
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Row noGutters>
                <Col md="12" className="page-title">
                  <h1>Freeze Frame View</h1>
                </Col>
              </Row>
              <Row noGutters>
                <Col md="12">
                  <CustomDataTable
                    tableColumns={this.snapshotColumns}
                    data={this.state.freezeFrameData}
                    loading={this.state.freezeLoading}
                    loadNextPage={this.loadFreezeNextPage}
                    currentPage={this.ffCurrentPage}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ReadFreezeFrameView;

import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

function ConfirmDeleteModal(props) {
  const action = props.action;
  let title = "Critical Operation"
  let message = "Are You Sure you want to perform this action?";

  if (action === "flashEcu" || action === "updateParameters" || action === "retryTrigger") {
    title = "Trigger Flash";
    message = "Are you sure you want to trigger Flash?";
  } else if (action === "deleteFile") {
    title = "Cancel Flash";
    message = "Are you sure you want to cancel Flash?";
  } else if (action === "APPROVED") {
    title = "Approve Schedule";
    message = "Are you sure you want to Approve this schedule?";
  } else if (action === "REJECTED") {
    title = "Reject Schedule";
    message = "Are you sure you want to Reject this schedule?";
  } else if (action === "WITHDRAW") {
    title = "Withdraw Schedule";
    message = "Are you sure you want to Withdraw this schedule?";
  } else if (action === "delete") {
    title = "Delete File";
    message = "Are you sure you want to delete this File?";
  } else if (action === "forceCancel") {
    title = "Cancel";
    message = "Are you sure you want to Cancel?";
  } else if (action === "DELETE_SCHEDULE") {
    title = "Delete Schedule";
    message = "Are you sure you want to delete this Schedule?";
  } else if (action === "confirmSync") {
    title = "Sync PD Vehicle";
    message = "Are you sure you want to sync this Vehicle?";
  } else if (action === "confirmIAlertSync") {
    title = "Sync iAlert Vehicle";
    message = "Are you sure you want to sync this Vehicle?";
  }  else if (action === "userDelete") {
    title = "Delete User";
    message = "Are you sure you want to delete this User?";
  } else if (action === "vehicleDelete") {
    title = "Delete Vehicle";
    message = "Are you sure you want to delete this Vehicle?";
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop={'static'}
        keyboard={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message} <br />
          This Action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={props.onHide} disabled={props.disable}>
            <i className="fa fa-times" aria-hidden="true">&nbsp;</i>
            No
          </Button>
          <Button variant="danger" onClick={props.update} disabled={props.disable}>
            {props.disable ?
              <Spinner animation="border" variant="light" size="sm" role="status" />
              : <i className="fa fa-check" aria-hidden="true">&nbsp;</i>
            }
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmDeleteModal;

import React, { Component } from "react";
import { Modal, Button, Form, Col, Spinner } from "react-bootstrap";
import { tspApi } from "../../utill/api/tspApi";
import { createVehicleApi } from "../../utill/api/vehicleApi";
import InlineAlert from "../../shared/notificationAlert/inlineAlert";

class VehicleAddModal extends Component {
  state = {};
  currentUser = JSON.parse(localStorage.getItem("currentUser"));

  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      disable: false,
      serialNumber: "",
      vin: "",
      configurationId: "",
      tspData: [],
      error: null
    };
    this.getTsp = this.getTsp.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.getTsp();
  }
  /**
   *
   */
  getTsp() {
    tspApi()
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            this.setState({ tspData: res });
          })
        }
      })
      .catch(e => {
        console.log(e);
      });
  }
  /**
   *
   * @param {*} event
   */
  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      this.setState({ disable: true });
      const { serialNumber, vin, vPartNumber, configurationId } = event.target;
      const payload = {
        serialNumber: serialNumber.value,
        vin: vin.value,
        vPartNumber: this.currentUser.userGroupId == 2 ? vPartNumber.value: null,
        tspId: configurationId.value
      };
      /**
       *
       */
      createVehicleApi(payload)
        .then(res => {
          if (res.status !== 200) {
            res.json().then(data => {
              this.setState({ error: data, disable: false });
            });
          } else {
            res.successCode = "addVehicle";
            this.props.getVehicles(0);
            this.props.onHide();
            this.props.showModalSuccess(res);
            this.setState({ disable: false });
          }
        })
        .catch(e => {
          this.setState({ error: e, disable: false })
          console.log(e);
        });
    }
    this.setState({ validated: true });
  }
  /**
   *
   */
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            New Vehicle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Boolean(this.state.error) ? (
            <InlineAlert error={this.state.error} />
          ) : null}

          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleSubmit}
          >
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Serial Number</Form.Label>
                <Form.Control
                  name="serialNumber"
                  required
                  type="text"
                  placeholder="Serial Number"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter serial number.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>VIN</Form.Label>
                <Form.Control
                  name="vin"
                  required
                  type="text"
                  placeholder="VIN"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter vin.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            
            {(this.currentUser.userGroupId == 2) ? (
              <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>vPart Number</Form.Label>
                <Form.Control
                  name="vPartNumber"
                  required
                  type="text"
                  placeholder="vPart Number"
                />
               <Form.Control.Feedback type="invalid">
                  Please enter vPartNumber.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          ) : null}


            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                <Form.Label>TSP</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="configurationId"
                // onChange={this.getEcuName}
                >
                  <option value="" disabled defaultValue>
                    Select Tsp
                  </option>
                  {this.state.tspData.map((data, i) => {
                    return (
                      <option
                        key={`tspId_${i}`}
                        defaultValue={i === 0}
                        value={data.tspId}
                      >
                        {data.tspName}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please choose TSP
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Button type="submit" variant="success" disabled={this.state.disable}>
              {this.state.disable ?
                <Spinner animation="border" variant="light" size="sm" role="status" />
                : null}
              Add Vehicle
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default VehicleAddModal;

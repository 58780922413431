import React, { Component } from 'react';
import { Modal, Form, Col, Button, Spinner } from 'react-bootstrap';
import InlineAlert from '../../shared/notificationAlert/inlineAlert';
import { resetPasswordApi } from '../../utill/api/userApi';

class ResetPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      showResetModal: false,
      disableResetButton: false,
      isConfirmed: false
    }
  }

  toggleConfirmCheck = () => {
    this.setState(oldState => ({
      isConfirmed: !oldState.isConfirmed
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const {
      newPassword
    } = event.target;
    if (form.checkValidity()) {
      this.setState({ disableResetButton: true });
      const payload = {
        newPassword: newPassword.value
      };
      resetPasswordApi(this.props.row.userId, payload)
      .then(res => {
        if (res.status === 200) {
          res.successCode = "resetPasswordSuccess";
          this.props.onHide();
          this.props.showModalSuccess(res);
          this.setState({ disableResetButton: false, validated: false });
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disableResetButton: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disableResetButton: false });
        console.log(e);
      });
    }
    this.setState({ validated: true });
  }
  
  render() {
    const { row } = this.props;
    const {
      validated,
      disableResetButton,
      isConfirmed
    } = this.state;
    return (
      <>
        {/* Reset Password Modal */}
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          backdrop={'static'}
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          >
          <Modal.Header closeButton>
            <Modal.Title>Reset {row ? row.firstName : "User"}'s Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Boolean(this.state.error) ? (
              <InlineAlert error={this.state.error} />
            ) : null}
            <Form
              noValidate
              encType="multipart/form-data"
              validated={validated}
              onSubmit={this.handleSubmit}
            >
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="newPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    required
                    name="newPassword"
                    type="password"
                    autoComplete="off"
                    placeholder="Enter New Password"
                    minLength={6}
                    maxLength={45}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter New Password with atleast 6 characters.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <div className="col-lg-12">
               <p class="text-muted small"> Password will be reset now, use this password on next login.</p>
                 Are you sure to reset the password ?<br/>
              </div>
              <div className="form-check ml-3">
                <input
                  className="form-check-input"
                  type="checkbox" value=""
                  checked={isConfirmed}
                  id="confirm"
                  onChange={this.toggleConfirmCheck}
                />
                <label className="form-check-label" for="confirm">
                  Yes, I am sure.
                </label>
              </div>
              <div className="text-right mt-3">
                <Button type="submit" variant="danger" disabled={disableResetButton || !isConfirmed}>
                  {disableResetButton ?
                    <Spinner animation="border" variant="light" size="sm" role="status" />
                    : <i className="fa fa-key" aria-hidden="true">&nbsp;</i>
                  }
                  Reset
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal></>
    );
  }
}

export default ResetPasswordModal;
import React from "react";
import Moment from "react-moment";
import "moment-timezone";

const DateConverter = (props) => {
  // date format changed to yyyy-mm-dd
  let format = "YYYY-MM-DD HH:mm:ss";
  if (props.format) {
    format = props.format;
  }
  return (
    <span>
      {props.date != null ? <Moment format={format}>{props.date}</Moment> : ""}
    </span>
  );
};

export default DateConverter;

import apiHelper from "./apiHelper";

export const getOverallCountApi = (from, to) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/dashboard/schedules/overall?from=${from}&to=${to}&includeVehicles=true`,
    method: "GET",
  })
};

export const getCountByTypeApi = (from, to, type) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/dashboard/schedules/types/${type}?from=${from}&to=${to}&includeVehicles=true`,
    method: "GET",
  })
};

export const downloadReportApi = (from, to) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/reports/download?from=${from}&to=${to}`,
    method: "GET",
  })
};

export const getLiveDataApi = (from, to) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/dashboard/remote-diagnostics/live?from=${from}&to=${to}`,
    method: "GET",
  })
};

export const getSyncDataApi = () => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/dashboard/sync`,
    method: "GET",
  })
};

// export const getScheduleByType = (from, to, type) => {
//   let token = localStorage.getItem('token');
//   return apiHelper({
//     headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
//     url: `${process.env.REACT_APP_DOMAIN}/dashboard/schedules/types/${type}`,
//     method: "GET",
//   })
// };
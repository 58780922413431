import apiHelper from "./apiHelper";

export const vehiclesApi = (vin, vpart) => {
  let token = localStorage.getItem('token');
  let tempUrl = `${process.env.REACT_APP_DOMAIN}/vehicles/vin/${vin}`;
  if (vpart) {
    tempUrl += `?vPartNumber=${vpart}`;
  }
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: tempUrl,
    method: "GET",
  })
};

export const ecuGroupApi = () => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/ecu/group`,
    method: "GET",
  })
};

export const ecuNameApi = (data) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/ecu/name?ecuGroup=${data}`,
    method: "GET",
  })
};

export const filesApi = (ecuName, ecuGroup, type, searchVal) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/files/ecu?ecuGroup=${ecuGroup}&ecuName=${ecuName}&scheduleType=${type}&fileName=${searchVal}`,
    method: "GET",
  })
};

export const vPartNumberApi = (vPart) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/vehicles/vPartNumber/${vPart}`,
    method: "GET",
  })
};

export const ecuDetailsApi = (vPart) => {
  let token = localStorage.getItem('token');
  let tempUrl = `${process.env.REACT_APP_DOMAIN}/ialert/ecu`;
  if (vPart) tempUrl += `?vPartNumber=${vPart}`;
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: tempUrl,
    method: "GET",
  })
};

export const supplierDetailsApi = (ecuGroup, vPart) => {
  let token = localStorage.getItem('token');
  let tempUrl = `${process.env.REACT_APP_DOMAIN}/ialert/supplier?ecuGroup=${ecuGroup}`;
  if (vPart) tempUrl += `&vPartNumber=${vPart}`
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: tempUrl,
    method: "GET",
  })
};

export const getEcuNameApi = (ecuGroupId, supplierId, vPart) => {
  let token = localStorage.getItem('token');
  let tempUrl = `${process.env.REACT_APP_DOMAIN}/ialert/ecuName?ecuGroupId=${ecuGroupId}&supplierId=${supplierId}`;
  if (vPart) tempUrl += `&vPartNumber=${vPart}`;
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: tempUrl,
    method: "GET",
  })
};

export const softwareTypeDetailsApi = (ecuGroup, supplier, vPartNumber) => {
  let tempUrl = `${process.env.REACT_APP_DOMAIN}/ialert/softwareType?supplier=${supplier}&ecuGroup=${ecuGroup}`;
  if (vPartNumber) tempUrl += `&vPartNumber=${vPartNumber}`;
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: tempUrl,
    method: "GET",
  })
};

export const fileDetailsApi = (ecuGroup, supplier, softwareType, vPartNumber) => {
  if (softwareType == 'B+C') softwareType = 'B%2BC';
  let tempUrl = `${process.env.REACT_APP_DOMAIN}/ialert/files?softwareType=${softwareType}&supplier=${supplier}&ecuGroup=${ecuGroup}`;
  if (vPartNumber) tempUrl += `&vPartNumber=${vPartNumber}`;
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: tempUrl,
    method: "GET",
  })
};

export const approverDetailsApi = (ecuGroupId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/users/ialert/approver?ecuGroupId=${ecuGroupId}`,
    method: "GET",
  })
};
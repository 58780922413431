import React, { Component } from "react";
import { Route, BrowserRouter, Redirect } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import SideMenu from "./SideMenu";
import Users from "../Users/Users";
import Vehicles from "../Vehicles/Vehicles";

const LeftLayout = ({ children }) => {
  return (
    <>
      <Row md="12">
        <Col md="2">
          <SideMenu />
        </Col>
        <Col md="10">
          <main>{children}</main>
        </Col>
      </Row>
    </>
  );
};
class Admin extends Component {
  state = {};
  render() {
    return (
      // <BrowserRouter>
      <>
        <Route
          path="/admin/users"
          exact
          render={(props) => (
            <LeftLayout>
              <Users {...props} />
            </LeftLayout>
          )}
        />
        <Route
          path="/admin/vehicles"
          exact
          render={(props) => (
            <LeftLayout>
              <Vehicles {...props} />
            </LeftLayout>
          )}
        />
        <Redirect from="/admin" to="/admin/users" />
      </>
      // </BrowserRouter>
    );
  }
}

export default Admin;

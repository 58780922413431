import React from "react";
import { Modal, Button, Form, Col, InputGroup, Spinner, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import "../../../../assets/styles/_modal.scss";
// import Schedules from "./Schedules";
import {
  ecuGroupApi,
  ecuNameApi,
  filesApi
} from "../../../../utill/api/ecuApi";
import {
  createScheduleSelectApi,
  createScheduleUploadApi,
  editScheduleApi,
  scheduleVehiclesApi,
  errorLogsApi,
  createDTCScheduleSelectApi,
  createDTCScheduleUploadApi,
  createReadParamScheduleSelectApi,
  createReadParamScheduleUploadApi
} from "../../../../utill/api/schedulesApi";
import { scheduleType } from "../../../../utill/config";
import InlineAlert from "../../../../shared/notificationAlert/inlineAlert";
import AsyncSelect from "react-select/async";
import makeAnimated from 'react-select/animated';
import { vehicleSearchApi } from "../../../../utill/api/vehicleApi";

const animatedComponents = makeAnimated();

class CreateScheduleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      disable: false,
      error: null,
      edit: Boolean(this.props.editRow),
      editRow: this.props.editRow,
      ecus: [{ ecuGroup: '', ecuName: '', fileName: ''}],
      ecuData: [{ groupList: [], nameList: [], fileList: [], isFileSelected: null }],
      ecusLoading: false,
      vehicles: [],
      vehiclesData: [],
      selectedVehicles: [],
      isVehicleSelected: null,
      type: this.props.type,
      checkboxValue: "select",
      invalidFileName: false,
      inputValue: '',
      scheduleExpiryDays: 14
    };
    this.getVehicles = this.getVehicles.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.getEcuGroup = this.getEcuGroup.bind(this);
    this.getEcuName = this.getEcuName.bind(this);
    // this.getFiles = this.getFiles.bind(this);
    this.onEcuGroupChange = this.onEcuGroupChange.bind(this);
    this.onEcuNameChange = this.onEcuNameChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOtherSubmit = this.handleOtherSubmit.bind(this);
  }

  componentDidMount() {
    // this.getVehicles();
    if (this.state.edit) {
      this.getSelectedVehicles();
      this.setState({ selectedFile: { label: this.props.editRow.fileName } });
    }
    this.getEcuGroup(0);
  }

  loadOptions = (inputValue) => (
    new Promise(async resolve => {
      const vehicles = await this.getVehicles(inputValue);
      resolve(vehicles);
    })
  );

  async getVehicles(inputValue) {
    const apiPromice = await vehicleSearchApi('vin', inputValue);
    const res = await apiPromice.json();
    if (res.error && res.status !== 200) {
      this.setState({ error: res });
      return [];
    } else {
      res.map(item => {
        item.value = item.vin;
        item.label = `${item.vin} (${item.serialNumber})`;
      });
      return res;
    }
  }

  loadFileOptions = (index, inputValue) => (
    new Promise(async resolve => {
      const files = await this.getFiles(index, inputValue);
      resolve(files);
    })
  );

  async getFiles(index, inputValue) {
    const { ecus, type } = this.state;
    const ecuGroupValue = ecus[index].ecuGroup;
    const ecuNameValue = ecus[index].ecuName;
    const apiPromice = await filesApi(ecuNameValue, ecuGroupValue, type, inputValue)
    const res = await apiPromice.json();
    if (res.error && res.status !== 200) {
      this.setState({ error: res });
      return [];
    } else {
      res.map(item => {
        item.value = item.id;
        item.label = item.fileName;
      });
      return res;
    }
  }

  getSelectedVehicles = () => {
    scheduleVehiclesApi(0, this.state.editRow.scheduleId)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(item => {
                item.value = item.vehicleId;
                item.label = item.vin;
              });
              this.setState({ isVehicleSelected: true, selectedVehicles: res });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  };

  getEcuGroup(index) {
    this.setState({ ecusLoading: true });
    ecuGroupApi()
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (this.state.edit) {
              this.setState({
                ecuGroup: res,
                ecuGroupId: this.state.editRow.ecuGroupId,
                ecuGroupValue: this.state.editRow.ecuGroup,
              }, () => this.getEcuName(null, this.state.editRow.ecuGroup));
              let ecuGroupObj = res.filter(item => {
                return item.ecuGroupId === this.state.editRow.ecuGroupId;
              });
              this.setState({ ecuGroupObj: ecuGroupObj });
            } else {
              if (res.length) {
                let data = [...this.state.ecuData];
                let selected = [...this.state.ecus]
                data[index].groupList = res;
                selected[index].ecuGroup = res[0].ecuGroup;
                this.setState(
                  { ecuData: data, ecus: selected },
                  () => this.getEcuName(index, res[0].ecuGroup)
                );
              }
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getEcuName(index) {
    let data = [...this.state.ecuData];
    let selected = [...this.state.ecus]
    ecuNameApi(selected[index].ecuGroup)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (this.state.edit && !this.state.groupChanged) {
              this.setState({
                ecuNameData: res,
                ecuNameId: this.state.editRow.ecuNameId,
                ecuNameValue: this.state.editRow.ecuName
              });
              if (this.state.checkboxValue === "select") {
                // this.getVehicles()
              }
              this.getFlashEcuFiles(this.state.editRow.ecuName);
            } else {
              data[index].nameList = res;
              selected[index].ecuName = res[0].ecuName;
              this.setState(
                { ecuData: data, ecus: selected },
                () => this.getFlashEcuFiles(index, res[0].ecuName)
              );
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  };

  getFlashEcuFiles = (index) => {
    if (this.state.type === 'flashEcu') this.getDefaultFiles(index);
  };

  getDefaultFiles = (index) => {
    const { type } = this.state;
    let data = [...this.state.ecuData];
    let selected = [...this.state.ecus]
    filesApi(selected[index].ecuName, selected[index].ecuGroup, type, "")
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(item => {
                item.label = item.fileName;
                item.value = item.id;
              });
              data[index].fileList = res;
              data[index].isFileSelected = true;
              selected[index].fileName = res[0];
              this.setState({ ecuData: data, ecus: selected, ecusLoading: false });
            } else {
              data[index].fileList = [];
              data[index].isFileSelected = null;
              selected[index].fileName = null;
              this.setState({ ecuData: data, ecus: selected, ecusLoading: false });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  onEcuGroupChange(index, e) {
    let selected = [...this.state.ecus];
    let data = [...this.state.ecuData];
    selected[index].ecuGroup = e.target.value;
    data[index].groupList.map(group => {
      if (group.ecuGroup === e.target.value) {
        selected[index].ecuGroupId = group.ecuGroupId;
      }
    });
    this.setState({ ecus: selected },
      () => this.getEcuName(index)
    );
  }

  onEcuNameChange(index, e) {
    let selected = [...this.state.ecus];
    selected[index].ecuName = e.target.value;
    this.setState({ ecus: selected },
      () => this.getFlashEcuFiles(index)
    );
  };

  selectFile = (index, value) => {
    let selected = [...this.state.ecus];
    selected[index].fileName = value;
    if (value !== null) {
      this.setState({ ecus: selected, isFileSelected: true });
    } else {
      this.setState({ ecus: selected, isFileSelected: false });
    }
  }

  addEcuRow = (e) => {
    let index = this.state.ecus.length;
    this.setState((prevState) => ({
      ecus: [...prevState.ecus, { ecuGroup: '', ecuGroupId: '', ecuName: '', fileName: '' }],
      ecuData: [...prevState.ecuData, { groupList: [], nameList: [], fileList: [], isFileSelected: null }]
    }), () => this.getEcuGroup(index));
  };

  removeEcuRow = (index) => {
    let ecus = [...this.state.ecus];
    let ecuData = [...this.state.ecuData];
    ecus.splice(index, 1);
    ecuData.splice(index, 1);
    this.setState({ ecus, ecuData });
  }

  handleRadioSelection = value => {
    this.setState({ checkboxValue: value, isVehicleSelected: false });
  };

  validateFileName = (elem) => {
    if (elem.target.files.length && /\s/.test(elem.target.files[0].name)) {
      this.setState({ invalidFileName: true });
      return true;
    } else {
      this.setState({ invalidFileName: false, isVehicleSelected: true });
      return false;
    }
  };

  selectVehicles = (values) => {
    if (values !== null && values.length) {
      this.setState({ isVehicleSelected: true, selectedVehicles: values });
    } else {
      this.setState({ isVehicleSelected: false, selectedVehicles: [] });
    }
  }

  handleFormSubmit(event) {
    if (this.state.type === 'flashEcu') this.handleFlashSubmit(event);
    else this.handleOtherSubmit(event);
  }

  handleOtherSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const {
      ecus,
      ecuData,
      isVehicleSelected,
      invalidFileName,
      checkboxValue,
      selectedVehicles,
      edit
    } = this.state;
    // let allFilesSelected = true;
    // if (isVehicleSelected === null) {
    //   this.setState({ isVehicleSelected: false });
    // }
    ecuData.map(ecu => {
      if (ecu.isFileSelected === null || ecu.isFileSelected === false) {
        ecu.isFileSelected = false;
        // allFilesSelected = false;
      }
    });
    this.setState({ ecuData });
    if (form.checkValidity() && !invalidFileName && isVehicleSelected /*&& allFilesSelected*/) {
      this.setState({ disable: true });
      const {
        description,
        ecuGroup,
        file,
        scheduleExpiryDays
      } = event.target;

      if (checkboxValue === "select") {
        let vehiclesData = [];
        selectedVehicles.map(item => {
          if (!vehiclesData.includes(item.vin)) {
            vehiclesData.push(item.vin);
          }
        });
        // old payload
        // const payload = {
        //   ecus: finalEcus,
        //   description: description.value,
        //   vehicles: vehiclesData,
        //   scheduleExpiryDays: scheduleExpiryDays.value,
        //   type: this.state.type,
        // };
        // New payload structure
        const payload = {
          ecuGroup: ecus[0].ecuGroup,
          ecuName: ecus[0].ecuName,
          description: description.value,
          vehicles: vehiclesData,
          scheduleExpiryDays: scheduleExpiryDays.value,
          type: this.state.type
        };
        if (edit) {
          this.editSchedule(payload)
        } else {
          if (['readDtc', 'clearDtc'].includes(this.state.type)) {
            this.createDTCScheduleSelect(payload);
          } else if (this.state.type === 'readFreezeFrame') {
            this.createFreezeFrameScheduleSelect(payload);
          } else this.createScheduleSelect(payload)
        }
      } else if (checkboxValue === "upload") {
        const payload = {
          // ecus: finalEcus,
          description: description.value,
          ecuGroup: ecus[0].ecuGroup,
          ecuName: ecus[0].ecuName,
          // fileName: selectedFile.label,
          scheduleExpiryDays: scheduleExpiryDays.value,
          type: this.state.type,
        };
        const formData = new FormData();
        formData.append("file", file.files[0]);
        if (['readDtc', 'clearDtc'].includes(this.state.type)) {
          this.createDTCScheduleUpload(formData, payload);
        } else if (this.state.type === 'readFreezeFrame') {
          this.createFreezeFrameScheduleUpload(formData, payload)
        } else this.createScheduleUpload(formData, payload);
      }
    }
    this.setState({ validated: true });
  };

  handleFlashSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const {
      ecus,
      ecuData,
      isVehicleSelected,
      invalidFileName,
      checkboxValue,
      selectedVehicles,
      edit
    } = this.state;
    let allFilesSelected = true;
    if (isVehicleSelected === null) {
      this.setState({ isVehicleSelected: false });
    }
    ecuData.map(ecu => {
      if (ecu.isFileSelected === null || ecu.isFileSelected === false) {
        ecu.isFileSelected = false;
        allFilesSelected = false;
      }
    });
    this.setState({ ecuData });
    if (form.checkValidity() && !invalidFileName && isVehicleSelected && allFilesSelected) {
      this.setState({ disable: true });
      const {
        description,
        ecuGroup,
        file,
        scheduleExpiryDays
      } = event.target;

      let finalEcus = [];
      ecus.map(ecu => {
        const obj = {};
        obj.ecuGroup = ecu.ecuGroup;
        obj.ecuName = ecu.ecuName;
        obj.fileName = ecu.fileName.fileName;
        finalEcus.push(obj);
      });

      if (checkboxValue === "select") {
        let vehiclesData = [];
        selectedVehicles.map(item => {
          if (!vehiclesData.includes(item.vin)) {
            vehiclesData.push(item.vin);
          }
        });
        const payload = {
          ecuGroup : ecus[0].ecuGroup,
        ecuName :ecus[0].ecuName,
        fileName : ecus[0].fileName.fileName,
          description: description.value,
          vehicles: vehiclesData,
          scheduleExpiryDays: scheduleExpiryDays.value,
          type: this.state.type,
        };
        if (edit) {
          this.editSchedule(payload)
        } else {
          this.createScheduleSelect(payload)
        }
      } else if (checkboxValue === "upload") {
        const payload = {
          // ecus: finalEcus,
          description: description.value,
          ecuGroup: finalEcus[0].ecuGroup,
          ecuName: finalEcus[0].ecuName,
          fileName: finalEcus[0].fileName,
          scheduleExpiryDays: scheduleExpiryDays.value,
          type: this.state.type,
        };
        const formData = new FormData();
        formData.append("file", file.files[0]);
        this.createScheduleUpload(formData, payload);
      }
    }
    this.setState({ validated: true });
  };

  createScheduleSelect = (payload) => {
    createScheduleSelectApi(payload)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            this.props.getSchedule('0');
            this.getErrorLogs(res);
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  };

  createScheduleUpload = (file, payload) => {
    createScheduleUploadApi(file, payload)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            this.props.getSchedule('0');
            this.getErrorLogs(res);
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  };

  createDTCScheduleSelect = (payload) => {
    createDTCScheduleSelectApi(payload)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            this.props.getSchedule('0');
            this.getErrorLogs(res);
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  };

  createDTCScheduleUpload = (file, payload) => {
    createDTCScheduleUploadApi(file, payload)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            this.props.getSchedule('0');
            this.getErrorLogs(res);
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  };

  createFreezeFrameScheduleSelect = (payload) => {
    createReadParamScheduleSelectApi(this.state.type, payload)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            this.props.getSchedule('0');
            this.getErrorLogs(res);
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  }

  createFreezeFrameScheduleUpload = (file, payload) => {
    createReadParamScheduleUploadApi(this.state.type, file, payload)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            this.props.getSchedule('0');
            this.getErrorLogs(res);
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  }

  editSchedule = (payload) => {
    editScheduleApi(this.state.editRow.scheduleId, payload)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            this.props.getSchedule('0');
            this.getErrorLogs(res);
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  };

  getErrorLogs = (metadata) => {
    errorLogsApi(metadata.message/*scheduleId*/)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            if (res.length !== 0) {
              metadata.successCode = "createScheduleWarning";
              metadata.warning = true;
              metadata.logs = res;
            } else {
              metadata.successCode = "createSchedule";
              metadata.warning = false;
            }
            this.props.onHide();
            this.props.showModalSuccess(metadata);
            this.setState({ disable: false });
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  };

  render() {
    const {
      ecus,
      ecuData,
      ecusLoading
    } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop={'static'}
        keyboard={false}
        size="md"
        id="schedule"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.state.edit ? "Edit Schedule" : `New ${scheduleType[this.state.type]} Schedule`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Boolean(this.state.error) ? (
            <InlineAlert error={this.state.error} />
          ) : null}
          <Form
            noValidate
            encType="multipart/form-data"
            validated={this.state.validated}
            onSubmit={this.handleFormSubmit}
          >
            <Form.Row>
              {this.state.edit ? (
                <Form.Group as={Col} md="12" controlId="validationCustom02">
                  <Form.Label>Schedule Name</Form.Label>
                  <Form.Control
                    disabled
                    name="name"
                    as="textarea"
                    rows="2"
                    placeholder={this.state.editRow.name}
                  />
                </Form.Group>
              ) : null}

              {ecus.map((val, idx) => {
                let gid = `gid-${idx}`, nid = `nid-${idx}`, fid = `fid-${idx}`
                return (
                  <>
                    {/* ECU Group */}
                    <Form.Group as={Col} md="12" controlId={gid}>
                      <Form.Label>ECU Group {idx + 1}</Form.Label>
                      <Form.Control
                        as="select"
                        required
                        name={gid}
                        value={ecus[idx].ecuGroup}
                        onChange={(e) => this.onEcuGroupChange(idx, e)}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {ecuData[idx].groupList.map((data, i) => {
                          return (
                            <option key={`ecuGroup_${i}`} value={data.ecuGroup}>
                              {data.ecuGroup}
                            </option>
                          );
                        })}{" "}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please choose ECU group.
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* ECU Name */}
                    <Form.Group as={Col} md="12" controlId={nid}>
                      <Form.Label>ECU Name {idx + 1}</Form.Label>
                      <Form.Control
                        as="select"
                        required
                        name={nid}
                        value={ecus[idx].ecuName}
                        onChange={(e) => this.onEcuNameChange(idx, e)}
                      >
                        <option value="" disabled defaultValue>
                          Select
                        </option>
                        {ecuData[idx].nameList.map((data, i) => {
                          return (
                            <option key={`ecuName_${i}`} value={data.ecuName}>
                              {data.ecuName}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please choose ECU name.
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* File Name */}
                    {this.state.type === 'flashEcu' ?
                      <Form.Group as={Col} md="12" controlId={fid}>
                        <Form.Label>Choose File {idx + 1}</Form.Label>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions={ecuData[idx].fileList}
                          value={ecus[idx].fileName}
                          loadOptions={(inputValue) => this.loadFileOptions(idx, inputValue)}
                          components={animatedComponents}
                          onChange={(value) => this.selectFile(idx, value)}
                          isDisabled={ecus[idx].ecuGroup === '' || ecus[idx].ecuName === ''}
                          noOptionsMessage={() => "No Files Found."}
                        />
                        {ecuData[idx].isFileSelected !== null && !ecuData[idx].isFileSelected ? (
                          <>
                            <small className="text-danger">
                              Please select a file.
                            </small>
                          </>
                        ) : null}
                      </Form.Group>
                    : null}
                    {/* {ecus.length !== 1 ?
                      <div className="removeRowButton col-md-1 text-right">
                        <Button variant="danger" size="md" onClick={() => this.removeEcuRow(idx)}>
                          <i className="fa fa-minus-circle fa-fw"></i>
                        </Button>
                      </div>
                      : null} */}
                  </>
                )
              })}

              {/* Radio Selection */}
              {!this.state.edit ? (
                <>
                  <div className="col-md-12 d-flex flex-column px-1 mb-2 width100">
                    <ToggleButtonGroup
                      type="radio"
                      name="options"
                      defaultValue={this.state.checkboxValue}
                      onChange={this.handleRadioSelection}
                    >
                      <ToggleButton variant="outline-secondary" value={"select"}>Select VIN</ToggleButton>
                      <ToggleButton variant="outline-secondary" value={"upload"}>Bulk Upload</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </>
              ) : null}

              {/* <div className="col-md-6 px-1 text-right">
                {this.state.type !== 'flashEcu' ?
                  <Button size="md" onClick={this.addEcuRow} disabled={ecusLoading}>
                    <i className="fa fa-plus-circle fa-fw"></i> Add ECU
                  </Button>
                : null}
              </div> */}

              {/* Select VIN Input */}
              {this.state.checkboxValue === "select" ? (
                <>
                  <Form.Group as={Col} md="12" controlId="selectVehicles">
                    <Form.Label>Search and Select VIN</Form.Label>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      value={this.state.selectedVehicles}
                      loadOptions={this.loadOptions}
                      components={animatedComponents}
                      onChange={this.selectVehicles}
                      noOptionsMessage={() => "No Vehicles Found."}
                    />
                    {this.state.isVehicleSelected !== null && !this.state.isVehicleSelected ? (
                      <>
                        <small className="text-danger">
                          Please select one or more VIN.
                        </small>
                      </>
                    ) : null}
                  </Form.Group>
                </>
              ) : null}

              {/* Bulk Upload Input */}
              {this.state.checkboxValue === "upload" ? (
                <>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustomUsername"
                    onChange={this.validateFileName}
                  >
                    <Form.Label>Bulk Upload</Form.Label>
                    <Form.Control type="file" name="file" required />
                    <Form.Control.Feedback type="invalid">
                      Please choose File.
                    </Form.Control.Feedback>
                    {this.state.invalidFileName ? (
                      <>
                        <small className="text-danger">
                          File Name contains space. Please rename the file and try again.
                        </small>
                      </>
                    ) : null}
                  </Form.Group>
                </>
              ) : null}

              <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  required
                  name="description"
                  type="text"
                  placeholder="Description"
                  maxLength={45}
                  defaultValue={
                    this.state.edit ? this.state.editRow.description : null
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please enter schedule description.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="8" controlId="validationCustom01">
                <Form.Label>Schedule Expiry</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    required
                    type="text"
                    min="1"
                    name="scheduleExpiryDays"
                    max={365}
                    placeholder="Days"
                    minLength={1}
                    maxLength={3}
                    pattern="^(?:[1-9]\d?|[12]\d{2}|3[0-5]\d|36[0-5])$"
                    defaultValue={
                      this.state.edit
                        ? this.props.editRow.scheduleExpiryDays
                        : this.state.scheduleExpiryDays
                    }
                  />
                  <InputGroup.Append className="expiry-text">
                    <InputGroup.Text id="basic-addon2">
                      days from date of approval
                    </InputGroup.Text>
                  </InputGroup.Append>
                  <Form.Control.Feedback type="invalid">
                    Please select days within range from 1 to 365 only.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Form.Row>
            {/* Submit Button */}
            <Button type="submit" variant="success" disabled={this.state.disable}>
              {this.state.disable ? (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  role="status"
                />
              ) : null}
              {this.state.edit ? "Save" : "Create"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CreateScheduleModal;

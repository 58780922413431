import React from "react";
import "./notFound.scss"

const NotFound = () => (
  <div id="notfound">
    <div className="notfound">
      <div className="notfound-404">
        <h3 className="not-found-color">Oops! Page not found</h3>
        <h1 className="not-found-color">
          <span>4</span>
          <span>0</span>
          <span>4</span>
        </h1>
      </div>
      <h2 className="not-found-color">we are sorry, but the page you requested was not found</h2>
    </div>
  </div>
);
export default NotFound;

import React from 'react';
import Footer from '../Footer/Footer';
import "./Login.scss"
import LoginForm from "./loginForm";

class Login extends React.Component {
  render() {
    return (
      <div>
        <LoginForm />
        <Footer />
      </div>
    );
  }
}

export default Login;

import React, { Component } from "react";
import { Nav, NavItem } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { permissionModules } from "../../utill/config";
import "./SyncSideMenu.scss";

class SyncSideMenu extends Component {
  currentUser = JSON.parse(localStorage.getItem("currentUser"));

  render() {
    return (
      <>
        <Nav className="flex-column left-menu">
          {/* PD Sync */}
          {this.currentUser &&
            this.currentUser.userType &&
            this.currentUser.userGroupId === 1 &&
            permissionModules[this.currentUser.userType].sync.includes("pdVehicleSync") ? (
              <NavItem>
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/sync/pdvehicle"
                >
                  PD Vehicle
              </NavLink>
              </NavItem>
            ) : null}
          {/* IAlert Sync */}
          {this.currentUser &&
            this.currentUser.userType &&
            this.currentUser.userGroupId === 2 &&
            permissionModules[this.currentUser.userType].sync.includes("iAlert") ? (
              <NavItem>
                <NavLink className="nav-link" to="/sync/ialert">
                  iAlert
              </NavLink>
              </NavItem>
            ) : null}
          {/* Ecu Master Sync */}
          {this.currentUser &&
            this.currentUser.userType &&
            this.currentUser.userGroupId === 1 &&
            permissionModules[this.currentUser.userType].sync.includes("ecuMasterSync") ? (
              <NavItem>
                <NavLink className="nav-link" to="/sync/ecumaster">
                  ECU Master
              </NavLink>
              </NavItem>
            ) : null}
          {/* Hex Sync Route */}
          {/* <NavItem>
            <NavLink className="nav-link" to="/sync/hexsync">
              HEX Sync
            </NavLink>
          </NavItem> */}
        </Nav>
      </>
    );
  }
}

export default SyncSideMenu;

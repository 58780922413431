import React, { Component } from 'react';
import { Alert, Badge, Card, ListGroup, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleType: props.scheduleType,
      vinCounts: props.vinCounts,
      vinDetails: props.vinDetails,
      validVins: props.validVins,
      invalidVins: props.invalidVins,
      selectedData: props.selectedData
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { vinCounts, vinDetails, validVins, invalidVins, selectedData } = nextProps;
    this.setState({ vinCounts, vinDetails, validVins, invalidVins, selectedData });
  }

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {this.state.selectedData.parameters.map(item => {
        return <span>{item} <br/></span>
      })}
    </Tooltip>
  );

  render() {
    if (this.props.currentStep !== 2) { // Prop: The current step
      return null
    }
    const {
      vinCounts,
      vinDetails,
      validVins,
      scheduleType,
      selectedData
    } = this.state;
    const badgeStyle = {
      fontWeight: '500',
      fontSize: '14px'
    }
    return (
      <>
        <div className="row noGutters">
          <div className="col-6">
            <Card className="create-schedule-card">
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <b>ECU Group:</b> {selectedData.ecuGroup}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Supplier:</b> {selectedData.supplier}
                </ListGroup.Item>
                {selectedData.softwareType ?
                  <ListGroup.Item>
                    <b>Software Type:</b> {selectedData.softwareType}
                  </ListGroup.Item>
                : null}
                {selectedData.parameters && selectedData.parameters.length ?
                  <ListGroup.Item>
                    <b>Parameters:</b> {selectedData.parameters[0]}{' '}
                    {selectedData.parameters.length > 1 ?
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={this.renderTooltip}
                        >
                        <Badge variant="primary" className='pull-right mt-1'>
                          {'+'}{selectedData.parameters.length - 1}{' others'}
                        </Badge>
                      </OverlayTrigger>
                    : null}
                  </ListGroup.Item>
                : null}
                {selectedData.parameterName ?
                  <ListGroup.Item>
                    <b>Parameter Name:</b> {selectedData.parameterName}
                  </ListGroup.Item>
                : null}
              </ListGroup>
            </Card>
          </div>
          <div className="col-6">
            <Card className="create-schedule-card">
              <ListGroup variant="flush">
                {selectedData.parameterValue ?
                  <ListGroup.Item>
                    <b>Parameter Value:</b> {selectedData.parameterValue}
                  </ListGroup.Item>
                : null}
                {selectedData.bFileName  && selectedData.softwareType !="C" ?
                  <ListGroup.Item>
                    <b>Base File Name:</b> {selectedData.bFileName}
                  </ListGroup.Item>
                : null}
                {selectedData.cFileName && (selectedData.softwareType =="B&C" || selectedData.softwareType=="C") ?
                  <ListGroup.Item>
                    <b>Cal File Name:</b> {selectedData.cFileName}
                  </ListGroup.Item>
                : null}
                <ListGroup.Item>
                  <b>Description:</b> {selectedData.description}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Schedule Expiry:</b> {selectedData.scheduleExpiryDays} days
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </div>
        </div>
        <hr/>
        <div className="row no-gutters mb-2">
          <div className="col-6">
            <h6>Preview</h6>
          </div>
          <div className="col-6 text-right">
            <Badge style={badgeStyle} variant="primary">Total: {vinCounts.totalVins || 0}</Badge>{'  '}|{'  '}
            <Badge style={badgeStyle} variant="success">Pass: {vinCounts.validVins || 0}</Badge>{'  '}|{'  '}
            <Badge style={badgeStyle} variant="danger">Fail: {vinCounts.invalidVins || 0}</Badge>{' '}
          </div>
        </div>
        {vinCounts.totalVins && !vinCounts.validVins ?
          <div className="width100 text-center py-2">
            <Alert variant="warning">
              <i className="fa fa-exclamation-triangle text-warning"></i>
              There aren't any VINs that match the valid criteria. 
              Please go back to the previous step and choose file with valid vins.
            </Alert>
          </div>
        : null}
        {vinCounts.totalVins ?
          <div className="bulk-vin-preview-table mb-3">
            <Table bordered size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>VIN</th>
                  <th>V Part Number</th>
                  <th>Status</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {vinDetails.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{i+1}</td>
                      <td>{item.vin}</td>
                      <td>{item.vPartNumber}</td>
                      <td>{item.status}</td>
                      <td>{item.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          : 
          <div className="col-12 text-center p-4">
            <i className="fa fa-exclamation-triangle text-warning" style={{fontSize: 5 + 'rem'}}></i><br/>
            <small className="text-secondary">
              Something went wrong. <br/>
              Please go back to the previous step and choose file with valid vins.
            </small>
          </div>
        }
      </>
    );
  }
}

export default Step2;
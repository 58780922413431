import React, { Component } from "react";
import { Route } from "react-router-dom";
import ReadDtcView from "../ReadDtcView/ReadDtcView";
import ReadParametersView from "../ReadParametersView/ReadParametersView";
import UpdateParametersView from "../UpdateParametersView/UpdateParametersView";
import ReadFreezeFrameView from "../ReadFreezeFrameView/ReadFreezeFrameView";
import "./DiagnosticsView.scss";
import ClearDtcView from "../ClearDtcView/ClearDtcView";
import FlashEcuView from "../FlashEcuView/FlashEcuView";
/**
 * 
 */
class DiagnosticsView extends Component {
  constructor() {
    super();
    this.state = {
      vehicleId: "vehicleId",
      scheduleType: "scheduleType"
    };
  }

  componentDidMount() {
    // console.log(this.props)
    this.setState({
      vehicleId: this.props.match.params.vehicleId,
      scheduleId: this.props.match.params.scheduleId,
      scheduleType: this.props.match.params.scheduleType
    });
  }

  render() {
    return (
      // <BrowserRouter>
      <>
        <Route
          path="/diagnostics/flashEcu/:scheduleId/:vehicleId"
          exact
          render={(props) => (
            <FlashEcuView {...props} />
          )}
        />
        <Route
          path="/diagnostics/readDtc/:scheduleId/:vehicleId"
          exact
          render={(props) => (
            <ReadDtcView {...props} />
          )}
        />
        <Route
          path="/diagnostics/clearDtc/:scheduleId/:vehicleId"
          exact
          render={(props) => (
            <ClearDtcView {...props} />
          )}
        />
        <Route
          path="/diagnostics/readParameters/:scheduleId/:vehicleId"
          exact
          render={(props) => (
            <ReadParametersView {...props} />
          )}
        />
        <Route
          path="/diagnostics/updateParameters/:scheduleId/:vehicleId"
          exact
          render={(props) => (
            <UpdateParametersView {...props} />
          )}
        />
        <Route
          path="/diagnostics/readFreezeFrame/:scheduleId/:vehicleId"
          exact
          render={(props) => (
            <ReadFreezeFrameView {...props} />
          )}
        />
      </>
      // </BrowserRouter>
    );
  }
}

export default DiagnosticsView;

import React, { Component } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomDataTable from "../../shared/customDataTable/customDataTable";
import "./Schedules.scss";
import {
  scheduleApi,
  approveRejectScheduleApi,
  withdrawScheduleApi,
  deleteScheduleApi,
} from "../../utill/api/schedulesApi";
import DateConverter from "../../shared/dateConverter/dateConverter";
import ScheduleApproval from "./scheduleApproval";
import ConfirmDeleteModal from "../../shared/modals/confirmDeleteModal";
import {
  permissionModules,
  scheduleType,
  scheduleStatus,
  defaultScheduleFilter,
} from "../../utill/config";
import ToastAlert from "../../shared/notificationAlert/toastAlert";
import StatusBar from "../../shared/statusBar/StatusBar";
import EditSchedule from "./editSchedule";
import ServerSearch from "../../shared/server-search/server-serach";
import CreateScheduleModal from "./CreateScheduleModals/Pd/createScheduleModal";
import CreateReadParamSchedModal from "./CreateScheduleModals/Pd/createReadParamSchedModal";
import CreateUpdateParamSchedModal from "./CreateScheduleModals/Pd/createUpdateParamSchedModal";
import CreateProdScheduleModal from "./CreateScheduleModals/Prod/createProdScheduleModal";
import CreateProdUpdateParamScheduleModal from "./CreateScheduleModals/Prod/createProdUpdateParamScheduleModal";
import CreateProdReadParamScheduleModal from "./CreateScheduleModals/Prod/CreateProdReadParamScheduleModal.jsx";
import CreateProdErrorScheduleModal from "./CreateScheduleModals/Prod/createProdErrorScheduleModal";

class Schedules extends Component {
  columns = [
    {
      name: "#",
      sortable: false,
      minWidth: "60px",
      maxWidth: "50px",
      left: true,
      cell: (row) => row.index,
    },
    {
      name: "Name",
      selector: "scheduleName",
      sortable: false,
      maxWidth: "700px",
      minWidth: "300px",
      cell: (row) => (
        <Link
          to={`schedules/${row.scheduleId}/${row.type}`}
          className="longString"
        >
          {row.name}
        </Link>
      ),
    },
    {
      name: "Description",
      selector: "description",
      sortable: false,
      minWidth: "200px",
      cell: (row) => <span className="text-ellipses">{row.description}</span>,
    },
    {
      name: "Type",
      selector: "type",
      sortable: false,
      minWidth: "200px",
      cell: (row) => <span>{scheduleType[row.type]}</span>,
    },
    {
      name: "Progress",
      selector: "status",
      sortable: false,
      minWidth: "200px",
      cell: (row) => <StatusBar schedule={row} />,
    },
    // {
    //   name: "Status",
    //   selector: "status",
    //   sortable: false,
    //   minWidth: "150px",
    //   cell: row => <span>{scheduleStatus[row.status]}</span>
    // },
    {
      name: "Approved/Rejected By",
      selector: "approvedBy",
      sortable: false,
      minWidth: "300px",
      cell: (row) => (
        <ScheduleApproval row={row} confirmDelete={this.confirmDelete} />
      ),
    },
    {
      name: "Approved/Rejected On",
      selector: "approvedDate",
      sortable: false,
      minWidth: "200px",
      cell: (row) => <DateConverter date={row.updatedTimeStamp} />,
    },
    {
      name: "Created By",
      selector: "initiatedBy",
      sortable: false,
      minWidth: "250px",
      cell: (row) => (
        <>
          <span>
            <p className="mb-1">{row.initiatedBy}</p>
          </span>
        </>
      ),
    },
    {
      name: "Created On",
      selector: "createdOn",
      sortable: false,
      minWidth: "200px",
      cell: (row) => <DateConverter date={row.createdTimeStamp} />,
    },
    {
      name: "Expiry ",
      selector: "expiryDate",
      sortable: false,
      minWidth: "200px",
      cell: (row) => <DateConverter date={row.expiryTimeStamp} />,
    },
    {
      name: "ECU Group > ECU Name",
      selector: "createdOn",
      sortable: false,
      minWidth: "250px",
      cell: (row) => (
        <span>
          {row.ecuGroup} {row.ecuGroup ? <span> {">"} </span> : null}{" "}
          {row.ecuName}
        </span>
      ),
    },
    // {
    //   name: "File Name",
    //   selector: "fileName",
    //   sortable: false,
    //   // right: true,
    //   minWidth: "250px",
    //   cell: row => <div>{row.fileName}</div>
    // },
    {
      name: "Action",
      selector: "action",
      sortable: true,
      width: "200px",
      cell: (row) => (
        <EditSchedule
          schedule={row}
          edit={this.editSchedule}
          delete={this.confirmDelete}
        />
      ),
    },
  ];

  searchDropdownlist = [
    { displayText: "Schedule Name", key: "name" },
    { displayText: "VIN", key: "vin" },
    { displayText: "ECU Group", key: "ecuGroup" },
    { displayText: "ECU Name", key: "ecuName" },
    { displayText: "Creator Name", key: "creatorName" },
    { displayText: "Creator Email", key: "creatorEmail" },
    { displayText: "Approver/Rejecter Name", key: "updaterName" },
    { displayText: "Approver/Rejector Email", key: "updaterEmail" },
  ];

  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  editRow = null;
  currentPage = 0;

  constructor() {
    super();
    this.state = {
      scheduleData: [],
      status: localStorage.getItem("selectedScheduleFilter")
        ? localStorage.getItem("selectedScheduleFilter")
        : defaultScheduleFilter[this.currentUser.userType],
      searchKey: localStorage.getItem("scheduleSearchKey")
        ? localStorage.getItem("scheduleSearchKey")
        : "",
      searchValue: localStorage.getItem("scheduleSearchValue")
        ? localStorage.getItem("scheduleSearchValue")
        : "",
      showModal: false,
      showReadParamModal: false,
      showUpdateParamModal: false,
      showDeleteModal: false,
      dropdownOpen: false,
      showErrorModal: false,
      showParamModal: false,
      isLoading: true,
      newScheduleType: null,
      deleteData: {},
      disableConfirmButton: false,
      error: null,
      currentUser: JSON.parse(localStorage.getItem("currentUser")),
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleReadParamModal = this.toggleReadParamModal.bind(this);
    this.toggleUpdateParamModal = this.toggleUpdateParamModal.bind(this);
    this.getSchedule = this.getSchedule.bind(this);
    this.setIndexNumber = this.setIndexNumber.bind(this);
  }

  componentDidMount() {
    /**
     * This is not called here because the useEffect Hook in the customDataTable
     * component calls it automatically in the initial rendering.
     * This methodology is followed across all class components using customDataTable.
     */
    // this.getSchedule(0);
    if (this.currentUser == null || this.currentUser === undefined) {
      localStorage.clear();
      this.props.history.push("/login");
    } 
    // else if (
    //   this.currentUser.userType === "SCHEDULE_APPROVER_L1" ||
    //   this.currentUser.userType === "SCHEDULE_APPROVER_L2"
    // ) {
    //   this.setState({ status: "APPROVAL_PENDING" });
    // }
  }

  toggleModal(type) {
    if (Boolean(this.editRow)) this.editRow = null;
    this.setState((oldState) => ({
      showModal: !oldState.showModal,
      newScheduleType: type,
    }));
  }

  toggleReadParamModal(type) {
    if (Boolean(this.editRow)) this.editRow = null;
    this.setState((oldState) => ({
      showReadParamModal: !oldState.showReadParamModal,
      newScheduleType: type,
    }));
  }

  toggleUpdateParamModal(type) {
    if (Boolean(this.editRow)) this.editRow = null;
    this.setState((oldState) => ({
      showUpdateParamModal: !oldState.showUpdateParamModal,
      newScheduleType: type,
    }));
  }

  toggleErrorModal = (type) => {
    if (Boolean(this.editRow)) this.editRow = null;
    this.setState((oldState) => ({
      showErrorModal: !oldState.showErrorModal,
      newScheduleType: type,
    }));
  };

  getSchedule(page) {
    const { status, searchKey, searchValue } = this.state;
    scheduleApi(status, page, searchKey, searchValue)
      .then((res) => {
        if (res.status !== 200) {
          res.json().then((res) => {
            this.setState({
              error: res,
              isLoading: false,
              showDeleteModal: false,
              disableConfirmButton: false,
            });
          });
        } else {
          res.json().then((res) => {
            if (res.length != 0) {
              this.setIndexNumber(res, page);
              if (this.state.showDeleteModal) {
                this.setState({
                  showDeleteModal: false,
                  disableConfirmButton: false,
                });
              }
            } else {
              if (page == 0) this.setState({ scheduleData: [] });
              this.setState({ isLoading: false });
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          error: e,
          isLoading: false,
          showDeleteModal: false,
          disableConfirmButton: false,
        });
      });
  }

  setIndexNumber(data, page) {
    if (page == 0) {
      this.setState({ scheduleData: [] });
    }
    data.map((row, index) => {
      row.index = this.state.scheduleData.length + index + 1;
      row.totalDevice =
        row.error +
        row.inProgress +
        row.scheduled +
        row.completed +
        row.cancelled;
    });
    if (page == 0) {
      this.setState({
        scheduleData: data,
        isLoading: false,
      });
    } else {
      this.setState((prevState) => {
        return {
          scheduleData: [...prevState.scheduleData, ...data],
          isLoading: false,
        };
      });
    }
  }

  updateStatus = () => {
    const { deleteData } = this.state;
    this.setState({ disableConfirmButton: true });

    if (deleteData.status === "APPROVED" || deleteData.status === "REJECTED") {
      this.approveReject();
    } else if (deleteData.status === "WITHDRAW") {
      this.withdraw(deleteData.row.scheduleId);
    } else if (deleteData.status === "DELETE_SCHEDULE") {
      this.deleteSchedule(deleteData.row.scheduleId);
    }
  };

  approveReject = () => {
    const payload = {
      scheduleId: this.state.deleteData.row.scheduleId,
      name: this.state.deleteData.row.name,
      status: this.state.deleteData.status,
    };
    approveRejectScheduleApi(payload)
      .then((res) => {
        if (res.status === 200) {
          res.json().then((res) => {
            if (this.state.deleteData.status === "APPROVED") {
              res.successCode = "approveSuccess";
            } else if (this.state.deleteData.status === "REJECTED") {
              res.successCode = "rejectSuccess";
            }
            this.setState({
              error: res,
              // deleteData: {},
              // showDeleteModal: false,
              // disableConfirmButton: false
            });
          });
          this.getSchedule(0);
        } else if (res.status !== 200) {
          res.json().then((res) => {
            this.setState({
              error: res,
              showDeleteModal: false,
              disableConfirmButton: false,
            });
          });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          error: e,
          showDeleteModal: false,
          disableConfirmButton: false,
        });
      });
    // this.setState({ showDeleteModal: false });
  };

  withdraw = (scheduleId) => {
    withdrawScheduleApi(scheduleId)
      .then((res) => {
        if (res.status === 200) {
          res.json().then((res) => {
            res.successCode = "withdrawSuccess";
            this.setState({
              error: res,
              // deleteData: {},
              // showDeleteModal: false,
              // disableConfirmButton: false
            });
          });
          this.getSchedule(0);
        } else if (res.status !== 200) {
          res.json().then((res) => {
            this.setState({
              error: res,
              showDeleteModal: false,
              disableConfirmButton: false,
            });
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: e,
          showDeleteModal: false,
          disableConfirmButton: false,
        });
      });
    // this.setState({ showDeleteModal: false });
  };

  deleteSchedule = (scheduleId) => {
    deleteScheduleApi(scheduleId)
      .then((res) => {
        if (res.status === 200) {
          // res.json().then(res => {
          res.successCode = "deleteSuccess";
          this.setState({
            error: res,
            // deleteData: {},
            // showDeleteModal: false,
            // disableConfirmButton: false
          });
          // });
          this.getSchedule(0);
        } else if (res.status !== 200) {
          res.json().then((res) => {
            this.setState({
              error: res,
              showDeleteModal: false,
              disableConfirmButton: false,
            });
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: e,
          showDeleteModal: false,
          disableConfirmButton: false,
        });
      });
  };

  editSchedule = (row) => {
    this.toggleModal(row.type);
    this.editRow = row;
  };

  confirmDelete = (data) => {
    if (!this.state.showDeleteModal) {
      this.setState({ showDeleteModal: true, deleteData: data });
    } else if (this.state.showDeleteModal) {
      this.setState({ showDeleteModal: false });
    }
  };

  handleSearch = async (key, value) => {
    localStorage.setItem("scheduleSearchKey", key);
    localStorage.setItem("scheduleSearchValue", value);
    this.setState({ searchKey: key, searchValue: value });
    if (key === "vin" && value.length && value.length < 5) return;
    this.setState({ isLoading: true });
    const apiPromice = await scheduleApi(this.state.status, 0, key, value);
    const res = await apiPromice.json();
    if (res.error && res.status !== 200) {
      this.setState({ error: res });
    } else {
      this.setIndexNumber(res, 0);
    }
  };

  onFilterChange = (status) => {
    if (status !== this.state.status) {
      this.currentPage = 0;
      localStorage.setItem("selectedScheduleFilter", status);
      this.setState(
        { status, isLoading: true }
        // () => this.getSchedule(0)
      );
    }
  };

  disableEditing = () => {
    this.editRow = null;
  };

  errorHandled = () => {
    this.setState({ error: null });
  };

  showModalSuccess = (e) => {
    this.setState({ error: e });
  };

  loadNextPage = (page, currentPage) => {
    this.currentPage = currentPage;
    this.getSchedule(page);
  };

  dropdownState = (value) => {
    this.setState({ dropdownOpen: value });
  };

  render() {
    const {
      scheduleData,
      status,
      isLoading,
      error,
      showModal,
      newScheduleType,
      showReadParamModal,
      showUpdateParamModal,
      showDeleteModal,
      deleteData,
      disableConfirmButton,
      showErrorModal,
    } = this.state;

    return (
      <>
        {/* Toast Alert */}
        {Boolean(error) ? (
          <ToastAlert
            show={Boolean(error)}
            onClose={this.errorHandled}
            error={error}
          />
        ) : null}
        {/* Page Content starts */}
        <Container fluid>
          <Row className="page-title">
            {/* Page Title Column */}
            <Col className="col-2">
              <h1>Schedules</h1>
            </Col>
            {/* Search Column */}
            <Col className="col-6">
              <ServerSearch
                dropdown
                schedules
                list={this.searchDropdownlist}
                search={this.handleSearch}
              />
            </Col>
            {/* Schedule Filter dropdown */}
            <Col className="col-2 text-left filterBtn p-0">
              <Dropdown>
                <Dropdown.Toggle
                  variant={
                    scheduleStatus[status]
                      ? "outline-secondary"
                      : "outline-secondary"
                  }
                  id="dropdown-basic"
                  disabled={isLoading}
                >
                  <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;
                  Filter by {scheduleStatus[status] ? scheduleStatus[status] : "ALL"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    active={status === "IN_PROGRESS"}
                    onClick={() => this.onFilterChange("IN_PROGRESS")}
                    variant="secondary"
                  >
                    In Progress&emsp;
                    {status === "IN_PROGRESS" ? (
                      <i className="fa fa-check" aria-hidden="true"></i>
                    ) : null}
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={status === "APPROVAL_PENDING"}
                    onClick={() => this.onFilterChange("APPROVAL_PENDING")}
                  >
                    Pending for Approval&emsp;
                    {status === "APPROVAL_PENDING" ? (
                      <i className="fa fa-check" aria-hidden="true"></i>
                    ) : null}
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={status === "WITHDRAW"}
                    onClick={() => this.onFilterChange("WITHDRAW")}
                  >
                    Withdrawn&emsp;
                    {status === "WITHDRAW" ? (
                      <i className="fa fa-check" aria-hidden="true"></i>
                    ) : null}
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={status === "REJECTED"}
                    onClick={() => this.onFilterChange("REJECTED")}
                  >
                    Rejected&emsp;
                    {status === "REJECTED" ? (
                      <i className="fa fa-check" aria-hidden="true"></i>
                    ) : null}
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={status === "COMPLETED"}
                    onClick={() => this.onFilterChange("COMPLETED")}
                  >
                    Completed&emsp;
                    {status === "COMPLETED" ? (
                      <i className="fa fa-check" aria-hidden="true"></i>
                    ) : null}
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={status === "ALL"}
                    onClick={() => this.onFilterChange("ALL")}
                  >
                    All&emsp;
                    {status === "ALL" ? (
                      <i className="fa fa-check" aria-hidden="true"></i>
                    ) : null}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {/* Create Schedule Button Column */}
            <Col className="col-2 text-right newSchedBtn">
              {this.currentUser &&
              this.currentUser.userType &&
              permissionModules[this.currentUser.userType].schedule.includes(
                "create"
              ) ? (
                <Dropdown
                  alignRight
                  show={this.state.dropdownOpen}
                  onMouseLeave={() => this.dropdownState(false)}
                >
                  <Dropdown.Toggle
                    variant={"success"}
                    size={"sm"}
                    id="dropdown-basic"
                    onMouseOver={() => this.dropdownState(true)}
                  >
                    <i className="fa fa-plus-circle fa-fw"></i> New Schedule
                  </Dropdown.Toggle>
                  {this.state.currentUser.userGroupId === 2 ? (
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => this.toggleModal("flashEcu")}
                      >
                        <i class="fa fa-bolt" aria-hidden="true"></i> ECU
                        Flashing
                      </Dropdown.Item>
                      <div class="dropdown-divider"></div>
                      <Dropdown.Item
                        onClick={() => this.toggleErrorModal("readDtc")}
                      >
                        <i
                          class="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>{" "}
                        Read Error Codes
                      </Dropdown.Item>
                      <div class="dropdown-divider"></div>
                      <Dropdown.Item
                        onClick={() => this.toggleErrorModal("clearDtc")}
                      >
                        <i class="fa fa-eraser" aria-hidden="true"></i> Read and
                        Erase Error Codes
                      </Dropdown.Item>
                      <div class="dropdown-divider"></div>
                      <Dropdown.Item
                        onClick={() => this.toggleErrorModal("readFreezeFrame")}
                      >
                        <i class="fa fa-file-text" aria-hidden="true"></i> Read
                        Error Codes with Snapshot
                      </Dropdown.Item>
                      <div class="dropdown-divider"></div>
                      <Dropdown.Item
                        onClick={() =>
                          this.toggleReadParamModal("readParameters")
                        }
                      >
                        <i class="fa fa-file-code-o" aria-hidden="true"></i>{" "}
                        Read Parameter
                      </Dropdown.Item>
                      <div class="dropdown-divider"></div>
                      <Dropdown.Item
                        onClick={() =>
                          this.toggleUpdateParamModal("updateParameters")
                        }
                      >
                        <i class="fa fa-microchip" aria-hidden="true"></i>{" "}
                        Update Parameter
                      </Dropdown.Item>
                      <div class="dropdown-divider"></div>
                    </Dropdown.Menu>
                  ) : (
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => this.toggleModal("flashEcu")}
                      >
                        <i class="fa fa-bolt" aria-hidden="true"></i> ECU
                        Flashing
                      </Dropdown.Item>
                      <div class="dropdown-divider"></div>
                      <Dropdown.Item
                        onClick={() => this.toggleModal("readDtc")}
                      >
                        <i
                          class="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>{" "}
                        Read Error Codes
                      </Dropdown.Item>
                      <div class="dropdown-divider"></div>
                      <Dropdown.Item
                        onClick={() => this.toggleModal("clearDtc")}
                      >
                        <i class="fa fa-eraser" aria-hidden="true"></i> Read and
                        Erase Error Codes
                      </Dropdown.Item>
                      <div class="dropdown-divider"></div>
                      <Dropdown.Item
                        onClick={() => this.toggleModal("readFreezeFrame")}
                      >
                        <i class="fa fa-file-text" aria-hidden="true"></i> Read
                        Error Codes with Snapshot
                      </Dropdown.Item>
                      <div class="dropdown-divider"></div>
                      <Dropdown.Item
                        onClick={() =>
                          this.toggleReadParamModal("readParameters")
                        }
                      >
                        <i class="fa fa-file-code-o" aria-hidden="true"></i>{" "}
                        Read Parameter
                      </Dropdown.Item>
                      <div class="dropdown-divider"></div>
                      <Dropdown.Item
                        onClick={() =>
                          this.toggleUpdateParamModal("updateParameters")
                        }
                      >
                        <i class="fa fa-microchip" aria-hidden="true"></i>{" "}
                        Update Parameter
                      </Dropdown.Item>
                      <div class="dropdown-divider"></div>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              ) : null}
            </Col>
          </Row>
          {/* Datatable Column */}
          <Row noGutters>
            <Col className="data-table">
              <CustomDataTable
                schedulesTable
                tableColumns={this.columns}
                data={scheduleData}
                loading={isLoading}
                loadNextPage={this.loadNextPage}
                currentPage={this.currentPage}
                selectedFilter={status}
              />
            </Col>
          </Row>
        </Container>
        {/* Create/Edit Schedule Modals */}
        {showModal ||
        showReadParamModal ||
        showUpdateParamModal ||
        showErrorModal ? (
          <>
            {this.state.currentUser.userGroupId == 2 ? (
              <>
                {showErrorModal ? (
                  <CreateProdErrorScheduleModal
                    show={showErrorModal}
                    getSchedule={this.getSchedule}
                    onHide={this.toggleErrorModal}
                    type={newScheduleType}
                    editRow={this.editRow}
                    showModalSuccess={this.showModalSuccess}
                  />
                ) : null}
                {showReadParamModal ? (
                  <CreateProdReadParamScheduleModal
                    show={showReadParamModal}
                    getSchedule={this.getSchedule}
                    onHide={this.toggleReadParamModal}
                    type={newScheduleType}
                    editRow={this.editRow}
                    showModalSuccess={this.showModalSuccess}
                  />
                ) : null}
                {showUpdateParamModal ? (
                  <CreateProdUpdateParamScheduleModal
                    show={showUpdateParamModal}
                    getSchedule={this.getSchedule}
                    onHide={this.toggleUpdateParamModal}
                    type={newScheduleType}
                    editRow={this.editRow}
                    showModalSuccess={this.showModalSuccess}
                  />
                ) : null}
                {showModal ? (
                  <CreateProdScheduleModal
                    show={showModal}
                    getSchedule={this.getSchedule}
                    onHide={this.toggleModal}
                    type={newScheduleType}
                    editRow={this.editRow}
                    showModalSuccess={this.showModalSuccess}
                  />
                ) : null}
              </>
            ) : (
              <>
                {showReadParamModal ? (
                  <CreateReadParamSchedModal
                    show={showReadParamModal}
                    getSchedule={this.getSchedule}
                    onHide={this.toggleReadParamModal}
                    editRow={this.editRow}
                    type={newScheduleType}
                    showModalSuccess={this.showModalSuccess}
                  />
                ) : null}
                {showUpdateParamModal ? (
                  <CreateUpdateParamSchedModal
                    show={showUpdateParamModal}
                    getSchedule={this.getSchedule}
                    onHide={this.toggleUpdateParamModal}
                    editRow={this.editRow}
                    type={newScheduleType}
                    showModalSuccess={this.showModalSuccess}
                  />
                ) : null}
                {showModal ? (
                  <CreateScheduleModal
                    show={showModal}
                    getSchedule={this.getSchedule}
                    onHide={this.toggleModal}
                    editRow={this.editRow}
                    type={newScheduleType}
                    showModalSuccess={this.showModalSuccess}
                  />
                ) : null}
              </>
            )}
          </>
        ) : null}
        {/* Confirm Action Modal */}
        <ConfirmDeleteModal
          show={showDeleteModal}
          disable={disableConfirmButton}
          onHide={this.confirmDelete}
          update={this.updateStatus}
          action={deleteData.status}
        />
      </>
    );
  }
}

export default Schedules;

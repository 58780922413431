import apiHelper from "./apiHelper";



export const tspApi = () => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/configurations`,
    method: "GET"
  })
};

import React from 'react';
import { Button } from 'react-bootstrap';
import { permissionModules } from "../../utill/config";

const RetryTrigger = (props) => {
  const { row, schedule } = props;
  const updateStatus = (row, scope) => {
    props.confirmDelete({ row, scope });
  };
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  let showButton = true;
  const failStatus = [
    // "CLEAR_DTC_FAILED",
    // "DELETE_FILE_FAILED",
    // "FILE_DWLD_FAILED",
    // "FLASH_CANCEL_FAILED",
    "FLASH_FAILED",
    // "READ_DTC_FAILED",
    // "READ_FREEZE_FRAME_FAILED",
    // "READ_PARAMETERS_FAILED",
    // "REMOTE_DIAG_CONN_FAILED",
    // "WRITE_PARAMETERS_FAILED"
  ];

  if (
    currentUser &&
    currentUser.userType &&
    currentUser.userType === "SCHEDULE_CREATOR_EXECUTOR" &&
    (permissionModules[currentUser.userType].schedule.includes("triggerFlash") ||
      permissionModules[currentUser.userType].schedule.includes("forceCancel"))
  ) {
    showButton =
      currentUser &&
      currentUser.userId &&
      currentUser.userId === schedule.createdBy
        ? true
        : false;
  }
  return (
    <>
      {currentUser &&
        currentUser.userType &&
        showButton &&
        permissionModules[currentUser.userType].schedule.includes("triggerFlash") &&
        failStatus.includes(row.status) &&
        row.retryCount < 3 ? (
          <>
            <Button
              className="mr-2 action-btn"
              variant="warning"
              size="sm"
              onClick={() => updateStatus(row, "retryTrigger")}
              >
              <i className="fa fa-bolt action-button-icon" aria-hidden="true"></i>&nbsp;
              Retry ({row.retryCount + 1})
            </Button>
          </>
        ) : null}
    </>
  );
}

export default RetryTrigger;
import React, { useState, useEffect, useRef } from "react";
import Datatable from "react-data-table-component";
import styled, { keyframes } from "styled-components";
import "./customDataTable.scss";
import { Row, Col } from "react-bootstrap";
import CustomPagination from "../customPagination/customPagination";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function CustomDataTable(props) {
  // props common to all pages
  const { loadNextPage } = props;
  // Schedule page props
  const { schedulesTable, selectedFilter } = props;
  // Files page props
  const { filesTable, resetPage } = props;
  // Getting previous value of props
  const prevFilter = usePrevious(selectedFilter);
  const prevResetPage = usePrevious(resetPage);
  // list, page and offset setters
  const [page, setPage] = useState(0);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const alTheme = {
    header: {
      fontSize: "15px",
      fontColorActive: "FFFFFF",
      fontColor: "#FFFFFF",
    },
    pagination: {
      fontSize: "13px",
      // fontColor: "#FFFFFF",
      // backgroundColor: "#363640",
      // buttonFontColor: "#FFFFFF",
      buttonHoverBackground: "#3a7fd5"
    }
  };

  const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

  const Spinner = styled.div`
    margin: 16px;
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);
    border-top: 2px solid #6ebce2;
    border-right: 2px solid #6ebce2;
    border-bottom: 2px solid #6ebce2;
    border-left: 4px solid #3a7fd5;
    background: transparent;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  `;

  const CustomLoader = () => (
    <div>
      <br /><br />
      <Spinner />
      {/* <div>Fancy Loader...</div> */}
      <br /><br />
    </div>
  );

  // Handling page chage 100 per page with 10 offset
  const handlePageChange = (pageOfItems, pager) => {
    setList(pageOfItems);
    if (
      pager.endIndex > 0 &&
      pager.endIndex === pager.totalItems - 1 &&
      pager.totalItems % 100 === 0
    ) {
      setPage(page + 1);
      setCurrentPage(pager.currentPage)
    }
  };

  // Page increment trigger common for all
  useEffect(() => {
    loadNextPage(page, currentPage);
  }, [loadNextPage, page, currentPage]);

  // Page reset trigger for Schedules page
  useEffect(() => {
    if (schedulesTable) {
      if (prevFilter !== undefined && prevFilter !== selectedFilter) {
        if (page === 0) {
          loadNextPage(0, 0)
        } else {
          setPage(0)
          setCurrentPage(0)
        }
      }
    }
  }, [schedulesTable, prevFilter, selectedFilter, page, loadNextPage]);

  // Page Reset trigger for Files page
  useEffect(() => {
    if (filesTable) {
      if (resetPage !== undefined && prevResetPage !== undefined && prevResetPage !== resetPage) {
        if (page !== 0) {
          // loadNextPage(0, 0)
          setPage(0)
          setCurrentPage(0)
        }
      }
    }
  }, [filesTable, prevResetPage, resetPage, page, loadNextPage]);

  return (
    <>
      <Datatable
        className="custom-table"
        columns={props.tableColumns}
        data={list}
        highlightOnHover
        striped
        customTheme={alTheme}
        progressPending={props.loading}
        progressComponent={<CustomLoader />}
        dense={props.dense}
        pointerOnHover={props.pointerOnHover}
        onRowClicked={props.rowClicked}
        conditionalRowStyles={props.activeRow}
      />
      <Row noGutters>
        <Col className="custom-pagination">
          <CustomPagination
            items={props.data}
            onChangePage={handlePageChange}
            initialPage={props.currentPage}
          />
        </Col>
      </Row>
    </>
  );
}

export default CustomDataTable;

var config = {
  scheduleStatus: {
    "APPROVAL_PENDING": "Pending for Approval",
    "IN_PROGRESS": "In Progress",
    "APPROVED": "Approved",
    "REJECTED": "Rejected",
    "ELAPSED": "Elapsed",
    "WITHDRAW": "Withdrawn",
    "COMPLETED": "Completed",
    "ALL" : "All"
  },
  vehicleStatus: {
    "SCHEDULE": "Scheduled",
    "IN_PROGRESS": "In Progress",
    "REJECTED": "Rejected",
    "ELAPSED": "Elapsed",
    "WITHDRAW": "Withdrawn",
    "CANCELLED": "Cancelled",
    "CLEAR_DTC_COMPLETED": "Clearing DTC Completed",
    "CLEAR_DTC_FAILED": "Clearing DTC Failed",
    "CLEAR_DTC_REQ": "Clearing DTC Requested",
    "ERASE_DTC_REQ": "Erase DTC Requested",
    "DELETE_FILE_COMPLETED": "File Deletion Completed",
    "DELETE_FILE_FAILED": "File Deletion Failed",
    "DELETE_FILE_REQ": "File Deletion Requested",
    "FILE_DWLD_COMPLETED": "File Download Completed",
    "FILE_DWLD_FAILED": "File Download Failed",
    "FILE_DWLD_REQ": "File Download Requested",
    "FILE_DWLD_TKN_INVD": "File Download Token Invalid",
    "FLASH_CANCEL_COMPLETED": "Flash Cancellation Completed",
    "FLASH_CANCEL_FAILED": "Flash Cancellation Failed",
    "FLASH_CANCEL_REQ": "Flash Cancellation Requested",
    "FLASH_CANCEL_TKN_INVD": "Flash Cancellation Token Invalid",
    "FLASH_ECU_COMPLETED": "Flashing ECU Completed",
    "FLASH_COMPLETED": "Flashing ECU Completed",
    "FLASH_FAILED": "Flashing Failed",
    "FLASH_REQ": "Flashing Requested",
    "FLASH_REQ_TKN_INVD": "Flashing Request Token Invalid",
    "READ_DTC_COMPLETED": "Reading DTC Completed",
    "READ_DTC_FAILED": "Reading DTC Failed",
    "READ_DTC_REQ": "Reading DTC Requested",
    "READ_FREEZE_FRAME_COMPLETED": "Reading DTC with Snapshot Completed",
    "READ_FREEZE_FRAME_FAILED": "Reading DTC with Snapshot Failed",
    "READ_FREEZE_FRAME_REQ": "Reading DTC with Snapshot Requested",
    "READ_PARAMETERS_COMPLETED": "Reading Parameters Completed",
    "READ_PARAMETERS_FAILED": "Reading Parameters Failed",
    "READ_PARAMETERS_REQ": "Reading Parameters Requested",
    "REMOTE_DIAG_CONN_FAILED": "Live Remote Diagnostics Connection Failed",
    "REMOTE_DIAG_REQ": "Live Remote Diagnostics Required",
    "REMOTE_DIAG_TKN_INVD": "Live Remote Diagnostics Token Invalid",
    "SERVR_ERR": "Server Error",
    "WRITE_PARAMETERS_COMPLETED": "Updating Parameters Completed",
    "WRITE_PARAMETERS_FAILED": "Updating Parameters Failed",
    "WRITE_PARAMETERS_REQ": "Updating Parameters Requested",
    "FILE_WRAPPING_FAILED": "File Wrapping Failed",
    "FILE_WRAPPING ": "File Wrapping In Progress"
  },
  fileType: {
    "BASE": "Base",
    "CONFIGURATION": "Configuration",
    "BASE+CONFIGURATION": "Base and Configuration"
  },
  permissionModules: {
    "ADMIN": {
      schedule: ["view", "create", "edit", "delete", "withdraw", "approve", "triggerFlash", "forceCancel"],
      file: ["view", "create", "delete"],
      admin: ["view", "create", "userEdit", "userDelete", "userUpdate","vehicleDelete"],
      sync: ["view", "pdVehicleSync", "ecuMasterSync", "iAlert"]
    },
    "SCHEDULE_ADMIN": {
      schedule: ["view", "create", "edit", "delete", "withdraw", "approve", "triggerFlash", "forceCancel"],
      file: ["view", "create", "delete"],
      admin: [],
      sync: []
    },
    "SCHEDULE_CREATOR": {
      schedule: ["view", "create", "edit", "withdraw"],
      file: ["view", "create", "delete"],
      admin: [],
      sync: []
    },
    "SCHEDULE_EXECUTOR": {
      schedule: ["view", "triggerFlash"],
      file: [],
      admin: [],
      sync: []
    },
    "SCHEDULE_CREATOR_EXECUTOR": {
      schedule: ["view", "create", "edit", "delete", "withdraw", "triggerFlash", "forceCancel"],
      file: ["view"],
      admin: [],
      sync: []
    },
    "SCHEDULE_APPROVER_L1": {
      schedule: ["view", "approve"],
      file: ["view", "create", "delete"],
      admin: [],
      sync: []
    },
    "SCHEDULE_APPROVER_L2": {
      schedule: ["view", "approve"],
      file: ["view", "create", "delete"],
      admin: [],
      sync: [],
    },
    "SCHEDULE_RO": {
      schedule: ["view"],
      file: ["view"],
      admin: [],
      sync: []
    },
    "IT_ADMIN_RO": {
      schedule: ["view"],
      file: ["view"],
      admin: ["view"],
      sync: []
    },
    "ADMIN_RO": {
      schedule: ["view"],
      file: ["view"],
      admin: ["view"],
      sync: []
    }
  },
  errorCodes: {
    "ERR_GL011": "The Authorization token is not valid. Please try again with a valid token."
  },
  successCodes: {
    "approveSuccess": "Schedule Approved Successfully.",
    "rejectSuccess": "Schedule Rejected Successfully",
    "withdrawSuccess": "Schedule Withdrawn Successfully",
    "deleteSuccess":"Schedule Deleted Successfully",
    "resubmitSuccess": "Schedule Re-Submitted Successfully",
    "flashEcu": "Flash Triggered Successfully for Schedule type OTA Flash",
    "updateParameters": "Flash Triggered Successfully for Schedule type Params Update",
    "deleteFile": "Flash Trigger Cancelled",
    "fileDelete": "File deleted Successfully",
    "createSchedule": "Schedule Created Successfully",
    "createScheduleWarning": "Schedule Created with following Warning",
    "editSchedule": "Schedule Edited Successfully",
    "addFile": "File Uploaded Successfully",
    "addUser": "User Added Successfully",
    "addVehicle": "Vehicle Added Successfully",
    "forceCancel": "Force Cancelled Scheduled Vehicle",
    "syncPDVehicleSuccess": "Manual Sync raised Successfully",
    "syncIAlertSuccess": "iAlert Sync raised Successfully",
    "xmlFileUploadSuccess": "XML File Uploaded Successfully",
    "changePasswordSuccess": "Password Changed Successfully",
    "deleteUserSuccess": "User Deleted Successfully",
    "deleteVehicleSuccess": "Vehicle Deleted Successfully",
    "editUserSuccess": "User Edited Successfully",
    "resetPasswordSuccess": "Password Reset Successfully",
    "retryTrigger": "Flash Triggred Successfully."
  },
  scheduleType: {
    "flashEcu": "ECU Flashing",
    "readDtc": "Read Error Codes",
    "clearDtc": "Read and Erase Error Codes",
    "readFreezeFrame": "Read Error Codes with Snapshot",
    "readParameters": "Read Parameter",
    "updateParameters": "Update Parameter"
  },
  userType: {
    "ADMIN": "Admin",
    "ADMIN_RO": "Admin Readonly",
    "IT_ADMIN_RO": "IT Admin Readonly",
    "SCHEDULE_ADMIN": "Schedule Admin",
    "SCHEDULE_RO": "Schedule Readonly",
    "SCHEDULE_APPROVER_L2": "Schedule Approver L2",
    "SCHEDULE_APPROVER_L1": "Schedule Approver L1",
    "SCHEDULE_CREATOR": "Schedule Creator",
    "SCHEDULE_EXECUTOR": "Schedule Executor",
    "SCHEDULE_CREATOR_EXECUTOR": "Schedule Creator Executor"
  },
  defaultScheduleFilter: {
    "ADMIN": "ALL",
    "ADMIN_RO": "ALL",
    "IT_ADMIN_RO": "ALL",
    "SCHEDULE_ADMIN": "ALL",
    "SCHEDULE_RO": "ALL",
    "SCHEDULE_APPROVER_L2": "ALL",
    "SCHEDULE_APPROVER_L1": "ALL",
    "SCHEDULE_CREATOR": "ALL",
    "SCHEDULE_EXECUTOR": "ALL",
    "SCHEDULE_CREATOR_EXECUTOR": "ALL"
  },
OBU_MAKE_TYPE:{
  'IT001':'iTriangle',
  'TAP76': 'Trimble',
  'DAN001': 'Danlaw'
  }
};

module.exports = config;
import React, { Component } from 'react';
import { Form, Col, Button, Spinner, InputGroup, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import AsyncSelect from "react-select/async";
import makeAnimated from 'react-select/animated';
import {
  vehiclesApi,
  vPartNumberApi,
  ecuDetailsApi,
  fileDetailsApi,
  supplierDetailsApi,
  softwareTypeDetailsApi
} from '../../../../utill/api/ecuApi';
import InlineAlert from '../../../../shared/notificationAlert/inlineAlert';
import Select from 'react-select';

const animatedComponents = makeAnimated();

class EcuFlashManual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      disable: false,
      error: null,
      scheduleType: this.props.type,
      isVehicleSelected: null,
      selectedVehicles: [],
      isVpartSelected: null,
      selectedVpart: null,
      disableVpartSelect: false,
      ecuDetails: [],
      selectedEcuId: '',
      selectedEcu: '',
      supplierDetails: [],
      selectedSupplier: '',
      selectedSupplierId: '',
      selectedIAlertVpartId: '',
      softwareTypeDetails: [],
      selectedType: '',
      bFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
      cFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
      radioButtonsEnable: {
        B: false,
        C: false,
        BPC: false,
        BAC: false
      }
    }
    this.getVehicles = this.getVehicles.bind(this);
    this.getVpartNumbers = this.getVpartNumbers.bind(this);
    this.getEcuDetails = this.getEcuDetails.bind(this);
    this.getSupplierDetails = this.getSupplierDetails.bind(this);
    this.getSoftwareTypeDetails = this.getSoftwareTypeDetails.bind(this);
    this.getFileDetails = this.getFileDetails.bind(this);
  }

  componentDidMount() { }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ disable: nextProps.disable });
  }

  onSchedTypeChange = (e) => {
    this.setState({ scheduleType: e.target.value });
  };

  loadVinOptions = (inputValue) => (
    new Promise(async resolve => {
      const vehicles = await this.getVehicles(inputValue);
      resolve(vehicles);
    })
  );

  async getVehicles(inputValue) {
    const { selectedVpart } = this.state;
    const vpart = (selectedVpart === null) ? undefined : selectedVpart.vPartNumber;
    const apiPromice = await vehiclesApi(inputValue, vpart);
    const res = await apiPromice.json();
    if (res.error && res.status !== 200) {
      this.setState({ error: res });
      return [];
    } else {
      res.map(item => {
        item.value = item.vin;
        item.label = `${item.vin} (${item.serialNumber})`;
      });
      return res;
    }
  }

  selectVehicles = (values) => {
    if (values !== null && values.length) {
      if (
        (this.state.isVehicleSelected === null || !this.state.isVehicleSelected) &&
        (this.state.selectedVpart === null || !Object.keys(this.state.selectedVpart).length)
      ) {
        const selectVpart = JSON.parse(JSON.stringify(values[0]));;
        selectVpart.value = selectVpart.vehicleId;
        selectVpart.label = selectVpart.vPartNumber;
        this.setState(
          { isVpartSelected: true, selectedVpart: selectVpart },
          () => this.getEcuDetails()
        );
      }
      this.setState({
        isVehicleSelected: true,
        selectedVehicles: values,
        disableVpartSelect: true
      });
    } else {
      this.setState({
        isVehicleSelected: false,
        selectedVehicles: [],
        isVpartSelected: false,
        selectedVpart: null,
        disableVpartSelect: false,
        ecuDetails: [],
        selectedEcuId: '',
        selectedEcu: '',
        supplierDetails: [],
        selectedSupplierId: '',
        selectedSupplier: '',
        softwareTypeDetails: [],
        selectedType: '',
        bFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
        cFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
        radioButtonsEnable: {
          B: false,
          C: false,
          BPC: false,
          BAC: false
        }
      });
    }
  }

  loadVpartOptions = (inputValue) => (
    new Promise(async resolve => {
      const vpart = await this.getVpartNumbers(inputValue);
      resolve(vpart);
    })
  );

  async getVpartNumbers(inputValue) {
    const apiPromice2 = await vPartNumberApi(inputValue);
    const res = await apiPromice2.json();
    if (res.error && res.status !== 200) {
      this.setState({ error: res });
      return [];
    } else {
      res.map(item => {
        item.value = item.vehicleId;
        item.label = item.vPartNumber;
      });
      return res;
    }
  }

  selectVpartNumber = (value) => {
    if (value !== null) {
      this.setState(
        { isVpartSelected: true, selectedVpart: value },
        () => this.getEcuDetails()
      );
    } else {
      this.setState({ isVpartSelected: false, selectedVpart: {} });
    }
  }

  getEcuDetails() {
    ecuDetailsApi(this.state.selectedVpart.vPartNumber)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(ecu => { ecu.label = `${ecu.ecuGroup}`; });
              this.setState({
                ecuDetails: res,
                selectedEcu: res[0].label,
                selectedEcuId: res[0].id
              }, () => this.getSupplierDetails());
            } else {
              this.setState({
                ecuDetails: [],
                selectedEcuId: '',
                selectedEcu: '',
                supplierDetails: [],
                selectedSupplier: '',
                selectedSupplierId: '',
                selectedIAlertVpartId: '',
                softwareTypeDetails: [],
                selectedType: '',
                bFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                cFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                radioButtonsEnable: {
                  B: false,
                  C: false,
                  BPC: false,
                  BAC: false
                }
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getSupplierDetails() {
    supplierDetailsApi(this.state.selectedEcu, this.state.selectedVpart.vPartNumber)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(sup => { sup.label = `${sup.supplier}` });
              this.setState({
                supplierDetails: res,
                selectedSupplier: res[0].label,
                selectedSupplierId: res[0].id,
                selectedIAlertVpartId: res[0].iAlertVPartNumberId
              }, () => this.getSoftwareTypeDetails());
            } else {
              this.setState({
                supplierDetails: [],
                selectedSupplier: '',
                selectedSupplierId: '',
                selectedIAlertVpartId: '',
                softwareTypeDetails: [],
                selectedType: '',
                bFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                cFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                radioButtonsEnable: {
                  B: false,
                  C: false,
                  BPC: false,
                  BAC: false
                }
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getSoftwareTypeDetails() {
    const { selectedEcu, selectedSupplier, selectedVpart } = this.state;
    const vpart = (selectedVpart === null) ? null : selectedVpart.vPartNumber;
    softwareTypeDetailsApi(selectedEcu, selectedSupplier, vpart)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              // res.map(sw => { swTypes.push(sw.softwareType) });
              // Explicitly adding B&C if both B and C are present. Works when res values are not repeated.
              if (res.includes('B') && res.includes('C')) {
                res.push('B&C');
                // const obj = {
                //   softwareType: 'B&C',
                //   bid: res[swTypes.indexOf('B')].id,
                //   cid: res[swTypes.indexOf('C')].id
                // };
                // res.push(obj);
              }
              this.setState({
                softwareTypeDetails: res,
                selectedType: res[0]
              }, () => this.getFiles());
            } else {
              this.setState({
                softwareTypeDetails: [],
                selectedType: '',
                bFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                cFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                radioButtonsEnable: {
                  B: false,
                  C: false,
                  BPC: false,
                  BAC: false
                }
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getFiles() {
    const { selectedType, bFileDetails, cFileDetails } = this.state;
    this.checkEnability();
    if (bFileDetails.data.length || cFileDetails.data.length) {
      this.setState({
        bFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
        cFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
      });
    }
    if (selectedType === 'B&C') {
      this.getFileDetails('B');
      this.getFileDetails('C');
    } else {
      this.getFileDetails(selectedType);
    }
  }

  getFileDetails(type) {
    const { selectedEcu, selectedSupplier, selectedVpart } = this.state;
    const vpart = (selectedVpart === null) ? null : selectedVpart.vPartNumber;
    fileDetailsApi(selectedEcu, selectedSupplier, type, vpart)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(item => {
                const d = new Date(item.effectivityDate);
                item.effDateString = `${d.toLocaleString('default', { month: 'short' })} ${d.getDate()} ${d.getFullYear()}`;
                item.label = `${item.fileName}  (Effective: ${item.effDateString})`;
                item.value = item.fileName;
              });
              if (type === 'B' || type === 'B+C') {
                this.setState({
                  bFileDetails: { data: res, selected: res[0], selectedName: res[0].fileName, selectedId: res[0].id }
                });
              } else if (type === 'C') {
                this.setState({
                  cFileDetails: { data: res, selected: res[0], selectedName: res[0].fileName, selectedId: res[0].id }
                });
              }
            } else {
              this.setState({
                bFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' },
                cFileDetails: { data: [], selected: null, selectedName: '', selectedId: '' }
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  checkEnability() {
    let { softwareTypeDetails, radioButtonsEnable } = this.state;
    if ((softwareTypeDetails.includes('B') && softwareTypeDetails.includes('C')) || softwareTypeDetails.includes('B&C')) {
      radioButtonsEnable = {
        B: true, C: true, BPC: false, BAC: true
      };
    } else if (softwareTypeDetails.includes('B+C')) {
      radioButtonsEnable = {
        B: false, C: false, BPC: true, BAC: false
      };
    } else if (softwareTypeDetails.includes('B')) {
      radioButtonsEnable = {
        B: true, C: false, BPC: false, BAC: false
      };
    } else if (softwareTypeDetails.includes('C')) {
      radioButtonsEnable = {
        B: false, C: true, BPC: false, BAC: false
      };
    } else {
      radioButtonsEnable = {
        B: false, C: false, BPC: false, BAC: false
      };
    }
    this.setState({ radioButtonsEnable });
  }

  onEcuGroupChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const { ecuDetails } = this.state;
    this.setState({
      selectedEcu: ecuDetails[selectedIndex].label,
      selectedEcuId: ecuDetails[selectedIndex].id
    }, () => this.getSupplierDetails());
  }

  onSupplierChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const { supplierDetails } = this.state;
    this.setState({
      selectedSupplier: supplierDetails[selectedIndex].label,
      selectedSupplierId: supplierDetails[selectedIndex].id,
      selectedIAlertVpartId: supplierDetails[selectedIndex].iAlertVPartNumberId,
    }, () => this.getSoftwareTypeDetails());
  }

  onSwtypeChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const { softwareTypeDetails } = this.state;
    this.setState({
      selectedType: softwareTypeDetails[selectedIndex],
    }, () => this.getFiles());
  }

  handleRadioClick = (type) => {
    const { softwareTypeDetails } = this.state;
    if (softwareTypeDetails.length && softwareTypeDetails.includes(type)) {
      this.setState({
        selectedType: type,
      }, () => this.getFiles());
    }
  }

  onFileChange = (e, type) => {
    // const selectedIndex = e.target.selectedIndex;
    // const selectedValue = e.target.selectedOptions[0].value;
    // const selectedId = e.target.selectedOptions[0].id;
    let { bFileDetails, cFileDetails } = this.state;
    if (type === 'B') {
      bFileDetails.selected = e;
      bFileDetails.selectedName = e.fileName;
      bFileDetails.selectedId = e.id;
      this.setState({ bFileDetails });
    } else if (type === 'C') {
      cFileDetails.selected = e;
      cFileDetails.selectedName = e.fileName;
      cFileDetails.selectedId = e.id;
      this.setState({ cFileDetails });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const {
      isVehicleSelected,
      isVpartSelected,
      scheduleType,
      selectedType,
      bFileDetails,
      cFileDetails,
      selectedEcu,
      selectedSupplier,
      selectedVpart
    } = this.state;
    if (isVehicleSelected === null) {
      this.setState({ isVehicleSelected: false });
    }
    if (isVpartSelected === null) {
      this.setState({ isVpartSelected: false });
    }
    if (form.checkValidity() && isVehicleSelected && isVpartSelected) {
      this.setState({ disable: true });
      // Do data submission tasks
      // Raw data from template
      const {
        description,
        scheduleExpiryDays
      } = event.target;
      // Data stored in state (vehicles)
      let vins = [];
      this.state.selectedVehicles.map(item => {
        if (!vins.includes(item.vin)) {
          vins.push(item.vin);
        }
      });
      const files = [];
      // send file name
      if (selectedType === 'B&C') {
        const b = {
          fileName: bFileDetails.selectedName,
          fileType:"B"
        }
        const c = {
          fileName: cFileDetails.selectedName,
          fileType:"C"
        }
        files.push(b, c);
      } else if (selectedType === 'B' ) {
        const b = {
          fileName: bFileDetails.selectedName,
          fileType:"B"
        }
        files.push(b);
      } else if (selectedType === 'B+C') {
        const b = {
          fileName: bFileDetails.selectedName,
          fileType:"B+C"
        }
        files.push(b);
      } else if (selectedType === 'C') {
        const c = {
          fileName: cFileDetails.selectedName,
          fileType:"C"
        }
        files.push(c);
      }
      // structure the payload
      const payload = {
        description: description.value,
        scheduleExpiryDays: scheduleExpiryDays.value,
        type: scheduleType,
        vehicles: vins,
        vPartNumber: selectedVpart.vPartNumber,
        ecuGroup: selectedEcu,
        supplier: selectedSupplier,
        files: files
      };
      this.props.createSchedule(payload);
    }
    this.setState({ validated: true });
  };

  // createSchedule = (payload) => {
  //   createIalertScheduleApi(payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         res.json().then(res => {
  //           this.props.getSchedule('0');
  //           this.getErrorLogs(res);
  //         })
  //       } else if (res.status !== 200) {
  //         res.json().then(res => {
  //           this.setState({ error: res, disable: false });
  //         })
  //       }
  //     })
  //     .catch(e => {
  //       this.setState({ error: e, disable: false });
  //       console.log(e);
  //     });
  // };

  // getErrorLogs = (metadata) => {
  //   errorLogsApi(metadata.message/*scheduleId*/)
  //     .then(res => {
  //       if (res.status === 200) {
  //         res.json().then(res => {
  //           if (res.length !== 0) {
  //             metadata.successCode = "createScheduleWarning";
  //             metadata.warning = true;
  //             metadata.logs = res;
  //           } else {
  //             metadata.successCode = "createSchedule";
  //             metadata.warning = false;
  //           }
  //           this.props.onHide();
  //           this.props.showModalSuccess(metadata);
  //           this.setState({ disable: false });
  //         })
  //       } else if (res.status !== 200) {
  //         res.json().then(res => {
  //           this.setState({ error: res, disable: false });
  //         })
  //       }
  //     })
  //     .catch(e => {
  //       this.setState({ error: e, disable: false });
  //       console.log(e);
  //     });
  // };

  render() {
    const {
      isVehicleSelected,
      isVpartSelected,
      selectedVpart,
      disableVpartSelect,
      ecuDetails,
      selectedEcu,
      supplierDetails,
      selectedSupplier,
      softwareTypeDetails,
      selectedType,
      bFileDetails,
      cFileDetails,
      radioButtonsEnable,
      validated
    } = this.state;
    return (
      <>
        {/* Error Display */}
        {Boolean(this.state.error) ? (
          <InlineAlert error={this.state.error} />
        ) : null}
        <Form
          noValidate
          encType="multipart/form-data"
          validated={this.state.validated}
          onSubmit={this.handleSubmit}
          >
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="vin">
              <Form.Label>Search and Select VIN</Form.Label>
              <AsyncSelect
                isMulti
                loadOptions={this.loadVinOptions}
                components={animatedComponents}
                onChange={this.selectVehicles}
              />
              {isVehicleSelected !== null && !isVehicleSelected ? (
                <>
                  <small className="text-danger">
                    Please select one or more VIN.
                  </small>
                </>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="vpart">
              <Form.Label>Search and Select a V Part Number</Form.Label>
              <AsyncSelect
                value={selectedVpart}
                loadOptions={this.loadVpartOptions}
                components={animatedComponents}
                onChange={this.selectVpartNumber}
                isDisabled={disableVpartSelect}
              />
              {isVpartSelected !== null && !isVpartSelected ? (
                <>
                  <small className="text-danger">
                    Please select V Part Number.
                  </small>
                </>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="ecuGroup">
              <Form.Label>ECU Group</Form.Label>
              <Form.Control
                as="select"
                required
                name="ecuGroup"
                value={selectedEcu}
                onChange={this.onEcuGroupChange}
              >
                {ecuDetails.map((item, i) => {
                  return (
                    <option key={i} id={item.id} value={item.label}>{item.label}</option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a Group.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="supplier">
              <Form.Label>Supplier</Form.Label>
              <Form.Control
                as="select"
                required
                name="supplier"
                value={selectedSupplier}
                onChange={this.onSupplierChange}
                >
                {supplierDetails.map((item, i) => {
                  return (
                    <option key={i} id={item.id} value={item.label}>{item.label}</option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a Supplier.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="swType">
              <Form.Label>Software Type</Form.Label>
              <Form.Control
                as="select"
                required
                name="swType"
                value={selectedType}
                onChange={this.onSwtypeChange}
                >
                {softwareTypeDetails.map((item, i) => {
                  return (
                    <option key={i} value={item}>{item}</option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select Software Type.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="12" className="radioButton" controlId="swTypeRadio">
              <ButtonToolbar>
                <ToggleButtonGroup type="radio" name="fileType" value={selectedType}>
                  <ToggleButton
                    value={'B+C'}
                    disabled={!radioButtonsEnable.BPC}
                    checked={selectedType === 'B+C' ? true : false}
                    onClick={() => this.handleRadioClick('B+C')}
                    >
                    B+C
                  </ToggleButton>
                  <ToggleButton
                    value={'B'}
                    disabled={!radioButtonsEnable.B}
                    checked={selectedType === 'B' ? true : false}
                    onClick={() => this.handleRadioClick('B')}
                    >
                    B
                  </ToggleButton>
                  <ToggleButton
                    value={'C'}
                    disabled={!radioButtonsEnable.C}
                    checked={selectedType === 'C' ? true : false}
                    onClick={() => this.handleRadioClick('C')}
                    >
                    C
                  </ToggleButton>
                  <ToggleButton
                    value={'B&C'}
                    disabled={!radioButtonsEnable.BAC}
                    checked={selectedType === 'B&C' ? true : false}
                    onClick={() => this.handleRadioClick('B&C')}
                    >
                    B&C
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="baseDataset">
              <Form.Label>Base Dataset Part No.</Form.Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={bFileDetails.selected}
                isDisabled={selectedType === 'C'}
                isLoading={false}
                isClearable={false}
                isSearchable={true}
                name="color"
                options={bFileDetails.data}
                onChange={(e) => this.onFileChange(e, 'B')}
              />
              {validated && bFileDetails.selected == null ? (
                <>
                  <small className="text-danger">
                    Please select a File Name.
                  </small>
                </>
              ) : null}
            </Form.Group>
            {/* <Form.Group as={Col} md="6" controlId="baseDataset">
              <Form.Label>Base Dataset Part No.</Form.Label>
              <Form.Control
                as="select"
                required
                name="baseDataset"
                disabled={selectedType === 'C'}
                onChange={(e) => this.onFileChange(e, 'B')}
                >
                {bFileDetails.data.map((item, i) => {
                  return (
                    <option key={i} id={item.id} value={item.fileName}>
                      {item.fileName}&emsp;(Effective: {item.effDateString})
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a File Name.
              </Form.Control.Feedback>
            </Form.Group> */}

            <Form.Group as={Col} md="6" controlId="calibDataset">
              <Form.Label>Calibration Dataset Part No.</Form.Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={cFileDetails.selected}
                isDisabled={selectedType === 'B' || selectedType === 'B+C'}
                isLoading={false}
                isClearable={false}
                isSearchable={true}
                name="color"
                options={cFileDetails.data}
                onChange={(e) => this.onFileChange(e, 'C')}
              />
              {validated && cFileDetails.selected == null ? (
                <>
                  <small className="text-danger">
                    Please select a File Name.
                  </small>
                </>
              ) : null}
            </Form.Group>
            {/* <Form.Group as={Col} md="6" controlId="calibDataset">
              <Form.Label>Calibration Dataset Part No.</Form.Label>
              <Form.Control
                as="select"
                required
                name="calibDataset"
                disabled={selectedType === 'B' || selectedType === 'B+C'}
                onChange={(e) => this.onFileChange(e, 'C')}
                >
                {cFileDetails.data.map((item, i) => {
                  if (item.softwareType === 'C') {
                    return (
                      <option key={i} id={item.id} value={item.fileName}>
                        {item.fileName}&emsp;(Effective: {item.effDateString})
                      </option>
                    );
                  }
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select File Name.
              </Form.Control.Feedback>
            </Form.Group> */}

            <Form.Group as={Col} md="12" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                required
                name="description"
                type="text"
                placeholder="Description"
                maxLength={45}
              />
              <Form.Control.Feedback type="invalid">
                Please enter schedule description.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="8" controlId="scheduleExpiry">
              <Form.Label>Schedule Expiry</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  required
                  type="text"
                  min="1"
                  name="scheduleExpiryDays"
                  max={365}
                  placeholder="Days"
                  minLength={1}
                  maxLength={3}
              
                  pattern="^(?:[1-9]\d?|[12]\d{2}|3[0-5]\d|36[0-5])$"
                />
                <InputGroup.Append className="expiry-text">
                  <InputGroup.Text id="basic-addon2">
                    days from date of approval
                      </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  Please select days within range from 1 to 365 only.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>

          <Button type="submit" disabled={this.state.disable} style={{ float: 'right', backgroundColor: "#fb6666" }}>
            {this.state.disable ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                role="status"
              />
            ) : null}
                Create
              </Button>
        </Form>
      </>
    );
  }
}

export default EcuFlashManual;
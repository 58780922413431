import apiHelper from "./apiHelper";
/**
 * 
 */
export const vehicleApi = (page) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/vehicles?page=${page}&limit=${100}`,
    method: "GET"
  })
};
/**
 * 
 * @param {*} payload 
 */
export const createVehicleApi = (payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/vehicles`,
    method: "POST",
    body: payload
  })
};

export const vehicleSearchApi = (key, value) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/vehicles/search?key=${key}&value=${value}`,
    method: "GET"
  })
};


export const vehicleDeleteApi = (vehicleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/vehicles/${vehicleId}`,
    method: "DELETE"
  })
};
import apiHelper from "./apiHelper";



export const fileApi = (page) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/files?page=${page}&limit=${100}`,
    method: "GET"
  })
};

export const fileSearchApi = (page, key, value) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/files/search?key=${key}&value=${value}&page=${page}&limit=${100}`,
    method: "GET"
  })
};

export const createFileApi = (payload, data, selectedType) => {
  let tempUrl = `${process.env.REACT_APP_DOMAIN}/files/upload` +
  `?ecuGroup=${data.ecuGroup}` +
  `&ecuName=${data.ecuName}` +
  `&scheduleType=${data.scheduleType}`;

  if (selectedType === "flashEcu") {
    if (data.fileType == 'B+C') {
      data.fileType = 'B%2BC';
    }
  }
  if (data.fileType) tempUrl += `&fileType=${data.fileType}`;
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Authorization": "Bearer " + token },
    url: tempUrl,
    method: "POST",
    body: payload
  })
};

export const uploadHexFieApi = (payload, data, selectedType) => {
  let tempUrl = `${process.env.REACT_APP_DOMAIN}/files/upload/hex` +
  `?ecuGroup=${data.ecuGroup}` +
  `&ecuName=${data.ecuName}` +
  `&scheduleType=${data.scheduleType}`;

  if (selectedType === "flashEcu") {
    if (data.fileType == 'B+C') {
      data.fileType = 'B%2BC';
    } else if (data.fileType == 'B&C') {
      data.fileType = 'B%26C';
    }
  }
  if (data.fileType) tempUrl += `&fileType=${data.fileType}`;
  if (data.ecuManufacturerName) tempUrl += `&ecuManufacturerName=${data.ecuManufacturerName}`;
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Authorization": "Bearer " + token },
    url: tempUrl,
    method: "POST",
    body: payload
  })
};

export const deleteFileApi = (fileId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/files/${fileId}`,
    method: "DELETE",
  })
};
import React, { Component } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Button } from 'react-bootstrap';
import './Calendar.scss'
import DateConverter from '../dateConverter/dateConverter';

class CalendarOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      startDate: new Date(),
      endDate: new Date(),
      today: new Date()
    }
  }

  componentDidMount() {
    const tmilli = Date.parse(new Date().toUTCString());
    const startDate = new Date(tmilli - (30 * 24 * 60 * 60 * 1000));
    this.setState({ startDate },
      () => this.props.onChange([startDate, this.state.endDate])
    );
    // this.props.onChange(this.state.date)
  }

  toggleOverlay = () => {
    this.setState(oldState => ({
      show: !oldState.show
    }));
  }

  selectStartDate = (date) => {
    this.setState({ startDate: date });
  }

  selectEndDate = (date) => {
    const {today} = this.state;
    let currentDateTime;
    if (date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()) {
      currentDateTime = new Date(
        date.getFullYear(), date.getMonth(), date.getDate(),
        today.getHours(), today.getMinutes(), today.getSeconds()
      )
    } else {
      currentDateTime = new Date(
        date.getFullYear(), date.getMonth(), date.getDate(),
        11, 59, 59
      )
    }
    this.setState({ endDate: currentDateTime },
      () => this.onDateSelect()
    );
  }

  onDateSelect = () => {
    const date = [this.state.startDate, this.state.endDate]
    this.setState({ date });
    this.toggleOverlay();
    this.props.onChange(date)
  }

  render() {
    const {
      show,
      today,
      startDate,
      endDate
    } = this.state;
    return (
      <>
        <Button size="sm" variant="btn btn-outline-secondary btn-sm" onClick={this.toggleOverlay}>
          <i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;&nbsp;
          
          
            <DateConverter date={startDate} format="DD-MMM-YYYY" />
            {' '} to {' '}
            <DateConverter date={endDate} format="DD-MMM-YYYY" />
          
          
        </Button>
        {show ?
          <div className="calendarOverlay">
            <Calendar
              className="border-right"
              maxDate={endDate}
              onChange={this.selectStartDate}
              value={startDate}
            />
            <Calendar
              maxDate={today}
              minDate={startDate}
              onChange={this.selectEndDate}
              value={endDate}
            />
          </div>
          : null}
      </>
    );
  }
}

export default CalendarOverlay;
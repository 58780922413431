import React, { Component } from "react";
import { Nav, NavItem } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./SideMenu.scss";

class SideMenu extends Component {
  state = {};

  render() {
    return (
      <Nav className="flex-column left-menu">
        <NavItem>
          <NavLink
            activeClassName="active"
            className="nav-link"
            to="/admin/users"
          >
            <i class="fa fa-users" aria-hidden="true" style={{marginRight: 5}}></i>
            Users
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link" to="/admin/vehicles">
            <i class="fa fa-car" aria-hidden="true" style={{marginRight: 5}}></i>
            Vehicles
          </NavLink>
        </NavItem>
        {/* <NavItem>
                <NavLink className="nav-link" to="/admin/ecu">ECU Master</NavLink>
            </NavItem> */}
      </Nav>
    );
  }
}

export default SideMenu;

import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import CustomDataTable from "../../shared/customDataTable/customDataTable";
import DateConverter from "../../shared/dateConverter/dateConverter";
import { getSyncLogApi, syncPDVehicle } from "../../utill/api/syncApi";
import { Button } from "react-bootstrap";
import ToastAlert from "../../shared/notificationAlert/toastAlert";
import ConfirmDeleteModal from "../../shared/modals/confirmDeleteModal";

const columns = [
  {
    name: "#",
    selector: "index",
    sortable: true,
    maxWidth: "50px"
  },
  {
    name: "Added",
    selector: "addedCount",
    sortable: true,
    maxWidth: "150px",
    cell: row => <span>{row.addedCount}</span>
  },
  {
    name: "Updated",
    selector: "updatedCount",
    sortable: true,
    maxWidth: "150px",
    cell: row => <span>{row.updatedCount}</span>
  },
  {
    name: "Updated By",
    selector: "updatedBy",
    sortable: true,
    minWidth: "150px",
  },
  {
    name: "Message",
    selector: "message",
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Sync At",
    selector: "syncAt",
    sortable: true,
    minWidth: "100px",
    cell: row => <DateConverter date={row.syncAt} />
  }
];
/**
 * 
 */
class PDVehicle extends Component {
  currentPage = 0;
  constructor() {
    super();
    this.state = {
      pdVehicleLog: [],
      isLoading: true,
      error: null,
      showConfirmModal: false,
      disableConfirmButton: false
    };
    this.getPdVehicleLog = this.getPdVehicleLog.bind(this);

  }
  componentDidMount() {
    //this.getPdVehicleLog();
  }

  /**
* To get files
*/
  getPdVehicleLog(page) {
    getSyncLogApi('PD', page)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.setIndexNumber(res, page);
            } else this.setState({ isLoading: false });
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false, error: e });
      });
  }
  /**
   * To set index for each row
   */
  setIndexNumber = (data, page) => {
    if (page == 0) {
      this.setState({ pdVehicleLog: [] });
    }
    data.map((row, index) => {
      return row.index = this.state.pdVehicleLog.length + index + 1;
    });
    if (page == 0) {
      this.setState({
        pdVehicleLog: data,
        isLoading: false
      })
    } else {
      this.setState(prevState => {
        return {
          pdVehicleLog: [...prevState.pdVehicleLog, ...data],
          isLoading: false
        }
      });
    }
    // this.setState({ pdVehicleLog: data, isLoading: false });
  };
  /**
  * 
  */
  loadNextPage = (page, currentPage) => {
    this.currentPage = currentPage;
    this.getPdVehicleLog(page);
  };

  syncPDVehicle = () => {
    this.setState({ disableConfirmButton: true });
    syncPDVehicle()
      .then(res => {
        if (res.status !== 200) {
          res.json().then(data => {
            this.setState({
              error: data,
              showConfirmModal: false,
              disableConfirmButton: false
            });
          });
        } else {
          res.successCode = "syncPDVehicleSuccess"
          this.setState({
            error: res,
            showConfirmModal: false,
            disableConfirmButton: false
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  };

  toggleConfirmModal = () => {
    this.setState(oldState => ({
      showConfirmModal: !oldState.showConfirmModal
    }));
  };

  errorHandled = () => {
    this.setState({ error: null });
  };

  render() {
    const {
      error,
      showConfirmModal,
      disableConfirmButton
    } = this.state;
    return (
      <>
        {/* Toast Alert */}
        {Boolean(error) ? (
          <ToastAlert
            show={Boolean(error)}
            onClose={this.errorHandled}
            error={error}
          />
        ) : null}
        {/* Page Content starts */}
        <Container fluid>
          <Row noGutters className="page-title">
            <Col className="col-6">
              <h1>PD Vehicle</h1>
            </Col>
            <Col className="col-6 text-right">
              <Button
                className="sync-button"
                variant="danger" size="md"
                onClick={this.toggleConfirmModal}
              >
                <i className={"fa fa-refresh " + this.state.syncing} aria-hidden="true"></i>
                <span className="name pl-2">Sync Vehicle</span>
              </Button>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="data-table">
              <CustomDataTable
                tableColumns={columns}
                data={this.state.pdVehicleLog}
                loading={this.state.isLoading}
                loadNextPage={this.loadNextPage}
                currentPage={this.currentPage}
              />
            </Col>
          </Row>
        </Container>
        {/* Confirm Action Modal */}
        <ConfirmDeleteModal
          show={showConfirmModal}
          disable={disableConfirmButton}
          onHide={this.toggleConfirmModal}
          update={this.syncPDVehicle}
          action={"confirmSync"}
        />
      </>
    );
  }
} export default PDVehicle;
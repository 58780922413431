import React, { Component } from "react";
import { Pagination } from "react-bootstrap";
import PropTypes from "prop-types";

const propTypes = {
  items: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
};

const defaultProps = {
  // initialPage: 1,
  pageSize: 10,
};

class CustomPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pager: {},
    };
  }

  componentWillMount() {
    // set page if items array isn't empty
    if (this.props.items && this.props.items.length) {
      this.setPage(this.props.initialPage);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // reset page if items array has changed
    if (this.props.items !== prevProps.items) {
      this.setPage(this.props.initialPage);
    }
  }

  setPage(page) {
    var { items, pageSize } = this.props;
    var pager = this.state.pager;

    // if (page < 1 || page > pager.totalPages) {
    //   return;
    // }

    // get new pager object for specified page
    pager = this.getPager(items.length, page, pageSize);
    // get new page of items from items array
    var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

    // update state
    this.setState({ pager: pager });

    // call change page function in parent component
    this.props.onChangePage(pageOfItems, pager);
  }

  getPager(totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    var pages = [...Array(endPage + 1 - startPage).keys()].map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  render() {
    var pager = this.state.pager;
    const textClass = {
      fontStyle: "italic",
      color: "#6c757d",
      fontSize: "14px",
      margin: "10px 0",
    };

    const align = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between", // Add space between text and pagination controls
    };

    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null;
    }

    return (
      <>
        <Pagination style={align}>
          <span style={textClass}>
            Showing {pager.startIndex + 1} - {pager.endIndex + 1} of{" "}
            {pager.totalItems} items.&nbsp;
          </span>

          <Pagination.First
            className={pager.currentPage === 1 ? "disabled" : ""}
            onClick={() => this.setPage(1)}
          />
          <Pagination.Prev
            className={pager.currentPage === 1 ? "disabled" : ""}
            onClick={() => this.setPage(pager.currentPage - 1)}
          />
          {pager.pages.map((page, index) => (
            <Pagination.Item
              className={pager.currentPage === page ? "active" : null}
              key={index}
              onClick={() => this.setPage(page)}
            >
              {page}
            </Pagination.Item>
          ))}
          <Pagination.Next
            className={pager.currentPage === pager.totalPages ? "disabled" : ""}
            onClick={() => this.setPage(pager.currentPage + 1)}
          />
          <Pagination.Last
            className={pager.currentPage === pager.totalPages ? "disabled" : ""}
            onClick={() => this.setPage(pager.totalPages)}
          />
        </Pagination>
      </>
    );
  }
}

CustomPagination.propTypes = propTypes;
CustomPagination.defaultProps = defaultProps;
export default CustomPagination;

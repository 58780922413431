import React, { Component } from 'react';
import EcuFlashBulk from './ecuFlashBulk';
import ReadClearDtcBulk from './readClearDtcBulk';
import ReadParamBulk from './readParamBulk';
import UpdateParamBulk from './updateParamBulk';

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleType: props.scheduleType
    }
  }

  render() {
    // if (this.props.currentStep !== 1) { // Prop: The current step
    //   return null
    // }
    // The markup for the Step 1 UI
    return (
      <>
        {this.props.scheduleType === 'flashEcu' ?
          <EcuFlashBulk
            scheduleType={this.props.scheduleType}
            currentStep={this.props.currentStep}
            handleChange={this.props.handleChange}
            isDataStored={this.props.isDataStored}
            validated={this.props.validated}
          />
        : null}
        {['readDtc', 'clearDtc', 'readFreezeFrame'].includes(this.props.scheduleType) ?
          <ReadClearDtcBulk
            scheduleType={this.props.scheduleType}
            currentStep={this.props.currentStep}
            handleChange={this.props.handleChange}
            isDataStored={this.props.isDataStored}
            validated={this.props.validated}
          />
        : null}
        {this.props.scheduleType === 'readParameters' ?
          <ReadParamBulk
            scheduleType={this.props.scheduleType}
            currentStep={this.props.currentStep}
            handleChange={this.props.handleChange}
            isParameterSelected={this.props.isParameterSelected}
            isDataStored={this.props.isDataStored}
            validated={this.props.validated}
          />
        : null}
        {this.props.scheduleType === 'updateParameters' ?
          <UpdateParamBulk
            scheduleType={this.props.scheduleType}
            currentStep={this.props.currentStep}
            handleChange={this.props.handleChange}
            isParameterSelected={this.props.isParameterSelected}
            isDataStored={this.props.isDataStored}
            validated={this.props.validated}
          />
        : null}
      </>
    )
  }
}

export default Step1;
import React, { Component } from "react";
/**
 *
 */
class HexSync extends Component {

  state = {};
  render() {
    return (
      <>
      Coming soon ......
      </>
    );
  }
}export default HexSync;
import React, { Component } from 'react';
import { Form, Col, Button, Spinner, InputGroup } from 'react-bootstrap';
import AsyncSelect from "react-select/async";
import makeAnimated from 'react-select/animated';
import {
  vehiclesApi,
  vPartNumberApi,
  ecuDetailsApi,
  supplierDetailsApi,
  getEcuNameApi
} from '../../../../utill/api/ecuApi';
import { getParametersApi } from "../../../../utill/api/schedulesApi";
import InlineAlert from '../../../../shared/notificationAlert/inlineAlert';
import Select from 'react-select';

const animatedComponents = makeAnimated();

class ReadParamManual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      disable: false,
      error: null,
      type: this.props.type,
      isVehicleSelected: null,
      selectedVehicles: [],
      selectedEcuOtaFlsRefName:'',
      isVpartSelected: null,
      selectedVpart: null,
      disableVpartSelect: false,
      parameters: [],
      selectedParameters: [],
      isParameterSelected: null,
      maxParameters: false,
      ecuDetails: [],
      selectedEcu: '',
      selectedEcuId: '',
      ecuGroup: '',
      ecuName: '',
      supplierDetails: [],
      selectedSupplier: '',
      selectedSupplierId: '',
      selectedIAlertVpartId: '',
      selectedIAlertVpart: ''

    }
    this.getVehicles = this.getVehicles.bind(this);
    this.getVpartNumbers = this.getVpartNumbers.bind(this);
    this.getEcuDetails = this.getEcuDetails.bind(this);
    this.getSupplierDetails = this.getSupplierDetails.bind(this);
  }

  componentDidMount() { }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ disable: nextProps.disable });
  }

  loadVinOptions = (inputValue) => (
    new Promise(async resolve => {
      const vehicles = await this.getVehicles(inputValue);
      resolve(vehicles);
    })
  );

  async getVehicles(inputValue) {
    const { selectedVpart } = this.state;
    const vpart = (selectedVpart === null) ? undefined : selectedVpart.vPartNumber;
    const apiPromice = await vehiclesApi(inputValue, vpart);
    const res = await apiPromice.json();
    if (res.error && res.status !== 200) {
      this.setState({ error: res });
      return [];
    } else {
      res.map(item => {
        item.value = item.vin;
        item.label = `${item.vin} (${item.serialNumber})`;
      });
      return res;
    }
  }

  selectVehicles = (values) => {
    if (values !== null && values.length) {
      if (
        (this.state.isVehicleSelected === null || !this.state.isVehicleSelected) &&
        (this.state.selectedVpart === null || !Object.keys(this.state.selectedVpart).length)
      ) {
        const selectVpart = JSON.parse(JSON.stringify(values[0]));;
        selectVpart.value = selectVpart.vehicleId;
        selectVpart.label = selectVpart.vPartNumber;
        this.setState(
          { isVpartSelected: true, selectedVpart: selectVpart },
          () => this.getEcuDetails()
        );
      }
      this.setState({
        isVehicleSelected: true,
        selectedVehicles: values,
        disableVpartSelect: true
      });
    } else {
      this.setState({
        isVehicleSelected: false,
        selectedVehicles: [],
        isVpartSelected: false,
        selectedVpart: null,
        disableVpartSelect: false,
        ecuDetails: [],
        selectedEcuId: '',
        selectedEcu: '',
        selectedEcuOtaFlsRefName:'',
        ecuGroup: '',
        ecuName: '',
        supplierDetails: [],
        selectedSupplier: '',
        selectedSupplierId: '',
        parameters: [],
        selectedParameters: [],
        isParameterSelected: null,
        maxParameters: false,
      });
    }
  }

  loadVpartOptions = (inputValue) => (
    new Promise(async resolve => {
      const vpart = await this.getVpartNumbers(inputValue);
      resolve(vpart);
    })
  );

  async getVpartNumbers(inputValue) {
    const apiPromice2 = await vPartNumberApi(inputValue);
    const res = await apiPromice2.json();
    if (res.error && res.status !== 200) {
      this.setState({ error: res });
      return [];
    } else {
      res.map(item => {
        item.value = item.vehicleId;
        item.label = item.vPartNumber;
      });
      return res;
    }
  }

  selectVpartNumber = (value) => {
    if (value !== null) {
      this.setState(
        { isVpartSelected: true, selectedVpart: value },
        () => this.getEcuDetails()
      );
    } else {
      this.setState({ isVpartSelected: false, selectedVpart: {} });
    }
  }

  getEcuDetails() {
    ecuDetailsApi(this.state.selectedVpart.vPartNumber, false)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(ecu => { ecu.label = `${ecu.ecuGroup}`; });
              this.setState({
                ecuDetails: res,
                selectedEcu: res[0].label,
                selectedEcuId: res[0].id,
                ecuGroup: res[0].ecuGroup,
                // ecuName: res[0].model
              }, () => this.getSupplierDetails());
            } else {
              this.setState({
                ecuDetails: [],
                selectedEcuId: '',
                selectedEcu: '',
                ecuGroup: '',
                ecuName: '',
                supplierDetails: [],
                selectedSupplier: '',
                selectedSupplierId: '',
                selectedIAlertVpartId: '',
                selectedIAlertVpart: ''
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getSupplierDetails() {
    supplierDetailsApi(this.state.selectedEcu, this.state.selectedVpart.vPartNumber)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(sup => { sup.label = `${sup.supplier}` });
              this.setState({
                supplierDetails: res,
                selectedSupplier: res[0].label,
                selectedSupplierId: res[0].id,
                selectedIAlertVpartId: res[0].iAlertVPartNumberId,
                selectedIAlertVpart: res[0].vPartNumber,
                selectedEcuOtaFlsRefName: res[0].ecuOtaFlsRefName
                // ecuName: res[0].connectAllEcuName
              }, () => this.getParameters());
            } else {
              this.setState({
                supplierDetails: [],
                selectedSupplierId: '',
                selectedSupplier: '',
                selectedIAlertVpartId: '',
                selectedIAlertVpart: '',
                selectedEcuOtaFlsRefName: ''
                // ecuName: '',
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getEcuName = () => {
    const { selectedEcuId, selectedSupplierId, selectedVpart } = this.state;
    getEcuNameApi(selectedEcuId, selectedSupplierId, selectedVpart.vPartNumber)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            this.setState({
              ecuName: res.ecuName
            }, () => this.getParameters());
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getParameters = () => {
    const { type, selectedEcuOtaFlsRefName } = this.state;
    getParametersApi(type, selectedEcuOtaFlsRefName)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(response => {
            if (response.length) {
              const temp = [];
              response.map(item => {
                const obj = {};
                obj.value = item.id;
                obj.label = type === 'readFreezeFrame' ? item.signalName : item.description;
                obj.lid = type === 'readFreezeFrame' ? item.lidId : item.lidHex;
                obj.referenceId = item.referenceId;
                temp.push(obj);
              });
              this.setState({ parameters: temp });
            } else this.setState({ parameters: [] });
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  selectParameters = (values) => {
    if (values !== null && values.length) {
      if (values.length <= 10) {
        this.setState({
          isParameterSelected: true,
          selectedParameters: values,
          maxParameters: false
        });
      } else {
        this.setState({ maxParameters: true });
      }
    } else {
      this.setState({
        isParameterSelected: false,
        selectedParameters: [],
        maxParameters: false
      });
    }
  }

  onEcuGroupChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const { ecuDetails } = this.state;
    this.setState({
      selectedEcu: ecuDetails[selectedIndex].label,
      selectedEcuId: ecuDetails[selectedIndex].id,
      ecuGroup: ecuDetails[selectedIndex].ecuGroup,
      isParameterSelected: null,
      selectedParameters: [],
      maxParameters: false
      // ecuName: ecuDetails[selectedIndex].model
    }, () => this.getSupplierDetails());
  }

  onSupplierChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const { supplierDetails } = this.state;
    this.setState({
      selectedSupplier: supplierDetails[selectedIndex].label,
      selectedSupplierId: supplierDetails[selectedIndex].id,
      selectedIAlertVpartId: supplierDetails[selectedIndex].iAlertVPartNumberId,
      selectedIAlertVpart:supplierDetails[selectedIndex].vPartNumber,
      selectedEcuOtaFlsRefName: supplierDetails[selectedIndex].ecuOtaFlsRefName,
      isParameterSelected: null,
      selectedParameters: [],
      maxParameters: false
      // ecuName: supplierDetails[selectedIndex].connectAllEcuName
    }, () => this.getParameters());
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const {
      type,
      isVehicleSelected,
      isVpartSelected,
      isParameterSelected,
      selectedParameters,
      selectedEcu,
      selectedSupplier,
      selectedIAlertVpartId,
      selectedIAlertVpart
    } = this.state;
    if (isVehicleSelected === null) {
      this.setState({ isVehicleSelected: false });
    }
    if (isVpartSelected === null) {
      this.setState({ isVpartSelected: false });
    }
    if (isParameterSelected === null) {
      this.setState({ isParameterSelected: false });
    }
    if (form.checkValidity() && isVehicleSelected && isVpartSelected && isParameterSelected) {
      this.setState({ disable: true });
      const {
        description,
        scheduleExpiryDays
      } = event.target;
      // Data stored in state (vehicles)
      let vehiclesData = [];
      this.state.selectedVehicles.map(item => {
        if (!vehiclesData.includes(item.vin)) {
          vehiclesData.push(item.vin);
        }
      });
      let parameters = [];
      selectedParameters.map((item, index) => {
        let parametersObj = {}
        parametersObj['lidHex'] = item.lid;
        parametersObj['referenceId'] = item.referenceId;
        if (type === 'readParameters') parametersObj['description'] = item.label;
        parameters.push(parametersObj);
      });
      // structure the payload
      const payload = {
        description: description.value,
        scheduleExpiryDays: scheduleExpiryDays.value,
        vehicles: vehiclesData,
        parameters: parameters,
        ecuGroup: selectedEcu,
        supplier: selectedSupplier,
        vPartNumber: selectedIAlertVpart
      };
      this.props.createSchedule(payload);
    }
    this.setState({ validated: true });
  };

  render() {
    const {
      isVehicleSelected,
      isVpartSelected,
      selectedVpart,
      disableVpartSelect,
      ecuDetails,
      selectedEcu,
      supplierDetails,
      selectedSupplier,
      parameters,
      selectedParameters,
      isParameterSelected,
      maxParameters
    } = this.state;
    return (
      <>
        {/* Error Display */}
        {Boolean(this.state.error) ? (
          <InlineAlert error={this.state.error} />
        ) : null}
        <Form
          noValidate
          encType="multipart/form-data"
          validated={this.state.validated}
          onSubmit={this.handleSubmit}
        >
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="vin">
              <Form.Label>Search and Select VIN</Form.Label>
              <AsyncSelect
                isMulti
                loadOptions={this.loadVinOptions}
                components={animatedComponents}
                onChange={this.selectVehicles}
              />
              {isVehicleSelected !== null && !isVehicleSelected ? (
                <>
                  <small className="text-danger">
                    Please select one or more VIN.
                    </small>
                </>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="vpart">
              <Form.Label>Search and Select a V Part Number</Form.Label>
              <AsyncSelect
                value={selectedVpart}
                loadOptions={this.loadVpartOptions}
                components={animatedComponents}
                onChange={this.selectVpartNumber}
                isDisabled={disableVpartSelect}
              />
              {isVpartSelected !== null && !isVpartSelected ? (
                <>
                  <small className="text-danger">
                    Please select V Part Number.
                  </small>
                </>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="ecuGroup">
              <Form.Label>ECU Group</Form.Label>
              <Form.Control
                as="select"
                required
                name="ecuGroup"
                value={selectedEcu}
                onChange={this.onEcuGroupChange}
              >
                {ecuDetails.map((item, i) => {
                  return (
                    <option key={i} value={item.label}>{item.label}</option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a Group.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="supplier">
              <Form.Label>Supplier</Form.Label>
              <Form.Control
                as="select"
                required
                name="supplier"
                value={selectedSupplier}
                onChange={this.onSupplierChange}
              >
                {supplierDetails.map((item, i) => {
                  return (
                    <option key={i} value={item.label}>{item.label}</option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a Supplier.
              </Form.Control.Feedback>
            </Form.Group>

            {/* Parameter Selection */}
            <Form.Group as={Col} md="12" controlId="selectVehicles">
              <Form.Label>Select Parameter</Form.Label>
              {maxParameters ? (
                <p>
                  <small className="text-warning">
                    Maximum of 10 Parameters are allowed. You have already selected them. To select another Parameter remove from the selected and re-select.
                </small>
                </p>
              ) : null}
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={parameters}
                value={selectedParameters}
                components={animatedComponents}
                onChange={this.selectParameters}
              />
              {isParameterSelected !== null && !isParameterSelected ? (
                <>
                  <small className="text-danger">
                    Please select one or more Parameter.
                </small>
                </>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                required
                name="description"
                type="text"
                placeholder="Description"
                maxLength={45}
              />
              <Form.Control.Feedback type="invalid">
                Please enter schedule description.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="8" controlId="scheduleExpiry">
              <Form.Label>Schedule Expiry</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  required
                  type="text"
                  min="1"
                  name="scheduleExpiryDays"
                  max={365}
                  placeholder="Days"
                  minLength={1}
                  maxLength={3}
                  pattern="^(?:[1-9]\d?|[12]\d{2}|3[0-5]\d|36[0-5])$"
                />
                <InputGroup.Append className="expiry-text">
                  <InputGroup.Text id="basic-addon2">
                    days from date of approval
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  Please select days within range from 1 to 365 only.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>

          <Button type="submit" disabled={this.state.disable} style={{ float: 'right' }}>
            {this.state.disable ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                role="status"
              />
            ) : null}
            Create
          </Button>
        </Form>
      </>
    );
  }
}
 
export default ReadParamManual;
import React, { Component } from "react";
import { Route, BrowserRouter, Redirect } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import SyncSideMenu from "./SyncSideMenu";
import PDVehicle from "../PdVehicle/PDVehicle";
import IAlert from "../IAlert/IAlert";
import EcuMaster from "../EcuMaster/EcuMaster";
import HexSync from "../HexSync/HexSync";
import NotFound from "../notFound/notFound";
import { permissionModules } from "../../utill/config";
/**
 * 
 * @param {*} param0 
 */
const LeftLayout = ({ children }) => {
  return (
    <>
      <Row md="12">
        <Col md="2">
          <SyncSideMenu />
        </Col>
        <Col md="10">
          <main>{children}</main>
        </Col>
      </Row>
    </>
  );
};
/**
 * 
 */
class Sync extends Component {

  checkPdSyncPermission = (props, module) => {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser && currentUser.userType &&
      currentUser.userGroupId === 1 &&
      permissionModules[currentUser.userType].sync.includes("pdVehicleSync")) {
      return <LeftLayout><PDVehicle {...props} /></LeftLayout>
    } else {
      return <NotFound />
    }
  }

  checkEcuSyncPermission = (props, module) => {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser && currentUser.userType &&
      currentUser.userGroupId === 1 &&
      permissionModules[currentUser.userType].sync.includes("ecuMasterSync")) {
      return <LeftLayout><EcuMaster {...props} /></LeftLayout>
    } else {
      return <NotFound />
    }
  }

  checkiAlertPermission = (props) => {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser && currentUser.userType &&
      currentUser.userGroupId === 2 &&
      permissionModules[currentUser.userType].sync.includes("iAlert")) {
      return <LeftLayout> <IAlert {...props} /> </LeftLayout>
    } else {
      return <NotFound />
    }
  }

  render() {
    return (
      // <BrowserRouter>
      <>
        <Route
          path="/sync/pdvehicle"
          exact
          render={(props) => (
            this.checkPdSyncPermission(props)
            // <LeftLayout><PDVehicle {...props} /></LeftLayout>
          )}
        />
        <Route
          path="/sync/ialert"
          exact
          render={(props) => (
            this.checkiAlertPermission(props)
            // <LeftLayout> <IAlert {...props} /> </LeftLayout>
          )}
        />
        <Route
          path="/sync/ecumaster"
          exact
          render={(props) => (
            this.checkEcuSyncPermission(props)
            // <LeftLayout><EcuMaster {...props} /></LeftLayout>
          )}
        />
        <Route
          path="/sync/hexsync"
          exact
          render={(props) => (
            <LeftLayout> <HexSync {...props} /> </LeftLayout>
          )}
        />
        {/* <Redirect from="/sync" to="/sync/pdvehicle" /> */}
        </>
      // </BrowserRouter>
    );
  }
}

export default Sync;
